import { SeriesEntryTableVariable } from './useSeriesTablesDataQuery';
import { SortDirection } from './SeriesEntryBody';

export type BackendEntryValue = string | number | null | string[];

export const createEntriesSort = ({
	variable,
	direction
}: {
	variable: SeriesEntryTableVariable;
	direction?: SortDirection;
}) => {
	return (a: BackendEntryValue, b: BackendEntryValue) => {
		switch (variable.variableType) {
			case 'categoryMultiple': {
				if (a === null && b === null) {
					return 0;
				}

				if (a === null) {
					return direction === 'asc' ? 1 : -1;
				}

				if (b === null) {
					return direction === 'asc' ? -1 : 1;
				}

				if (!Array.isArray(a) || !Array.isArray(b)) {
					console.warn('Invalid type for categoryMultiple', {
						typeA: typeof a,
						typeB: typeof b
					});
					return 0;
				}

				const aFirst = a[0];
				const bFirst = b[0];

				if (aFirst === undefined && bFirst === undefined) {
					return 0;
				}

				if (aFirst === undefined) {
					return direction === 'asc' ? 1 : -1;
				}

				if (bFirst === undefined) {
					return direction === 'asc' ? -1 : 1;
				}

				if (typeof aFirst !== 'string' || typeof bFirst !== 'string') {
					console.warn('Invalid type for categoryMultiple', {
						aFirst,
						bFirst
					});
					return 0;
				}

				const firstCompare =
					direction === 'asc'
						? aFirst.localeCompare(bFirst)
						: bFirst.localeCompare(aFirst);

				if (firstCompare !== 0) {
					return firstCompare;
				}

				return direction === 'asc' ? a.length - b.length : b.length - a.length;
			}

			case 'date':
			case 'datetime':
				if (a === null && b === null) {
					return 0;
				}

				if (a === null) {
					return direction === 'asc' ? 1 : -1;
				}

				if (b === null) {
					return direction === 'asc' ? -1 : 1;
				}

				if (typeof a !== 'string' || typeof b !== 'string') {
					console.warn('Invalid type for date or datetime');
					return 0;
				}

				return direction === 'asc'
					? new Date(a).getTime() - new Date(b).getTime()
					: new Date(b).getTime() - new Date(a).getTime();

			case 'category':
			case 'string':
				if (a === null && b === null) {
					return 0;
				}

				if (a === null) {
					return direction === 'asc' ? 1 : -1;
				}

				if (b === null) {
					return direction === 'asc' ? -1 : 1;
				}

				if (typeof a !== 'string' || typeof b !== 'string') {
					console.warn('Invalid type for category or string');
					return 0;
				}

				return direction === 'asc' ? a.localeCompare(b) : b.localeCompare(a);

			case 'float':
			case 'integer': {
				if (a === null && b === null) {
					return 0;
				}

				if (a === null) {
					return direction === 'asc' ? 1 : -1;
				}

				if (b === null) {
					return direction === 'asc' ? -1 : 1;
				}

				if (typeof a !== 'number' || typeof b !== 'number') {
					console.warn('Invalid type for float or integer', {
						typeA: typeof a,
						typeB: typeof b
					});
					return 0;
				}

				return direction === 'asc' ? a - b : b - a;
			}

			case 'timeDuration': {
				if (a === null && b === null) {
					return 0;
				}

				if (a === null) {
					return direction === 'asc' ? 1 : -1;
				}

				if (b === null) {
					return direction === 'asc' ? -1 : 1;
				}

				if (typeof a !== 'number' || typeof b !== 'number') {
					console.warn('Invalid type for timeDuration');
					return 0;
				}

				return direction === 'asc' ? a - b : b - a;
			}

			case 'userDefinedUnique':
				if (a === null && b === null) {
					return 0;
				}

				if (a === null) {
					return direction === 'asc' ? 1 : -1;
				}

				if (b === null) {
					return direction === 'asc' ? -1 : 1;
				}

				if (typeof a !== 'string' || typeof b !== 'string') {
					console.warn('Invalid type for userDefinedUnique', {
						type: typeof a
					});
					return 0;
				}

				return direction === 'asc' ? a.localeCompare(b) : b.localeCompare(a);

			case 'file':
				console.warn('File is not supported in series');
				return 0;

			case 'system':
				switch (variable.variableName) {
					// These are all dates
					case 'lastmodifieddate':
					case 'creationdate': {
						if (a === null && b === null) {
							return 0;
						}

						if (a === null) {
							return direction === 'asc' ? 1 : -1;
						}

						if (b === null) {
							return direction === 'asc' ? -1 : 1;
						}

						if (typeof a !== 'string' || typeof b !== 'string') {
							console.warn('Invalid type for creationdate');
							return 0;
						}

						return direction === 'asc'
							? new Date(a).getTime() - new Date(b).getTime()
							: new Date(b).getTime() - new Date(a).getTime();
					}

					// These are all strings
					case 'datasetentryid':
					case 'enteredbyuser':
					case 'ownedbyuser': {
						if (a === null && b === null) {
							return 0;
						}

						if (a === null) {
							return direction === 'asc' ? 1 : -1;
						}

						if (b === null) {
							return direction === 'asc' ? -1 : 1;
						}

						if (typeof a !== 'string' || typeof b !== 'string') {
							console.warn('Invalid type for datasetentryid');
							return 0;
						}

						return 0;
					}
				}
		}
	};
};
