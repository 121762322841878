import { useGetRepeatingSetRowsV2Query } from 'features/entry-form-v2/data/useGetRepeatingSetRowsV2Query';
import {
	useVariablesQuery,
	VariablesData
} from 'features/entry-form-v2/data/useVariablesQuery/useVariablesQuery';
import { SystemVariable, Variable, VariableGroup } from 'features/entry-form-v2/types';
import { useMemo } from 'react';

type VariableColumn = {
	type: 'variable';
	variable: Variable;
};

// Adding a separate system variable column type here for now, as extending the variable union type caused the need for a lot of handling in code where system variables are not relevant (e.g VariableInput in EntryForm)
type SystemVariableColumn = {
	type: 'system-variable';
	variable: SystemVariable;
};

export type SeriesEntryVariableColumn = VariableColumn | SystemVariableColumn;
export const isSeriesEntryVariableColumn = (
	column: SeriesEntryTableColumn
): column is SeriesEntryVariableColumn => {
	return column.type === 'variable' || column.type === 'system-variable';
};

export type SeriesEntryGroupColumn = {
	type: 'group';
	group: VariableGroup;
};
export const isSeriesEntryGroupColumn = (
	column: SeriesEntryTableColumn
): column is SeriesEntryGroupColumn => {
	return column.type === 'group';
};

export type SeriesEntryTableColumn = SeriesEntryVariableColumn | SeriesEntryGroupColumn;

export type SeriesEntryTableVariable = Variable | SystemVariable;
export type SeriesEntryTableVariables = Record<string, SeriesEntryTableVariable>;

export const procsessSeriesTableColumns = ({
	variablesData,
	seriesName
}: {
	variablesData: VariablesData;
	seriesName: string;
}): {
	columns: SeriesEntryTableColumn[];
	variables: SeriesEntryTableVariables;
} => {
	const customVariableColumns: SeriesEntryTableColumn[] = [];

	// Since we're reusing variables query from top level `sets` are optional, however they should always be present in this context
	const series = variablesData.sets![seriesName];

	for (const item of series.setOrder) {
		if (item.variable) {
			const variable = variablesData.variables[item.variable];
			if (!variable) {
				console.warn(`Variable ${item.variable} not found.`);
				continue;
			}

			customVariableColumns.push({
				type: 'variable',
				variable
			});

			continue;
		}

		if (item.group) {
			const group = variablesData.groups[item.group];

			if (!group) {
				console.warn(`Group ${item.group} not found.`);
				continue;
			}

			customVariableColumns.push({
				type: 'group',
				group
			});
		}
	}

	const topLevelVariables = customVariableColumns
		.filter(isSeriesEntryVariableColumn)
		.map(column => column.variable);

	const groupedVariableNames = customVariableColumns
		.filter(isSeriesEntryGroupColumn)
		.flatMap(column => column.group.variablesBelongingToGroup);

	const groupedVariables = groupedVariableNames.map(
		variableName => variablesData.variables[variableName]
	);

	const allVariables = [...topLevelVariables, ...groupedVariables, ...SYSTEM_VARIABLES];

	const variablesByVariableNames = allVariables.reduce((acc, variable) => {
		acc[variable.variableName] = variable;
		return acc;
	}, {} as SeriesEntryTableVariables);

	return {
		columns: [...customVariableColumns, ...SYSTEM_VARIABLE_COLUMNS],
		variables: variablesByVariableNames
	};
};

export const useSeriesTablesDataQuery = ({
	projectId,
	entryId,
	seriesName
}: {
	projectId: string;
	entryId: string;
	seriesName: string;
}) => {
	const variablesData = useVariablesQuery({ projectId });

	const getRepeatingDataSetRowsV2Query = useGetRepeatingSetRowsV2Query({
		entryId,
		projectId,
		setName: seriesName
	});

	const processedColumns = useMemo(() => {
		if (variablesData.isLoading) {
			return {
				isLoading: true,
				isFetching: getRepeatingDataSetRowsV2Query.isFetching,
				refetch: getRepeatingDataSetRowsV2Query.refetch
			};
		}

		if (variablesData.data) {
			const processed = procsessSeriesTableColumns({
				variablesData: variablesData.data,
				seriesName
			});

			return {
				data: processed,
				isLoading: false,
				isFetching: getRepeatingDataSetRowsV2Query.isFetching,
				refetch: getRepeatingDataSetRowsV2Query.refetch
			};
		}

		return {
			error: variablesData.error || 'Could not process variable into table headers',
			isLoading: false,
			isFetching: getRepeatingDataSetRowsV2Query.isFetching,
			refetch: getRepeatingDataSetRowsV2Query.refetch
		};
	}, [getRepeatingDataSetRowsV2Query, variablesData.data]);

	return processedColumns;
};

export const SYSTEM_VARIABLES: Readonly<SystemVariable[]> = [
	{
		variableType: 'system',
		variableName: 'enteredbyuser',
		variableLabel: 'Last modified by'
	},
	{
		variableType: 'system',
		variableName: 'ownedbyuser',
		variableLabel: 'Owner'
	},
	{
		variableType: 'system',
		variableName: 'creationdate',
		variableLabel: 'Creation date'
	},
	{
		variableType: 'system',
		variableName: 'lastmodifieddate',
		variableLabel: 'Last modified date'
	},
	{
		variableType: 'system',
		variableName: 'datasetentryid',
		variableLabel: 'Entry ID'
	}
] as const;

const SYSTEM_VARIABLE_COLUMNS: SeriesEntryTableColumn[] = SYSTEM_VARIABLES.map(variable => ({
	type: 'system-variable',
	variable
}));
