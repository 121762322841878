import { Link, useParams } from 'react-router-dom';
import { EntryHeader } from '../component/EntryHeader';
import { ROUTE_MAP } from '../utils/routeMap';
import { useGetProjectQuery } from '../data/useGetProjectQuery';

import { useState } from 'react';
import { Svgs } from 'environment';
import { Icon } from 'components/UI/Icons';
import {
	SeriesEntryBody,
	TableSkeleton
} from '../smart-components/series-entry-body/SeriesEntryBody';
import { SeriesFormItem, useProjectData } from '../data/useProjectData/useProjectData';
import { ErrorLoadingEntry, HeaderSkeleton } from '../update-entry/UpdateEntryPageV1_5';
import { Button } from '../component/Button';
import { ColumnFilterDropdown } from './ColumnFilterDropdown';
import { useSeriesTablesDataQuery } from '../smart-components/series-entry-body/useSeriesTablesDataQuery';
import { useGetRepeatingSetRowsV2Query } from '../data/useGetRepeatingSetRowsV2Query';
import { createFilterByFreeText } from '../smart-components/series-entry-body/entriesFilter';
import { Skeleton } from '../component/Skeleton';
import { Menu } from '@headlessui/react';
import { ExportSeriesModal } from './export-series-modal/ExportSeriesModal';

export const SeriesDetailsPage = () => {
	const params = useParams();
	const projectId = params['projectId'] as string;
	const seriesName = params['seriesName'] as string;
	const entryId = params['entryId'] as string;

	const [filteredVariables, setFilteredVariables] = useState<Set<string>>(new Set());

	const [freeTextSearch, setFreeTextSearch] = useState('');

	const projectDataQuery = useProjectData({
		projectId
	});

	const seriesFormItem = projectDataQuery.data?.formItems.find(
		formItem => formItem.type === 'series' && formItem.name === seriesName
	) as SeriesFormItem | undefined;

	const processedColumns = useSeriesTablesDataQuery({ projectId, entryId, seriesName });

	const getRepeatingDataSetRowsV2Query = useGetRepeatingSetRowsV2Query({
		entryId,
		projectId,
		setName: seriesName
	});

	if (
		getRepeatingDataSetRowsV2Query.isLoading ||
		processedColumns.isLoading ||
		projectDataQuery.isLoading
	) {
		return (
			<div className="md:px-20 pt-40 flex flex-col">
				<HeaderSkeleton />

				<div className="mx-auto w-full px-10 lg:p-0 lg:w-[1024px] flex flex-col">
					<Skeleton className="h-12 w-80 rounded-md self-end mb-4" />
					<TableSkeleton />
				</div>
			</div>
		);
	}

	if (!processedColumns.data) {
		return (
			<ErrorLoadingEntry
				isFetching={processedColumns.isFetching}
				refetch={processedColumns.refetch}
				message="Error processing columns, please try again. Contact support if the problem persists."
			/>
		);
	}

	if (!getRepeatingDataSetRowsV2Query.data) {
		return (
			<ErrorLoadingEntry
				isFetching={getRepeatingDataSetRowsV2Query.isFetching}
				refetch={getRepeatingDataSetRowsV2Query.refetch}
				message="Error fetching entries, please try again. Contact support if the problem persists."
			/>
		);
	}

	if (!seriesFormItem) {
		return (
			<ErrorLoadingEntry
				isFetching={projectDataQuery.isFetching}
				refetch={projectDataQuery.refetch}
				message="Error fetching series form item, please try again. Contact support if the problem persists."
			/>
		);
	}

	const filteredColumns =
		filteredVariables.size > 0
			? processedColumns.data.columns.filter(column => {
					if (column.type === 'group') {
						return column.group.variablesBelongingToGroup.some(variable =>
							filteredVariables.has(variable)
						);
					}

					return filteredVariables.has(column.variable.variableName);
			  })
			: processedColumns.data?.columns;

	const withFilteredGroups = filteredColumns.map(column => {
		if (column.type !== 'group') {
			return column;
		}

		return {
			...column,
			group: {
				...column.group,
				variablesBelongingToGroup:
					filteredVariables.size === 0
						? column.group.variablesBelongingToGroup
						: column.group.variablesBelongingToGroup.filter(variable =>
								filteredVariables.has(variable)
						  )
			}
		};
	});

	return (
		<div className="md:px-20 pt-40 flex flex-col">
			<Header seriesLabel={seriesFormItem.label} seriesName={seriesName} />

			<div className="flex flex-col mx-auto">
				<SearchBar
					freeTextSearch={freeTextSearch}
					onFreeTextSearchChanged={setFreeTextSearch}
					seriesLabel={seriesFormItem?.label || 'Error loading series label'}
				/>

				<div className="mt-4 self-end">
					{processedColumns.data?.columns && processedColumns.data && (
						<ColumnFilterDropdown
							variables={processedColumns.data.variables}
							columns={processedColumns.data.columns}
							filteredVariables={filteredVariables}
							onFilteredVariablesChange={setFilteredVariables}
						/>
					)}
				</div>

				<div className="mt-10 lg:min-w-[1024px] max-w-[100vw] md:max-w-[calc(100vw-80px)] overflow-auto">
					<SeriesEntryBody
						projectId={projectId}
						entryId={entryId}
						seriesName={seriesName}
						columns={withFilteredGroups || []}
						rows={getRepeatingDataSetRowsV2Query.data.dataRows.filter(entry => {
							if (!freeTextSearch) {
								return true;
							}

							return createFilterByFreeText(freeTextSearch)(entry);
						})}
						variables={processedColumns.data.variables}
					/>
				</div>
			</div>
		</div>
	);
};

const SearchBar = ({
	freeTextSearch,
	onFreeTextSearchChanged,
	seriesLabel
}: {
	onFreeTextSearchChanged: (search: string) => void;
	freeTextSearch: string;
	seriesLabel: string;
}) => {
	return (
		<div className="flex self-end">
			<div className="rounded-md border border-gray-300 relative flex items-center px-4">
				<div>
					<Icon svg={Svgs.Search} propagate size={s => s.m} />
				</div>

				<input
					onChange={e => onFreeTextSearchChanged(e.target.value)}
					value={freeTextSearch}
					placeholder="Search entries"
					className="p-4 text-base"
				/>
			</div>

			<SettingsMenu seriesLabel={seriesLabel} />
		</div>
	);
};

const Header = ({ seriesLabel, seriesName }: { seriesLabel: string; seriesName: string }) => {
	const params = useParams();
	const projectId = params['projectId'] as string;
	const entryId = params['entryId'] as string;

	const getProjectQuery = useGetProjectQuery({ projectId });

	return (
		<EntryHeader
			breadCrumbs={[
				{
					title: getProjectQuery.data?.project.projectName,
					url: ROUTE_MAP.project.byId.dataset.createPath({
						projectId
					})
				},
				{
					title: 'Update Entry',
					url: ROUTE_MAP.project.byId.dataset.update.createPath({
						projectId,
						entryId
					})
				}
			]}
			title={seriesLabel}
		>
			<div className="flex gap-4">
				<Link
					to={ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.create.createPath(
						{
							projectId,
							entryId,
							seriesName
						}
					)}
				>
					<Button title="Create New +" />
				</Link>

				<Link
					to={ROUTE_MAP.project.byId.dataset.update.createPath({
						projectId,
						entryId
					})}
				>
					<Icon svg={Svgs.Close} propagate variant={v => v.buttonActive} />
				</Link>
			</div>
		</EntryHeader>
	);
};

const SettingsMenu = ({ seriesLabel }: { seriesLabel: string }) => {
	const [showExportSeriesModal, setShowExportSeriesModal] = useState(false);

	return (
		<div className="relative">
			<Menu>
				<Menu.Button>
					<Icon svg={Svgs.More} propagate size={s => s.l} variant={v => v.button} />
				</Menu.Button>

				<Menu.Items className="absolute overflow-hidden top-14 right-0 w-48 bg-white rounded-lg z-50 shadow-normal flex flex-col items-stretch">
					<Menu.Item>
						<button
							className="hover:text-white hover:bg-primary-500 p-4 text-start text-base"
							type="button"
							onClick={() => setShowExportSeriesModal(true)}
						>
							Export series
						</button>
					</Menu.Item>
				</Menu.Items>
			</Menu>

			<ExportSeriesModal
				onClose={() => setShowExportSeriesModal(false)}
				visible={showExportSeriesModal}
				seriesLabel={seriesLabel}
			/>
		</div>
	);
};
