import { Typography } from 'components/UI/Typography';
import { Container } from './DomainConstraintError.style';
import { useTranslation } from 'hooks/store';

interface Props {
	emails: string[];
}

export const DomainConstraintError = ({ emails }: Props) => {
	const { translate } = useTranslation();

	const firstEmail = emails[0];

	return (
		<Container data-testid="notAllowedDueToDomainConstraintViolationError">
			<Typography.Paragraph>
				{translate(({ sharingConfirmNewMembers }) =>
					emails.length === 1
						? sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation
								.theFollowingEmailAddressIsNotConnectedToYourOrganization
						: sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation
								.theFollowingEmailAddressesAreNotConnectedToYourOrganization
				)}
				{emails.length === 1 ? (
					<a key={firstEmail} href={`mailto:${firstEmail}`}>
						{firstEmail}
					</a>
				) : (
					<ul>
						{emails.map(email => (
							<li key={email}>
								<a href={`mailto:${email}`}>{email}</a>
							</li>
						))}
					</ul>
				)}
			</Typography.Paragraph>

			<Typography.Paragraph fontweight={w => w.semiBold} marginOffset={{ top: 1 }}>
				{translate(
					({ sharingConfirmNewMembers }) =>
						sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation
							.forCollaboratorsWithinHSO
				)}
			</Typography.Paragraph>

			<Typography.Paragraph>
				{translate(
					({ sharingConfirmNewMembers }) =>
						sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation
							.thePersonYouInvited
				)}
			</Typography.Paragraph>

			<Typography.Paragraph fontweight={w => w.semiBold} marginOffset={{ top: 1 }}>
				{translate(
					({ sharingConfirmNewMembers }) =>
						sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation
							.forExternalCollaboratos
				)}
			</Typography.Paragraph>

			<Typography.Paragraph>
				{translate(({ sharingConfirmNewMembers }) =>
					emails.length === 1
						? sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation
								.ifYouWantToAddThisUserInYourRegisterContact
						: sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation
								.ifYouWantToAddTheseUsersInYourRegisterContact
				)}{' '}
				<a href="mailto:vicbri@ous-hf.no">vicbri@ous-hf.no</a>{' '}
				{translate(
					({ sharingConfirmNewMembers }) =>
						sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.or
				)}{' '}
				<a href="mailto:antvan@ous-hf.no">antvan@ous-hf.no</a>,{' '}
				{translate(
					({ sharingConfirmNewMembers }) =>
						sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation
							.andProvideTheFollowingInformation
				)}
			</Typography.Paragraph>

			<Typography.Paragraph marginOffset={{ top: 1 }}>
				<ul className="list-disc pl-6">
					<li>
						{translate(
							({ sharingConfirmNewMembers }) =>
								sharingConfirmNewMembers
									.usersNotAllowedDueToDomainConstraintViolation.nameOfTheUser
						)}
					</li>
					<li>
						{translate(
							({ sharingConfirmNewMembers }) =>
								sharingConfirmNewMembers
									.usersNotAllowedDueToDomainConstraintViolation.title
						)}
					</li>
					<li>
						{translate(
							({ sharingConfirmNewMembers }) =>
								sharingConfirmNewMembers
									.usersNotAllowedDueToDomainConstraintViolation.institution
						)}
					</li>
					<li>
						{translate(
							({ sharingConfirmNewMembers }) =>
								sharingConfirmNewMembers
									.usersNotAllowedDueToDomainConstraintViolation.department
						)}
					</li>
					<li>
						{translate(
							({ sharingConfirmNewMembers }) =>
								sharingConfirmNewMembers
									.usersNotAllowedDueToDomainConstraintViolation.roleInTheProject
						)}
					</li>
					<li>
						{translate(
							({ sharingConfirmNewMembers }) =>
								sharingConfirmNewMembers
									.usersNotAllowedDueToDomainConstraintViolation.email
						)}
					</li>
					<li>
						{translate(
							({ sharingConfirmNewMembers }) =>
								sharingConfirmNewMembers
									.usersNotAllowedDueToDomainConstraintViolation
									.nameOfTheProjectInLedidi
						)}
					</li>
					<li>
						{translate(
							({ sharingConfirmNewMembers }) =>
								sharingConfirmNewMembers
									.usersNotAllowedDueToDomainConstraintViolation.projectNumber
						)}
					</li>
				</ul>
			</Typography.Paragraph>

			<Typography.Paragraph marginOffset={{ top: 1 }}>
				{translate(
					({ sharingConfirmNewMembers }) =>
						sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation
							.weWillReturnWithMoreInformation
				)}
			</Typography.Paragraph>

			<Typography.Paragraph marginOffset={{ top: 1 }}>
				{translate(
					({ sharingConfirmNewMembers }) =>
						sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation
							.ifUsersYouWantToInviteDoesBelongToYourOrganizationAnyways
				)}
			</Typography.Paragraph>
		</Container>
	);
};
