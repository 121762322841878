import { Link, useNavigate, useParams } from 'react-router-dom';
import { EntryHeader } from '../../component/EntryHeader';
import { useGetProjectQuery } from '../../data/useGetProjectQuery';
import { ROUTE_MAP } from '../../utils/routeMap';
import { EntryForm } from 'features/entry-form-v2/EntryForm';
import { Button } from 'features/entry-form-v2/component/Button';
import { Entry } from 'features/entry-form-v2/types';
import { useRef, useState } from 'react';
import { ErrorModal } from 'features/entry-form-v2/ErrorModal';
import {
	getLatestEntryVersion,
	useGetLatestDataEntryVersionQuery
} from 'features/entry-form-v2/data/useGetLatestDataEntryVersionQuery';
import { useTracking } from 'app/tracking/TrackingProvider';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { useInsertDataEntryMutation } from 'features/entry-form-v2/data/useInsertDataEntryMutation';
import {
	isSeriesFormItem,
	useProjectData
} from 'features/entry-form-v2/data/useProjectData/useProjectData';
import { SelectGroup } from 'features/entry-form-v2/create-entry/CreateEntryPageV1_5';
import {
	EntryFormSkeleton,
	ErrorLoadingEntry
} from 'features/entry-form-v2/update-entry/UpdateEntryPageV1_5';
import { SelectForm, useFormSelector } from 'features/entry-form-v2/smart-components/SelectForm';

export const CreateSeriesEntryPageV1_5 = () => {
	const { track } = useTracking();
	const params = useParams();
	const navigate = useNavigate();

	const projectId = params.projectId as string;
	const entryId = params.entryId as string;
	const seriesName = params.seriesName as string;

	const allowLeavePageOverrideRef = useRef(false);

	const [selectedGroupId, setSelectedGroupId] = useState<string>();

	const insertSeriesDataEntryMutation = useInsertDataEntryMutation();

	const { formId, setSelectedFormId, showFormSelector, getFormsQueryIsLoading } = useFormSelector(
		{
			projectId,
			seriesName
		}
	);

	// I think we should split useProjectData into a separate hook for series, but it's not clear to me how to restructure the tests when we do that, so doing this "hack" for now
	const projectDataQuery = useProjectData({
		projectId
	});
	const seriesFormItem = projectDataQuery.data?.formItems
		.filter(isSeriesFormItem)
		.find(item => item.name === seriesName);

	const seriesProjectDataQuery = useProjectData({
		projectId,
		seriesName,
		selectedFormId: formId
	});

	const dataEntryQuery = useGetLatestDataEntryVersionQuery({
		entryId,
		projectId
	});

	const [entryIsSubmitting, setEntryIsSubmitting] = useState(false);
	const submitEntry = async (entry: Entry) => {
		try {
			setEntryIsSubmitting(true);

			if (!seriesProjectDataQuery.data) {
				console.error(
					"onSubmit called but projectDataQuery.data doesn't exist, this is a no-op"
				);
				return;
			}

			const createdSeriesEntry = await insertSeriesDataEntryMutation.mutateAsync({
				projectId,
				entry,
				organizationGroupId: selectedGroupId,
				setInfo: {
					mainSetRevisionId: dataEntryQuery.data?.entry['original_id'],
					setName: seriesName
				}
			});

			const latestEntryVersion = await getLatestEntryVersion({
				entryId,
				projectId
			});

			allowLeavePageOverrideRef.current = true;

			track({
				eventName: 'entry_series_created'
			});

			navigate(
				ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.update.createPath({
					projectId,
					entryId: latestEntryVersion.entry.datasetentryid,
					seriesName,
					seriesEntryId: createdSeriesEntry.insertedEntry.datasetentryid,
					formId
				}),
				{ replace: true }
			);
		} catch (e) {
			console.error(e);
		} finally {
			setEntryIsSubmitting(false);
		}
	};

	const isLoading = seriesProjectDataQuery.isLoading || getFormsQueryIsLoading;

	return (
		<div className="flex flex-col pt-40">
			{isLoading && <EntryFormSkeleton />}

			{seriesProjectDataQuery.error && (
				<ErrorLoadingEntry
					isFetching={seriesProjectDataQuery.isFetching}
					refetch={seriesProjectDataQuery.refetch}
				/>
			)}

			{!isLoading && (
				<SelectForm
					expanded={showFormSelector}
					onFormSelected={setSelectedFormId}
					selectedFormId={formId}
					seriesName={seriesName}
				/>
			)}

			{!isLoading && seriesProjectDataQuery.data && (
				<div className="flex flex-col md:w-[756px] lg:mx-auto">
					<ErrorModal
						onClose={insertSeriesDataEntryMutation.reset}
						error={insertSeriesDataEntryMutation.error}
					/>

					{!selectedGroupId && (
						<SelectGroup onGroupSelected={setSelectedGroupId} projectId={projectId} />
					)}

					<EntryForm
						key={formId}
						Header={
							<Header
								isSubmitting={entryIsSubmitting}
								seriesLabel={seriesFormItem?.label}
							/>
						}
						onSubmit={submitEntry}
						projectData={seriesProjectDataQuery.data}
						allowLeavePageOverrideRef={allowLeavePageOverrideRef}
					/>
				</div>
			)}
		</div>
	);
};

const Header = ({ isSubmitting, seriesLabel }: { seriesLabel?: string; isSubmitting: boolean }) => {
	const params = useParams();

	const projectId = params.projectId as string;
	const entryId = params.entryId as string;
	const seriesName = params.seriesName as string;

	const getProjectQuery = useGetProjectQuery({ projectId });

	return (
		<EntryHeader
			breadCrumbs={[
				{
					title: getProjectQuery.data?.project.projectName,
					url: ROUTE_MAP.project.byId.dataset.createPath({
						projectId
					})
				},
				{
					title: 'Update Entry',
					url: ROUTE_MAP.project.byId.dataset.update.createPath({
						projectId,
						entryId
					})
				},
				{
					title: seriesLabel,
					url: ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.createPath({
						projectId,
						entryId,
						seriesName
					})
				}
			]}
			title="New Series Entry"
		>
			<div className="flex gap-4">
				<Button title="Save" loading={isSubmitting} />

				<Link
					to={ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.createPath({
						projectId,
						entryId,
						seriesName
					})}
				>
					<Icon
						svg={Svgs.Close}
						variant={v => v.buttonActive}
						dataTestId="entry-form-header_close-icon"
						propagate
					/>
				</Link>
			</div>
		</EntryHeader>
	);
};
