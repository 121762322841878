import { RevisionWithChanges, useGetDataEntryHistoryQuery } from '../data/useGetDataEntryHistory';
import { useGetNamesFromUserIdsQuery } from '../data/useGetNamesFromUserIdsQuery';
import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Skeleton } from '../component/Skeleton';
import { Variable } from '../types';
import { backendValueToFormValue } from '../utils/parse-backend-values/backendValueToFormValue';
import { BackendFormFile } from '../utils/zodUtils';
import { Diff, ToValue, variableToFieldType } from '../confirm-diff-modal/ConfirmDiffModal';
import { useParams } from 'react-router-dom';
import { Loader } from 'components/UI/Loader';
import { logWithDetails } from 'sentry';
import { useVariablesQuery, VariablesData } from '../data/useVariablesQuery/useVariablesQuery';
import { parseBackendValues } from '../utils/parse-backend-values/parseBackendValues';
import { isNull } from 'lodash';
import { useGetDataEntryQuery } from '../data/useGetDataEntryQuery';
import { useTracking } from 'app/tracking/TrackingProvider';
import { Dialog } from '../component/Dialog';
import { Button } from '../component/Button';

interface Props {
	open: boolean;
	onClose: () => void;
	entryId: string;
	projectId: string;
	seriesName?: string;
}

export const EntryHistoryModal = ({ open, onClose, entryId, projectId, seriesName }: Props) => {
	const { track } = useTracking();
	useEffect(() => {
		if (open) {
			track({ eventName: 'entry_history_viewed' });
		}
	}, [open]);

	const entryHistoryData = useEntryHistoryData({ entryId, projectId, seriesName });

	return (
		<Dialog
			title="Entry History"
			open={open}
			onClose={onClose}
			description="Click on a revision to see change details"
		>
			<div className="flex flex-col w-[600px] h-[720px] overflow-y-auto">
				{entryHistoryData.loading && (
					<ul className="flex flex-col flex-1">
						{new Array(10).fill(null).map((_, index) => (
							<li key={index} className="flex flex-col p-4">
								<Skeleton className="w-40 h-6" />
								<Skeleton className="w-48 h-4 mt-2" />
							</li>
						))}
					</ul>
				)}

				{entryHistoryData.data && (
					<ul className="flex flex-col flex-1">
						{entryHistoryData.data.history?.map((revision, index) =>
							index === entryHistoryData.data.history.length - 1 ? (
								<FirstRevisionEntry
									key={revision.revisionId}
									entryRevisionId={revision.revisionId}
									revision={revision}
									darkBg={index % 2 == 1}
								/>
							) : (
								<RevisionEntry
									key={revision.revisionId}
									revision={revision}
									darkBg={index % 2 == 1}
								/>
							)
						)}
					</ul>
				)}

				<Button title="Close" onClick={onClose} className="self-end" />
			</div>
		</Dialog>
	);
};

const FirstRevisionEntry = ({
	revision,
	darkBg,
	entryRevisionId
}: {
	revision: ProcessedHistoryEntry;
	darkBg: boolean;
	entryRevisionId: string;
}) => {
	const { track } = useTracking();

	const [expanded, setExpanded] = useState(false);

	return (
		<li key={revision.revisionId}>
			<button
				onClick={() => {
					setExpanded(!expanded);
					track({ eventName: 'entry_history_expanded' });
				}}
				className={clsx('flex flex-col p-4 text-start w-full', darkBg && 'bg-gray-100')}
			>
				<p className="text-base">{new Date(revision.creationDate).toLocaleString()}</p>

				<p className="text-sm mt-2">
					<b>{revision.userFullName || revision.userName}</b> created <b>data entry</b>
				</p>

				{expanded && <FirstRevisionEntryContent entryRevisionId={entryRevisionId} />}
			</button>
		</li>
	);
};

const FirstRevisionEntryContent = ({ entryRevisionId }: { entryRevisionId: string }) => {
	const params = useParams();
	const projectId = params.projectId as string;

	const dataEntryQuery = useGetDataEntryQuery({
		entryId: entryRevisionId,
		projectId
	});
	const variablesQuery = useVariablesQuery({ projectId });

	if (dataEntryQuery.isLoading || variablesQuery.isLoading) {
		return (
			<div className="w-full flex justify-center items-center p-4">
				<Loader />
			</div>
		);
	}

	if (!dataEntryQuery.data || !variablesQuery.data) {
		logWithDetails('Data entry not found', { projectId, entryRevisionId });

		return (
			<div className="w-full flex justify-center items-center p-4">
				<p className="text-base text-error-500 font-semibold">Error loading entry</p>
			</div>
		);
	}

	const variables = Object.values(variablesQuery.data.variables);
	const formValues = parseBackendValues({
		variables,
		entry: dataEntryQuery.data.entry
	});

	return (
		<ul className="flex flex-col mt-2 gap-4">
			{Object.entries(formValues)
				.filter(([_, value]) => !isNull(value) && value !== '')
				.map(([variableName, entryValue]) => {
					const variable = variables.find(
						variable => variable.variableName === variableName
					);

					if (!variable) {
						console.error(`Variable for ${variableName} not found`);
						return null;
					}

					const fieldType = variableToFieldType(variable);

					return (
						<li key={variable.variableName} className="flex flex-col gap-2">
							<p className="text-base">{variable.variableLabel}</p>

							<ToValue to={entryValue} fieldType={fieldType} variable={variable} />
						</li>
					);
				})}
		</ul>
	);
};

const RevisionEntry = ({
	revision,
	darkBg
}: {
	revision: ProcessedHistoryEntry;
	darkBg: boolean;
}) => {
	const { track } = useTracking();

	const [expanded, setExpanded] = useState(false);

	return (
		<li key={revision.revisionId}>
			<button
				onClick={() => {
					setExpanded(!expanded);
					track({ eventName: 'entry_history_expanded' });
				}}
				className={clsx('flex flex-col p-4 text-start w-full', darkBg && 'bg-gray-100')}
			>
				<p className="text-base">{new Date(revision.creationDate).toLocaleString()}</p>

				<p className="text-sm mt-2">
					<b>{revision.userFullName || revision.userName}</b> edited <b>data entry</b>
				</p>

				{revision.active && <p className="italic text-primary-700">Latest version</p>}

				{expanded && revision.changes.length > 0 && (
					<ul className="flex flex-col mt-2">
						{revision.changes.map((change, index) => {
							return (
								<li key={index} className="flex flex-col mt-2">
									<p className="text-sm font-semibold">
										{change.variable.variableLabel}
									</p>

									<Diff
										changedFieldDiff={{
											from: change.from,
											to: change.to
										}}
										fieldType={variableToFieldType(change.variable)}
										variable={change.variable}
									/>
								</li>
							);
						})}
					</ul>
				)}

				{expanded && revision.changes.length === 0 && (
					<p className="text-base italic mt-2">No changes</p>
				)}
			</button>
		</li>
	);
};

const useEntryHistoryData = ({
	entryId,
	projectId,
	seriesName
}: {
	entryId: string;
	projectId: string;
	seriesName?: string;
}) => {
	const getDataEntryHistoryQuery = useGetDataEntryHistoryQuery({
		entryId,
		projectId,
		setName: seriesName
	});

	const variablesQuery = useVariablesQuery({ projectId });

	const allUserIds =
		getDataEntryHistoryQuery.data?.revisionsWithChanges.map(revision => revision.userName) ??
		[];
	const userIdsSet = new Set(allUserIds);
	const userIds = Array.from(userIdsSet);

	const getNamesFromUserIdsQuery = useGetNamesFromUserIdsQuery(
		{
			userIds
		},
		{ enabled: userIds.length !== 0 }
	);

	const processedHistory: ProcessedHistoryEntry[] = useMemo(() => {
		if (
			!getDataEntryHistoryQuery.data ||
			!getNamesFromUserIdsQuery.data ||
			!variablesQuery.data
		) {
			return [];
		}

		const withNames = getDataEntryHistoryQuery.data.revisionsWithChanges.map(revision => {
			return {
				...revision,
				userFullName: getNamesFromUserIdsQuery.data?.namesFromUserIds[revision.userName]
			};
		});

		const withChanges = withNames.map(revision => {
			return {
				...revision,
				changes: backendRevisionToDiffedFields(revision, variablesQuery.data.variables)
			};
		});

		return withChanges.sort((a, b) => {
			return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime();
		});
	}, [getDataEntryHistoryQuery.data, getNamesFromUserIdsQuery.data, variablesQuery.data]);

	return {
		loading: getDataEntryHistoryQuery.isLoading || getNamesFromUserIdsQuery.isLoading,
		data: {
			history: processedHistory
		}
	};
};

const backendRevisionToDiffedFields = (
	revision: RevisionWithChanges,
	variables: VariablesData['variables']
): ProcessedHistoryEntry['changes'] => {
	const diffedFields = revision.changes.map(change => {
		const variable = variables[change.variableName];

		if (!variable) {
			console.error(new Error('Variable not found'), {
				variableName: change.variableName
			});
			return;
		}

		return {
			variable,
			from: backendValueToFormValue({ value: change.from, variable }),
			to: backendValueToFormValue({ value: change.to, variable })
		};
	});

	return diffedFields.filter(isNotUndefined);
};

const isNotUndefined = <T,>(value: T | undefined): value is T => {
	return value !== undefined;
};

type ProcessedHistoryEntry = {
	active: boolean;
	changes: {
		variable: Variable;
		from: string | BackendFormFile;
		to: string | BackendFormFile;
	}[];
	creationDate: string;
	revisionId: string;
	userFullName?: string;
	userName: string;
};
