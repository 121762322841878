import { useQuery } from '@tanstack/react-query';
import { makeRequest } from './makeRequest';

export const useGetSeriesNumberOfEntries = ({
	projectId,
	entryId
}: {
	projectId: string;
	entryId: string;
}) => {
	return useQuery({
		queryKey: ['seriesNumberOfEntries', entryId],
		queryFn: () =>
			makeRequest<{
				numberOfEntries: Record<string, number>;
			}>({
				method: 'getSeriesNumberOfEntries',
				service: 'data-entry',
				data: {
					projectId,
					datasetentryid: entryId
				}
			})
	});
};
