import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { PropsWithChildren } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ROUTE_MAP } from '../utils/routeMap';
import { Skeleton } from './Skeleton';

interface Props {
	title: string;

	breadCrumbs: {
		title?: string;
		url: string;
	}[];
}

export const EntryHeader = ({ title, breadCrumbs, children }: PropsWithChildren<Props>) => {
	const params = useParams();
	const projectId = params['projectId'] as string;

	return (
		<div className="bg-gray-300 p-4 md:px-10 flex items-center justify-between fixed top-0 right-0 left-0 z-50 h-[60px]">
			<div className="flex items-center">
				<Link
					className="mr-10"
					to={ROUTE_MAP.project.byId.dataset.createPath({ projectId })}
				>
					<Icon
						svg={Svgs.LedidiLogoGrey}
						customSize={3}
						id="main_header_logo"
						propagate
					/>
				</Link>

				{breadCrumbs && (
					<div className="hidden md:flex">
						{breadCrumbs.map((breadCrumb, index) => (
							<div key={index} className="flex items-center gap-2">
								<Link
									className="hover:underline max-w-[200px] overflow-hidden"
									to={breadCrumb.url}
								>
									{breadCrumb.title ? (
										<p className="text-xl font-semibold truncate">
											{breadCrumb.title}
										</p>
									) : (
										<Skeleton className="h-14 w-96" />
									)}
								</Link>

								<Icon svg={Svgs.ChevronRight} className="mt-1" />
							</div>
						))}
					</div>
				)}

				<p className="text-xl font-semibold">{title}</p>
			</div>

			{children}
		</div>
	);
};
