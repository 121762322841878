import { StrictMode, useEffect, useState, useRef, forwardRef } from 'react';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { Button } from '../component/Button';
import { Menu } from '@headlessui/react';
import { useProjectData } from '../data/useProjectData/useProjectData';
import { ROUTE_MAP } from '../utils/routeMap';
import { ErrorModal } from '../ErrorModal';
import { EntryHistoryModal } from '../smart-components/EntryHistoryModal';
import { Entry, Variable } from '../types';
import { useReactToPrint } from 'react-to-print';
import { PrintEntryForm } from '../PrintEntryForm';
import { EntryDetailsModal } from '../smart-components/EntryDetailsModal';
import { useTracking } from 'app/tracking/TrackingProvider';
import { useNavigate, useParams, Link, useSearchParams } from 'react-router-dom';
import { EntryHeader } from '../component/EntryHeader';
import { Skeleton } from '../component/Skeleton';
import { ConfirmDiffModal } from '../confirm-diff-modal/ConfirmDiffModal';
import { useGetFormsQuery } from '../data/useGetFormsQuery/useGetFormsQuery';
import { useGetLatestDataEntryVersionQuery } from '../data/useGetLatestDataEntryVersionQuery';
import { useGetProjectQuery } from '../data/useGetProjectQuery';
import { useUpdateDataEntryMutation } from '../data/useUpdateDataEntryMutation';
import { EntryForm } from '../EntryForm';
import { SelectForm } from '../smart-components/SelectForm';
import { DiffedField } from '../utils/formUtils';
import { parseBackendValues } from '../utils/parse-backend-values/parseBackendValues';
import { PRINT_PAGE_STYLE } from '../utils/print';
import { DeleteEntryModal } from '../smart-components/DeleteEntryModal';
import { MoreIcon } from 'components/Icons';

export const UpdateEntryPageV1_5 = ({ hidePrintEntryForm }: { hidePrintEntryForm?: boolean }) => {
	const { track } = useTracking();

	const navigate = useNavigate();

	const allowLeavePageOverrideRef = useRef(false);

	const [searchParams, setSearchParams] = useSearchParams();
	const routeParams = useParams();
	const entryId = routeParams.entryId as string;
	const projectId = routeParams.projectId as string;

	const formId = searchParams.get('formId');
	const setSelectedFormId = (updatedFormId: string) => {
		setSearchParams({ formId: updatedFormId }, { replace: true });
	};

	const getFormsQuery = useGetFormsQuery({
		projectId
	});
	const [showFormSelector, setShowFormSelector] = useState<boolean>(false);
	useEffect(() => {
		if (getFormsQuery.data && getFormsQuery.data.activeForms.length >= 1) {
			if (!formId) {
				setSelectedFormId(getFormsQuery.data.activeForms[0].formId.toString());
			}

			if (getFormsQuery.data.activeForms.length > 1) {
				setShowFormSelector(true);
			}
		}
	}, [getFormsQuery.data]);

	const projectDataQuery = useProjectData({
		projectId,
		selectedFormId: formId
	});

	const updateDataEntryMutation = useUpdateDataEntryMutation();

	const [dataPendingSubmission, setDataPendingSubmission] = useState<Entry | undefined>(
		undefined
	);

	const dataEntryQuery = useGetLatestDataEntryVersionQuery({
		entryId,
		projectId
	});

	useEffect(() => {
		// Make sure the url matches the latest entry id so updates are most likely to work
		if (dataEntryQuery.data && dataEntryQuery.data.entry.datasetentryid !== entryId) {
			navigate(
				ROUTE_MAP.project.byId.dataset.update.createPath({
					projectId,
					entryId: dataEntryQuery.data.entry.datasetentryid,
					formId
				}),
				{ replace: true }
			);
		}
	}, [dataEntryQuery.data]);

	const loading =
		projectDataQuery.isLoading ||
		dataEntryQuery.isLoading || // its important to ensure we fetch forms before we render the EntryForm, as if we render the entry form with stale form data, it will display formitems/values in the wrong order
		// Ideally this should be done with the key on the EntryForm, but I'm not sure if we have something ala revisionId for custom forms
		!getFormsQuery.isFetchedAfterMount;
	const error = projectDataQuery.error || dataEntryQuery.error;

	const submitEntry = async (entry: Entry) => {
		if (!projectDataQuery.data) {
			console.error(
				"onSubmit called but projectDataQuery.data doesn't exist, this is a no-op"
			);
			return;
		}

		try {
			const result = await updateDataEntryMutation.mutate({
				projectId,
				entryId,
				entry
			});
			allowLeavePageOverrideRef.current = true;
			track({
				eventName: 'entry_updated',
				data: {
					entryHasStatus: false
				}
			});

			navigate(
				ROUTE_MAP.project.byId.dataset.update.createPath({
					projectId,
					entryId: result.updatedEntry.datasetentryid,
					formId
				}),
				{ replace: true }
			);

			await dataEntryQuery.refetch();
		} catch (e) {
			console.error(e);
		} finally {
			setDataPendingSubmission(undefined);
		}
	};

	return (
		<StrictMode>
			<div className="flex flex-col pt-40">
				{!loading && (
					<SelectForm
						expanded={showFormSelector}
						onFormSelected={setSelectedFormId}
						selectedFormId={formId}
					/>
				)}
				<div
					className="flex flex-col lg:w-[756px] lg:mx-auto px-4 md:px-10"
					data-testid="update-entry-page"
				>
					<ErrorModal
						onClose={updateDataEntryMutation.reset}
						error={updateDataEntryMutation.error}
					/>

					{loading && <EntryFormSkeleton />}

					{error && (
						<ErrorLoadingEntry
							isFetching={dataEntryQuery.isFetching || projectDataQuery.isFetching}
							refetch={() => {
								dataEntryQuery.refetch();
								projectDataQuery.refetch();
							}}
						/>
					)}

					{
						// this loading seems redundant, but because of the quirk with formsQuery.isFetchedAfterMount, it caused the page both show skeleton and the data.
						// Putting this here prevents such inconsistencies
						!loading && dataEntryQuery.data && projectDataQuery.data && (
							<>
								<SubmitDiffModal
									dataPendingSubmission={dataPendingSubmission}
									initialEntry={dataEntryQuery.data.entry}
									onClose={() => setDataPendingSubmission(undefined)}
									onConfirm={submitEntry}
									onDelete={() =>
										navigate(
											ROUTE_MAP.project.byId.dataset.createPath({
												projectId
											})
										)
									}
									submitting={updateDataEntryMutation.loading}
									variables={projectDataQuery.data.variables}
								/>

								<EntryForm
									key={dataEntryQuery.data?.entry?.datasetentryid + formId}
									Header={
										<Header
											entryIsSubmitting={updateDataEntryMutation.loading}
											formSelectorIsShowing={showFormSelector}
											onShowFormSelectorClicked={() =>
												setShowFormSelector(!showFormSelector)
											}
											hidePrintEntryForm={hidePrintEntryForm}
										/>
									}
									projectData={projectDataQuery.data}
									initialEntry={dataEntryQuery.data.entry}
									submitting={updateDataEntryMutation.loading}
									onSubmit={async entry => {
										setDataPendingSubmission(entry);
									}}
									allowLeavePageOverrideRef={allowLeavePageOverrideRef}
								/>
							</>
						)
					}
				</div>
			</div>
		</StrictMode>
	);
};

const Header = ({
	entryIsSubmitting,
	onShowFormSelectorClicked,
	formSelectorIsShowing,
	hidePrintEntryForm
}: {
	entryIsSubmitting: boolean;
	onShowFormSelectorClicked: () => void;
	formSelectorIsShowing: boolean;
	hidePrintEntryForm?: boolean;
}) => {
	const params = useParams();
	const projectId = params.projectId as string;
	const entryId = params.entryId as string;

	const contentRef = useRef<HTMLDivElement>(null);
	const reactToPrint = useReactToPrint({ contentRef, pageStyle: PRINT_PAGE_STYLE });
	const getProjectQuery = useGetProjectQuery({ projectId });

	const projectDataQuery = useProjectData({
		projectId
	});

	const getFormsQuery = useGetFormsQuery({
		projectId
	});

	const dataEntryQuery = useGetLatestDataEntryVersionQuery({
		entryId,
		projectId
	});

	return (
		<>
			{dataEntryQuery.data && projectDataQuery.data && !hidePrintEntryForm && (
				<div className="hidden print:block">
					{/**
					 * Writing integration tests revealed thaht this approach is not ideal, as this renders two instances of all inputs, which causes issues in the tests.
					 * For now adding the option to disable rendering this, but we should really reconsider this approach to printing.
					 *
					 * Counter (increment this when we do a hack related to PrintEntryForm): 2
					 */}
					{!hidePrintEntryForm && (
						<div className="hidden print:block">
							<PrintEntryForm
								projectData={projectDataQuery.data}
								initialEntry={dataEntryQuery.data.entry}
								contentRef={contentRef}
							/>
						</div>
					)}
				</div>
			)}

			<EntryHeader
				title="Update Entry"
				breadCrumbs={[
					{
						title: getProjectQuery.data?.project.projectName,
						url: ROUTE_MAP.project.byId.dataset.createPath({
							projectId
						})
					}
				]}
			>
				<div className="flex gap-4 items-center">
					{getFormsQuery.data && getFormsQuery.data.activeForms.length > 1 && (
						<button
							onClick={onShowFormSelectorClicked}
							className="self-end"
							type="button"
						>
							<Icon
								svg={Svgs.FileText}
								active={formSelectorIsShowing}
								propagate
								variant={v => v.button}
							/>
						</button>
					)}

					<OptionsMenu
						entryId={entryId}
						projectId={projectId}
						hasDeleteAccess={!!dataEntryQuery.data?.entryAccess.delete}
						handlePrint={reactToPrint}
					/>

					{dataEntryQuery.data?.entryAccess.write ? (
						<Button
							loading={entryIsSubmitting}
							className="col-start-4 col-span-2"
							title="Save"
							variant="primary"
							data-testid="entry-form-header_save-button"
						/>
					) : (
						<ViewOnly />
					)}

					<Link to={ROUTE_MAP.project.byId.dataset.createPath({ projectId })}>
						<Icon
							svg={Svgs.Close}
							variant={v => v.buttonActive}
							dataTestId="entry-form-header_close-icon"
							propagate
						/>
					</Link>
				</div>
			</EntryHeader>
		</>
	);
};

type OptionsMenuButtonProps = {
	children: React.ReactNode;
	onClick: () => void;
	ariaLabel: string;
};
export const OptionsMenuButton = forwardRef<HTMLButtonElement, OptionsMenuButtonProps>(
	({ children, onClick, ariaLabel }, ref) => {
		return (
			<button
				ref={ref}
				className="hover:text-white hover:bg-primary-500 p-4 text-start text-base"
				onClick={onClick}
				aria-label={ariaLabel}
			>
				{children}
			</button>
		);
	}
);

const ViewOnly = () => (
	<div className="border-gray-700 rounded-md border px-4 py-2 group hover:cursor-help relative">
		<p>View only</p>

		<p className="absolute right-0 z-50 w-80 top-full mt-2 px-4 py-2 bg-gray-800 text-white text-sm rounded-md opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 shadow-lg">
			You can only view this entry due to read-only rights
		</p>
	</div>
);

export const OptionsMenu = ({
	entryId,
	projectId,
	hasDeleteAccess,
	handlePrint
}: {
	entryId: string;
	projectId: string;
	hasDeleteAccess: boolean;
	handlePrint: () => void;
}) => {
	const { track } = useTracking();

	const [showDeleteEntryModal, setShowDeleteEntryModal] = useState(false);
	const [showEntryHistoryModal, setShowEntryHistoryModal] = useState(false);
	const [showEntryDetailsModal, setShowEntryDetailsModal] = useState(false);

	const navigate = useNavigate();

	return (
		<div className="relative">
			<Menu>
				<Menu.Button className="hover:bg-gray-700/10 rounded-full p-2">
					<MoreIcon className="text-icon-base" aria-label="More options" />
				</Menu.Button>

				<Menu.Items className="absolute overflow-hidden top-14 right-0 w-48 bg-white rounded-lg z-50 shadow-normal flex flex-col items-stretch">
					<Menu.Item>
						<OptionsMenuButton
							ariaLabel="Print entry"
							onClick={() => {
								handlePrint();
								track({
									eventName: 'print_entry_clicked',
									data: {
										entryType: 'entry'
									}
								});
							}}
						>
							Print entry
						</OptionsMenuButton>
					</Menu.Item>

					<Menu.Item>
						<OptionsMenuButton
							onClick={() => setShowEntryDetailsModal(true)}
							ariaLabel="Entry details"
						>
							Entry details
						</OptionsMenuButton>
					</Menu.Item>

					<Menu.Item>
						<OptionsMenuButton
							onClick={() => setShowEntryHistoryModal(true)}
							ariaLabel="Entry history"
						>
							Entry history
						</OptionsMenuButton>
					</Menu.Item>

					{hasDeleteAccess && (
						<Menu.Item>
							<OptionsMenuButton
								ariaLabel="Delete entry"
								onClick={() => setShowDeleteEntryModal(true)}
							>
								Delete entry
							</OptionsMenuButton>
						</Menu.Item>
					)}
				</Menu.Items>
			</Menu>

			{hasDeleteAccess && (
				<DeleteEntryModal
					entryId={entryId}
					projectId={projectId}
					show={showDeleteEntryModal}
					onClose={() => setShowDeleteEntryModal(false)}
					onEntryDeleted={() =>
						navigate(ROUTE_MAP.project.byId.dataset.createPath({ projectId }))
					}
				/>
			)}

			<EntryDetailsModal
				open={showEntryDetailsModal}
				onClose={() => setShowEntryDetailsModal(false)}
				entryId={entryId}
				projectId={projectId}
			/>

			<EntryHistoryModal
				open={showEntryHistoryModal}
				onClose={() => setShowEntryHistoryModal(false)}
				entryId={entryId}
				projectId={projectId}
			/>
		</div>
	);
};

export const SubmitDiffModal = ({
	dataPendingSubmission,
	initialEntry,
	onClose,
	onConfirm,
	onDelete,
	submitting,
	variables
}: {
	dataPendingSubmission: Entry | undefined;
	initialEntry: Entry;
	onClose: () => void;
	onConfirm: (data: Entry) => void;
	submitting?: boolean;
	variables: Variable[];
	onDelete: () => void;
}) => {
	if (!dataPendingSubmission) {
		return null;
	}

	const initialValues = parseBackendValues({
		variables,
		entry: initialEntry
	});
	const currentValues = {
		...initialValues,
		...parseBackendValues({ variables, entry: dataPendingSubmission }) // there is a bug here if there is a file variable they can't be parsed like this but it will work because the
	};

	const diffedFields: Record<string, DiffedField> = {};
	for (const key of Object.keys(dataPendingSubmission)) {
		if (variables.find(v => v.variableName === key)?.variableType === 'file') {
			diffedFields[key] = {
				from: initialValues[key],
				to: dataPendingSubmission[key]
			};
		} else {
			diffedFields[key] = {
				from: initialValues[key],
				to: currentValues[key]
			};
		}
	}

	return (
		<ConfirmDiffModal
			title="Please review the pending changes"
			diff={diffedFields}
			onClose={onClose}
			onConfirm={() => onConfirm(dataPendingSubmission)}
			submitting={submitting}
			variables={variables}
			onDelete={onDelete}
		/>
	);
};

export const EntryFormSkeleton = () => {
	return (
		<div
			className="grid grid-cols-2 px-10 gap-10 relative mb-52 lg:w-[756px] lg:mx-auto"
			role="progressbar"
		>
			<HeaderSkeleton />

			<InputSkeleton />
			<InputSkeleton />
			<InputSkeleton />
			<InputSkeleton />
			<InputSkeleton />
			<InputSkeleton />
			<InputSkeleton />
			<InputSkeleton />
			<InputSkeleton />
		</div>
	);
};

export const HeaderSkeleton = () => (
	<div className="bg-gray-300 fixed top-0 right-0 left-0 h-[60px]" />
);

const InputSkeleton = () => (
	<div className="flex flex-col gap-1">
		<Skeleton className="w-40 h-4 rounded-sm" />
		<Skeleton className="w-full h-14 rounded-md" />
	</div>
);

export const ErrorLoadingEntry = ({
	isFetching,
	refetch,
	message = 'Error loading data, please try again. Contact support if the problem persists.'
}: {
	isFetching: boolean;
	refetch: () => void;
	message?: string;
}) => {
	const params = useParams();
	const projectId = params.projectId as string;

	return (
		<div className="v-[100vw] h-[100vh] flex items-center justify-center flex-col">
			<Icon svg={Svgs.LedidiLogo} customSize={5} />

			<p className="text-base font-bold mt-20">{message}</p>

			<div className="flex flex-col gap-4 mt-10">
				<Button title="Reload" variant="primary" loading={isFetching} onClick={refetch} />

				<Link
					className="text-sm underline self-center"
					to={ROUTE_MAP.project.byId.dataset.createPath({ projectId })}
				>
					Back to dataset
				</Link>
			</div>
		</div>
	);
};
