import clsx from 'clsx';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetFormsQuery } from '../data/useGetFormsQuery/useGetFormsQuery';

export const SelectForm = ({
	expanded,
	onFormSelected,
	selectedFormId,
	seriesName
}: {
	expanded: boolean;
	onFormSelected: (formId: string) => void;
	selectedFormId: string | null;
	seriesName?: string;
}) => {
	const params = useParams();
	const projectId = params.projectId as string;

	const getFormsQuery = useGetFormsQuery({
		projectId,
		seriesName
	});

	const { containerRef, canScrollLeft, canScrollRight, scrollLeft, scrollRight } =
		useScrollObserver();

	if (!getFormsQuery.data) {
		return null;
	}

	if (getFormsQuery.data.activeForms.length < 2) {
		return null;
	}

	if (!expanded) {
		return null;
	}

	return (
		<div className="bg-gray-300 flex flex-col items-center -mt-16 mb-16 py-10">
			<div className="flex flex-row gap-4">
				<button
					onClick={scrollLeft}
					className={clsx(
						'self-center p-2 bg-white rounded-full shadow-md hover:bg-gray-50',
						!canScrollLeft && 'opacity-0'
					)}
					aria-label="Scroll left"
				>
					<Icon propagate svg={Svgs.ChevronLeft} />
				</button>

				<ul
					className="flex gap-4 px-4 max-w-[572px] overflow-x-scroll no-scrollbar"
					ref={containerRef}
				>
					{getFormsQuery.data.activeForms.map(form => (
						<li key={form.formId} className="flex flex-col gap-4">
							<button
								className="flex flex-col"
								onClick={() => onFormSelected(form.formId.toString())}
							>
								<div
									className={clsx(
										'border-2 flex w-[120px] h-[151px] rounded-md p-4 bg-white shadow-normal',
										form.formId.toString() === selectedFormId &&
											'border-primary-700'
									)}
								>
									<Icon
										className="self-start"
										svg={Svgs.FileText}
										customSize={2}
									/>
								</div>

								<p className="mt-4 text-ellipsis overflow-hidden whitespace-nowrap max-w-[120px]">
									{form.formDefinition.formName}
								</p>
							</button>
						</li>
					))}
				</ul>

				<button
					onClick={scrollRight}
					className={clsx(
						'self-center p-2 bg-white rounded-full shadow-md hover:bg-gray-50',
						!canScrollRight && 'opacity-0'
					)}
					aria-label="Scroll right"
				>
					<Icon propagate svg={Svgs.ChevronRight} />
				</button>
			</div>
		</div>
	);
};

const useScrollObserver = () => {
	const [canScrollLeft, setCanScrollLeft] = useState(false);
	const [canScrollRight, setCanScrollRight] = useState(false);
	const containerRef = useRef<HTMLUListElement>(null);

	const checkScroll = () => {
		const element = containerRef.current;
		if (element) {
			setCanScrollLeft(element.scrollLeft > 0);
			setCanScrollRight(element.scrollLeft < element.scrollWidth - element.clientWidth);
		}
	};

	useEffect(() => {
		const element = containerRef.current;
		if (element) {
			checkScroll();
			element.addEventListener('scroll', checkScroll);
			window.addEventListener('resize', checkScroll);

			// Initial check after content loads
			const initialCheck = setTimeout(checkScroll, 100);

			return () => {
				element.removeEventListener('scroll', checkScroll);
				window.removeEventListener('resize', checkScroll);
				clearTimeout(initialCheck);
			};
		}
	}, []);

	const scrollLeft = () => {
		const element = containerRef.current;
		if (element) {
			element.scrollBy({ left: -240, behavior: 'smooth' });
		}
	};

	const scrollRight = () => {
		const element = containerRef.current;
		if (element) {
			element.scrollBy({ left: 240, behavior: 'smooth' });
		}
	};

	return {
		containerRef,
		canScrollLeft,
		canScrollRight,
		scrollLeft,
		scrollRight
	};
};

export const useFormSelector = ({
	projectId,
	seriesName
}: {
	projectId: string;
	seriesName?: string;
}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const formId = searchParams.get('formId');
	const setSelectedFormId = (updatedFormId: string) => {
		setSearchParams({ formId: updatedFormId }, { replace: true });
	};

	const getFormsQuery = useGetFormsQuery({
		projectId,
		seriesName
	});
	const [showFormSelector, setShowFormSelector] = useState<boolean>(false);
	useEffect(() => {
		if (getFormsQuery.data && getFormsQuery.data.activeForms.length >= 1) {
			if (!formId) {
				setSelectedFormId(getFormsQuery.data.activeForms[0].formId.toString());
			}

			if (getFormsQuery.data.activeForms.length > 1) {
				setShowFormSelector(true);
			}
		}
	}, [getFormsQuery.data]);

	return {
		showFormSelector,
		toggleShowFormSelector: () => setShowFormSelector(prev => !prev),
		formId,
		setSelectedFormId,
		getFormsQueryIsLoading: getFormsQuery.isLoading
	};
};
