import { Routes, Route, Navigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AccountUMProvider, NotificationsProvider, AddonsProvider } from 'components/Providers';

import { Auth } from './Auth';
import { Auth as AmplifyAuth } from 'aws-amplify';
import { NavigationHub } from './NavigationHub';
import { FreshchatDisplay } from './FreshchatDisplay';
import { SegmentPageTracker } from './SegmentPageTracker';

import { LicenceLimitationModal } from 'components/Account/Subscription/Modals/LicenceLimitationModal';
import { PrivateRoutes } from 'components/PrivateRoutes';
import { Scroller } from 'components/UI/Scroller';
import { stringAsBoolean } from 'helpers/generic';
import { StorePrevLocation } from 'helpers/storePrevLocation';
import {
	AccountUMSubscriptionPage,
	AccountUMAddonsPage,
	AccountUMPaymentPage,
	AccountUMBillingPage
} from 'pages/Account';
import {
	AdminUsersPage,
	AdminUserCreatePage,
	AdminUserDetailsPage,
	AdminEnterprisePage
} from 'pages/Admin';
import { AdminDashboardPage } from 'pages/AdminDashboard';
import { AnalysisPage } from 'pages/Analysis';
import { CollaboratorsInvitePage, CollaboratorsPage } from 'pages/Collaborators';
import { DashboardPage } from 'pages/Dashboard';
import { DatasetPage, AddEditEntryPage } from 'pages/Dataset';
import { DependenciesPage } from 'pages/Dependencies';
import { DocumentsPage } from 'pages/Documents';
import { FormsPage, FormDesignerPage } from 'pages/Forms';
import { HelpPage } from 'pages/Help';
import { LoginPage } from 'pages/Login';
import { LogoutPage } from 'pages/Logout';
import { NotFoundPage } from 'pages/NotFound';
import { PatientManualSurveyPage, PatientLoginPage, PatientEditEntryPage } from 'pages/Patient';
import { PatientsPage } from 'pages/Patients';
import { ProjectsPage, CreateAndImportPage } from 'pages/Projects';
import { CreatePromPage } from 'pages/Proms';
import { RolesPage } from 'pages/Roles';
import { StatusesPage } from 'pages/Statuses';
import { TemplateRolesPage } from 'pages/TemplateRoles';
import { TemplatesPage } from 'pages/Templates';
import { VariablesPage } from 'pages/Variables';
import { useLogin, useShowAddons, useSubscription } from 'hooks/store';
import { ROUTES } from 'types/navigation';
import {
	EnterpriseAdminDashboardPage,
	EnterpriseAdminProjectsPage,
	EnterpriseAdminRolesPage,
	EnterpriseAdminSettingsPage,
	EnterpriseAdminSubscriptionPage
} from 'pages/EnterpriseAdmin';
import { LastPaymentWarning } from 'components/Account/LastPaymentWarning/LastPaymentWarning';
import { TinyURLPage } from 'pages/TinyURLPage';
import { useEffect } from 'react';
import { User } from 'api/auth';
import { configureSentryUser } from 'sentry';
import { MicroApp } from 'next-micro-app/NextMicroApp';
import { identify } from './tracking/TrackingProvider';
import { UpdateEntryPageV1_5 } from 'features/entry-form-v2/update-entry/UpdateEntryPageV1_5';
import { CreateEntryPageV1_5 } from 'features/entry-form-v2/create-entry/CreateEntryPageV1_5';
import { ROUTE_MAP } from 'features/entry-form-v2/utils/routeMap';
import { CreateSeriesEntryPageV1_5 } from 'features/entry-form-v2/series/create/CreateSeriesEntryPageV1_5';
import { SeriesDetailsPage } from 'features/entry-form-v2/series/SeriesDetailsPage';
import { UpdateSeriesEntryPageV1_5 } from 'features/entry-form-v2/series/update/UpdateSeriesEntryPageV1_5';

const ADMIN_PATH_ROOT = `/${process.env.REACT_APP_ADMIN_PATH_ROOT}`;
const ENTERPRISE_ADMIN_PATH_ROOT = `/${process.env.REACT_APP_ENTERPRISE_ADMIN_PATH_ROOT}`;

const IS_FRESHCHAT_ENABLED = stringAsBoolean(process.env.REACT_APP_USE_FRESHCHAT!);
const IS_HELP_PAGE_ENABLED = stringAsBoolean(process.env.REACT_APP_HELP_PAGE!);
const IS_PROMS_PAGE_ENABLED = stringAsBoolean(process.env.REACT_APP_PROMS_PAGE!);
const IS_FORMS_DESGINER_PAGE_ENABLED = stringAsBoolean(process.env.REACT_APP_USE_FORMS_DESIGNER!);
const IS_TEMPLATES_PAGE_ENABLED = stringAsBoolean(process.env.REACT_APP_USE_TEMPLATES!);
const IS_SEGMENT_TRACKING_ENABLED = stringAsBoolean(process.env.REACT_APP_USE_SEGMENT_TRACKING!);
const IS_ADMIN_DASHBOARD_PAGE_ENABLED = stringAsBoolean(
	process.env.REACT_APP_USE_ADMIN_DASHBOARD_PAGE!
);
const ARE_ENTERPRISE_ADMIN_ROUTES_ACTIVATED = stringAsBoolean(
	process.env.REACT_APP_USE_ENTERPRISE_ADMIN_ROUTES!
);

const IS_ADMIN_PROJECT_DASHBOARD_PAGE_ENABLED = stringAsBoolean(
	process.env.REACT_APP_USE_PROJECT_ADMIN_DASHBOARD_PAGE!
);

const NEXT_APP_URL = process.env.REACT_NEXT_APP_URL as string;

export function Navigation() {
	const [{ data: loggedIn }] = useLogin();
	const { data: showAddons } = useShowAddons();

	useSegmentIdentification();
	useSentryIdentification();

	const { enterpriseAdminRolesFlag } = useFlags();

	return (
		<>
			<Auth />
			<Scroller />
			<NavigationHub />
			<StorePrevLocation />

			{IS_FRESHCHAT_ENABLED && <FreshchatDisplay />}
			{IS_SEGMENT_TRACKING_ENABLED && <SegmentPageTracker />}

			<AccountUMProvider />
			<NotificationsProvider />
			<AddonsProvider />

			<LastPaymentWarning />
			<LicenceLimitationModal />

			<Routes>
				<Route element={<PrivateRoutes />}>
					<Route path={'/v2/*'} element={<MicroApp src={NEXT_APP_URL} />} />

					<Route
						path={ROUTE_MAP.project.byId.dataset.update.path}
						element={<UpdateEntryPageV1_5 />}
					/>
					<Route
						path={ROUTE_MAP.project.byId.dataset.create.path}
						element={<CreateEntryPageV1_5 />}
					/>
					<Route
						path={ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.create.path}
						element={<CreateSeriesEntryPageV1_5 />}
					/>
					<Route
						path={ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.path}
						element={<SeriesDetailsPage />}
					/>
					<Route
						path={ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.update.path}
						element={<UpdateSeriesEntryPageV1_5 />}
					/>

					<Route path={ROUTES.Analysis} element={<AnalysisPage />} />
					<Route path={ROUTES.Dataset} element={<DatasetPage />} />

					<Route path={ROUTES.CreateEntry} element={<AddEditEntryPage />} />
					<Route path={ROUTES.UpdateEntry} element={<AddEditEntryPage />} />
					<Route path={ROUTES.Variables} element={<VariablesPage />} />
					<Route path={ROUTES.CreateVariable} element={<VariablesPage />} />
					<Route path={ROUTES.Documents} element={<DocumentsPage />} />
					<Route path={ROUTES.PromDocuments} element={<DocumentsPage />} />
					<Route path={ROUTES.Dependencies} element={<DependenciesPage />} />
					<Route path={ROUTES.PromDependencies} element={<DependenciesPage />} />
					<Route path={ROUTES.EntryStatuses} element={<StatusesPage />} />
					<Route path={ROUTES.PromEntryStatuses} element={<StatusesPage />} />
					<Route path={ROUTES.AddCollaborators} element={<CollaboratorsInvitePage />} />
					<Route path={ROUTES.Collaborators} element={<CollaboratorsPage />} />
					<Route path={ROUTES.Projects} element={<ProjectsPage />} />
					<Route path={ROUTES.CreateProject} element={<CreateAndImportPage />} />
					<Route path={ROUTES.ImportDataset} element={<CreateAndImportPage />} />
					<Route path={ROUTES.ImportPromDataset} element={<CreateAndImportPage />} />
					<Route path={ROUTES.Roles} element={<RolesPage />} />
					{/* ACCOUNT USER MANAGEMENT*/}
					<Route
						path={ROUTES.AccountUMSubscription}
						element={<AccountUMSubscriptionPage />}
					/>
					{showAddons && (
						<Route path={ROUTES.AccountUMAddons} element={<AccountUMAddonsPage />} />
					)}
					{/* ADMIN */}
					<Route
						path={ADMIN_PATH_ROOT + ROUTES.AdminUsers}
						element={<AdminUsersPage />}
					/>
					<Route
						path={ADMIN_PATH_ROOT + ROUTES.AdminUserCreate}
						element={<AdminUserCreatePage />}
					/>
					<Route
						path={ADMIN_PATH_ROOT + ROUTES.AdminUserDetails}
						element={<AdminUserDetailsPage />}
					/>
					<Route
						path={ADMIN_PATH_ROOT + ROUTES.AdminEnterprise}
						element={<AdminEnterprisePage />}
					/>
					{/* ENTERPRISE ADMIN */}
					{ARE_ENTERPRISE_ADMIN_ROUTES_ACTIVATED && (
						<>
							<Route
								path={ENTERPRISE_ADMIN_PATH_ROOT + ROUTES.EnterpriseAdminDashboard}
								element={<EnterpriseAdminDashboardPage />}
							/>
							<Route
								path={ENTERPRISE_ADMIN_PATH_ROOT + ROUTES.EnterpriseAdminUsers}
								element={<AccountUMSubscriptionPage />}
							/>
							<Route
								path={ENTERPRISE_ADMIN_PATH_ROOT + ROUTES.EnterpriseAdminProjects}
								element={<EnterpriseAdminProjectsPage />}
							/>
							<Route
								path={
									ENTERPRISE_ADMIN_PATH_ROOT + ROUTES.EnterpriseAdminSubscription
								}
								element={<EnterpriseAdminSubscriptionPage />}
							/>
							{enterpriseAdminRolesFlag && (
								<Route
									path={ENTERPRISE_ADMIN_PATH_ROOT + ROUTES.EnterpriseAdminRoles}
									element={<EnterpriseAdminRolesPage />}
								/>
							)}
							<Route
								path={ENTERPRISE_ADMIN_PATH_ROOT + ROUTES.EnterpriseAdminSettings}
								element={<EnterpriseAdminSettingsPage />}
							/>
						</>
					)}
					{/* DASHBOARD */}
					{IS_ADMIN_DASHBOARD_PAGE_ENABLED && (
						<Route path={ROUTES.AdminDashboard} element={<AdminDashboardPage />} />
					)}
					{/* HELP */}
					{IS_HELP_PAGE_ENABLED && (
						<>
							<Route path={ROUTES.Help} element={<HelpPage />} />
							<Route path={ROUTES.HelpTopic} element={<HelpPage />} />
						</>
					)}
					{/* TEMPLATES */}
					{IS_TEMPLATES_PAGE_ENABLED && (
						<Route path={ROUTES.Templates} element={<TemplatesPage />} />
					)}
					{/* FORMS */}
					{IS_FORMS_DESGINER_PAGE_ENABLED && (
						<>
							<Route path={ROUTES.Forms} element={<FormsPage />} />
							<Route path={ROUTES.Form} element={<FormDesignerPage />} />
						</>
					)}
					{/* TEMPLATE ROLES */}
					<Route path={ROUTES.TemplateRoles} element={<TemplateRolesPage />} />
					{/*LPI - Portfolio insights */}
					{IS_ADMIN_PROJECT_DASHBOARD_PAGE_ENABLED && (
						<Route path={ROUTES.ProjectDashboard} element={<AdminDashboardPage />} />
					)}
					{/* PROMS */}
					{IS_PROMS_PAGE_ENABLED && (
						<>
							<Route path={ROUTES.Proms} element={<ProjectsPage />} />
							<Route path={ROUTES.Dashboard} element={<DashboardPage />} />
							<Route path={ROUTES.Patients} element={<PatientsPage />} />
							<Route path={ROUTES.PromAnalysis} element={<AnalysisPage />} />
							<Route path={ROUTES.PromDataset} element={<DatasetPage />} />
							<Route path={ROUTES.CreatePromEntry} element={<AddEditEntryPage />} />
							<Route path={ROUTES.UpdatePromEntry} element={<AddEditEntryPage />} />
							<Route path={ROUTES.CreateProm} element={<CreatePromPage />} />
							<Route path={ROUTES.PromVariables} element={<VariablesPage />} />
							<Route path={ROUTES.CreatePromVariable} element={<VariablesPage />} />
							<Route
								path={ROUTES.AddPromCollaborators}
								element={<CollaboratorsInvitePage />}
							/>
							<Route
								path={ROUTES.PromCollaborators}
								element={<CollaboratorsPage />}
							/>
							<Route path={ROUTES.PromTemplates} element={<TemplatesPage />} />
							<Route path={ROUTES.PromRoles} element={<RolesPage />} />

							{IS_FORMS_DESGINER_PAGE_ENABLED && (
								<>
									<Route path={ROUTES.PromForms} element={<FormsPage />} />
									<Route path={ROUTES.PromForm} element={<FormDesignerPage />} />
								</>
							)}
						</>
					)}
				</Route>
				<Route element={<PrivateRoutes subscriptionAccess />}>
					{/* ACCOUNT USER MANAGEMENT*/}
					<Route path={ROUTES.AccountUMPayment} element={<AccountUMPaymentPage />} />
					<Route
						path={ROUTES.AccountUMBillingHistory}
						element={<AccountUMBillingPage />}
					/>
				</Route>
				{/* PATIENT - MANUAL DISTRIBUTION */}
				<Route path={ROUTES.PatientManualSurvey} element={<PatientManualSurveyPage />} />
				<Route
					path={ROUTES.PatientManualSurveyWithParams}
					element={<PatientManualSurveyPage />}
				/>
				<Route
					path={ROUTES.PatientManualSurveyWithParamsForm}
					element={<PatientManualSurveyPage />}
				/>
				<Route
					path={ROUTES.PatientManualSurveyWithParamsForm}
					element={<PatientManualSurveyPage />}
				/>
				{/* PATIENT - AUTOMATIC DISTRIBUTION */}
				<Route path={ROUTES.PatientLogin} element={<PatientLoginPage />} />
				<Route path={ROUTES.PatientLoginWithParams} element={<PatientLoginPage />} />
				<Route path={ROUTES.PatientLoginWithParamsForm} element={<PatientLoginPage />} />
				<Route path={ROUTES.PatientEditEntry} element={<PatientEditEntryPage />} />
				<Route
					path={ROUTES.Root}
					element={<Navigate to={loggedIn ? ROUTES.Projects : ROUTES.Login} />}
				/>
				<Route path={ROUTES.Logout} element={<LogoutPage />} />
				<Route path={ROUTES.Login} element={<LoginPage />} />
				<Route path={ROUTES.NotFoundPage} element={<NotFoundPage />} />

				{/* SSO Tiny URL redirects */}
				<Route path={'/hso'} element={<TinyURLPage client={'hso'} />} />
				<Route path={'/cmr'} element={<TinyURLPage client={'cmr'} />} />
				<Route path={'/ledidissotest'} element={<TinyURLPage client={'ledidissotest'} />} />
				{/* Short link Cognito configuration issue for Cornell, uncomment when fixed  */}
				{/* <Route path={'/cornell'} element={<TinyURLPage client={'cornell'} />} /> */}
			</Routes>
		</>
	);
}

const useSentryIdentification = () => {
	const [{ data: loggedIn }] = useLogin();

	useEffect(() => {
		async function asyncShit() {
			try {
				const user: User = await AmplifyAuth.currentAuthenticatedUser();
				if (user) {
					configureSentryUser({ sub: user.signInUserSession.idToken.payload.sub });
				}
			} catch (error) {
				console.error(
					'loggedIn true but cannot get current user from Amplify. This is not supposed to happen'
				);
			}
		}

		if (loggedIn) {
			asyncShit();
		}
	}, [loggedIn]);
};

const useSegmentIdentification = () => {
	const [
		{
			data: { details: userDetails }
		}
	] = useSubscription();

	useEffect(() => {
		async function asyncShit() {
			try {
				const user: User = await AmplifyAuth.currentAuthenticatedUser();
				const subscriptionId = userDetails?.subscriptionId || '';
				if (user) {
					identify({
						userId: user.signInUserSession.idToken.payload.sub,
						trait: { subscriptionId: subscriptionId }
					});
				}
			} catch (error) {
				console.error(
					'loggedIn true but cannot get current user from Amplify. This is not supposed to happen'
				);
			}
		}

		if (userDetails) {
			asyncShit();
		}
	}, [userDetails]);
};
