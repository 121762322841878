export const ROUTE_MAP = {
	project: {
		byId: {
			dataset: {
				path: '/projects/:projectId/dataset',
				createPath: ({ projectId }: { projectId: string }) =>
					`/projects/${projectId}/dataset`,

				create: {
					path: '/v1.5/projects/:projectId/dataset/create',
					createPath: ({ projectId, formId }: { projectId: string; formId?: string }) => {
						const path = `/v1.5/projects/${projectId}/dataset/create`;

						if (formId) {
							return `${path}?formId=${formId}`;
						}

						return path;
					}
				},

				update: {
					path: '/v1.5/projects/:projectId/dataset/update/:entryId',
					createPath: ({
						projectId,
						entryId,
						formId
					}: {
						projectId: string;
						entryId: string;
						formId?: string | null;
					}) => {
						const path = `/v1.5/projects/${projectId}/dataset/update/${entryId}`;
						if (formId) {
							return `${path}?formId=${formId}`;
						}

						return path;
					},

					series: {
						bySeriesName: {
							path: '/v1.5/projects/:projectId/dataset/entries/:entryId/series/:seriesName',
							createPath: ({
								projectId,
								entryId,
								seriesName
							}: {
								projectId: string;
								entryId: string;
								seriesName: string;
							}) =>
								`/v1.5/projects/${projectId}/dataset/entries/${entryId}/series/${seriesName}`,

							create: {
								path: '/v1.5/projects/:projectId/dataset/entries/:entryId/series/:seriesName/create',
								createPath: ({
									projectId,
									entryId,
									seriesName,
									formId
								}: {
									projectId: string;
									entryId: string;
									seriesName: string;
									formId?: string | null;
								}) => {
									const path = `/v1.5/projects/${projectId}/dataset/entries/${entryId}/series/${seriesName}/create`;
									if (formId) {
										return `${path}?formId=${formId}`;
									}

									return path;
								}
							},

							update: {
								path: '/v1.5/projects/:projectId/dataset/entries/:entryId/series/:seriesName/entry/:seriesEntryId/update',
								createPath: ({
									projectId,
									entryId,
									seriesName,
									seriesEntryId,
									formId
								}: {
									projectId: string;
									entryId: string;
									seriesName: string;
									seriesEntryId: string;
									formId?: string | null;
								}) => {
									const path = `/v1.5/projects/${projectId}/dataset/entries/${entryId}/series/${seriesName}/entry/${seriesEntryId}/update`;
									if (formId) {
										return `${path}?formId=${formId}`;
									}

									return path;
								}
							}
						}
					}
				}
			}
		}
	}
} as const;
