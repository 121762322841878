import { makeRequest } from './makeRequest';
import { useQuery } from '@tanstack/react-query';

type FileResponse = {
	httpStatusCode: number;
	doc: {
		fileURL: string;
		metadata: {
			fileLabel: string;
			fileName: string;
			mimeType: string;
		};
	};
};

export const useFileInfoQuery = ({
	entryId,
	projectId,
	fileId,
	enabled = true
}: {
	entryId: string;
	projectId: string;
	fileId: string;
	enabled?: boolean;
}) => {
	const query = useQuery({
		queryKey: ['fileInfo', fileId],
		enabled,
		queryFn: () =>
			makeRequest<FileResponse>({
				method: 'getFileInfo',
				service: 'data-entry',
				data: {
					datasetentryid: entryId,
					projectId,
					fileId
				}
			})
	});

	return {
		...query,
		// https://github.com/TanStack/query/issues/3584#issuecomment-1256986636
		// loading is apparently true with enabled: false, which I think is a mistake, this makes it easier to use
		isLoading: query.isLoading && query.fetchStatus !== 'idle'
	};
};
