import {
	ImportDropzone,
	Subtitle,
	CardContainer,
	Card,
	CardLabel,
	ImportText,
	NarrowContainer
} from 'components/Projects/CreateAndImport';
import { Icon } from 'components/UI/Icons';
import { Spacer } from 'components/UI/Spacer';
import { StickyFooter } from 'components/UI/StickyFooter';
import { Typography } from 'components/UI/Typography';
import { DATASET_FILE_SIZE_LIMIT } from 'consts';
import { Colors, Svgs } from 'environment';
import { ImportType, SetState } from 'types/index';
import { Warning } from './ImportData.style';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	useImportDataText,
	useImport,
	useVariables,
	useImportVariableSet
} from 'hooks/store';
import { useEffectOnce } from 'hooks/utils';
import type { FileImportErrors } from 'types/data/projects/types';
import { useResetImportPercentage } from 'hooks/store/data/projects/import/useResetImportPercentage';
import { ImportErrorWarning } from '../ImportErrorWarning/ImportErrorWarning';
import { useEffect, useState } from 'react';

interface Props {
	importFileName: string;
	selectedOption: ImportType | null;
	uploadingFile: boolean;
	fileImportErrors: FileImportErrors | null;
	setFileImportErrors: SetState<FileImportErrors | null>;
	setImportFileName: SetState<string>;
	setSelectedOption: SetState<ImportType | null>;
	handleFinishedLoading: () => void;
	handleReset: () => void;
	handleUploadingError: () => void;
}

export function ImportData({
	importFileName,
	selectedOption,
	uploadingFile,
	fileImportErrors,
	setFileImportErrors,
	setImportFileName,
	setSelectedOption,
	handleFinishedLoading,
	handleReset,
	handleUploadingError
}: Props) {
	const { translate } = useTranslation();
	const { goBack } = useNavigation();

	const [shouldReset, setShouldReset] = useState(false);

	const [{ data: importText }] = useImportDataText();
	const [, { setPreviousMapping }] = useImport();

	const resetImportPercentage = useResetImportPercentage();

	const [
		{
			data: { variableSets, hasVariables }
		}
	] = useVariables({ initial: true });

	const [
		{ isImportVariableSet, importVariableSetName },
		{ setIsImportVariableSet, setImportVariableSetName }
	] = useImportVariableSet();

	const isNewVariableSet =
		importVariableSetName &&
		!variableSets.some(variableSet => variableSet.setName === importVariableSetName);

	const currentVariableSet =
		!isNewVariableSet &&
		variableSets.find(variableSet => variableSet.setName === importVariableSetName);
	const isVariableSetEmpty = currentVariableSet && currentVariableSet.setOrder.length === 0;

	const importVariableSetLabel = currentVariableSet
		? currentVariableSet.setLabel
		: importVariableSetName;

	const disableImportOptionsCards = isNewVariableSet || isVariableSetEmpty;
	useEffectOnce(() => {
		if (disableImportOptionsCards) {
			setSelectedOption(ImportType.ReplaceAll);
		}
	});

	useEffect(() => {
		if (!fileImportErrors) return;

		if (importFileName !== '' && selectedOption && !uploadingFile && shouldReset) {
			handleReset();
		}
	}, [importFileName, selectedOption, uploadingFile, fileImportErrors, shouldReset]);

	const IMPORT_OPTIONS = [
		{
			icon: Svgs.Add,
			label: translate(({ projects }) =>
				isImportVariableSet
					? projects.createAndImport.importOptions.moreDataToVariableSet
					: projects.createAndImport.importOptions.moreDataToEntries
			),
			value: ImportType.MoreDataToExistingEntries
		},
		{
			icon: Svgs.FilePlus,
			label: translate(({ projects }) =>
				isImportVariableSet
					? projects.createAndImport.importOptions.moreEntriesToVariableSet
					: projects.createAndImport.importOptions.moreEntriesToDataset
			),
			value: ImportType.MoreEntriesToDataset
		},
		{
			icon: Svgs.Repeat,
			label: translate(({ projects }) =>
				isImportVariableSet
					? projects.createAndImport.importOptions.replaceAllVariableSet
					: projects.createAndImport.importOptions.replaceAll
			),
			value: ImportType.ReplaceAll
		}
	].filter((_, index) => index !== 2 || !hasVariables);

	function handleClose() {
		setPreviousMapping(null);
		goBack();

		isImportVariableSet && setIsImportVariableSet(false);
		importVariableSetName && setImportVariableSetName('');
	}

	function computeTitleAndSubtitle() {
		let title = translate(({ projects }) => projects.createAndImport.title.import);
		let subtitle = translate(({ projects }) => projects.createAndImport.subtitle.import);

		if (isImportVariableSet) {
			title = disableImportOptionsCards
				? translate(({ projects }) => projects.createAndImport.title.replaceAllVariableSet)
				: translate(
						({ projects }) => projects.createAndImport.title.importDataVariableSet,
						false,
						{
							setLabel: importVariableSetLabel
						}
				  );
		}

		if (uploadingFile) {
			if (selectedOption === ImportType.MoreDataToExistingEntries) {
				title = isImportVariableSet
					? translate(
							({ projects }) => projects.createAndImport.title.dataToVariableSet,
							false,
							{
								setLabel: importVariableSetLabel
							}
					  )
					: translate(
							({ projects }) =>
								projects.createAndImport.importOptions.moreDataToEntries
					  );
			}
			if (selectedOption === ImportType.MoreEntriesToDataset) {
				title = isImportVariableSet
					? translate(
							({ projects }) => projects.createAndImport.title.entriesToVariableSet,
							false,
							{
								setLabel: importVariableSetLabel
							}
					  )
					: translate(
							({ projects }) =>
								projects.createAndImport.importOptions.moreEntriesToDataset
					  );
			}
			if (selectedOption === ImportType.ReplaceAll) {
				title = isImportVariableSet
					? translate(
							({ projects }) => projects.createAndImport.title.replaceAllVariableSet,
							false,
							{
								setLabel: importVariableSetLabel
							}
					  )
					: translate(
							({ projects }) => projects.createAndImport.importOptions.replaceAll
					  );
			}

			subtitle = translate(({ projects }) => projects.createAndImport.subtitle.loadingFile);
		}

		return { title, subtitle };
	}

	function isCardDisalbed(value: ImportType) {
		const isAdvanceImport = [
			ImportType.MoreDataToExistingEntries,
			ImportType.MoreEntriesToDataset
		].includes(value);

		/**
		 * HIDE THE ADVANCED IMPORT OPTIONS WHEN NO VARIABLES
		 * ADVANCED IMPORT OPTIONS WORK ONLY IF PROJECT HAS VARIABLES
		 */
		if (!hasVariables && isAdvanceImport) return true;

		return uploadingFile && selectedOption !== value;
	}

	function handleApiFileImportErrors(errors: FileImportErrors) {
		resetImportPercentage();
		setShouldReset(true);
		setFileImportErrors(errors);
	}

	const { subtitle } = computeTitleAndSubtitle();

	const warning =
		(translate(({ projects }) =>
			isImportVariableSet
				? projects.createAndImport.replaceAllWarningVariableSet
				: projects.createAndImport.replaceAllWarning
		) as string) ?? '';
	const splitWarning = warning.split('\n');

	return (
		<>
			<NarrowContainer>
				{!disableImportOptionsCards && (
					<>
						<Subtitle>{subtitle}</Subtitle>

						<CardContainer>
							{IMPORT_OPTIONS.map(({ icon, label, value }) => (
								<Card
									data-testid={`importOptionCard-${value}`}
									key={value}
									title={label}
									selected={selectedOption === value}
									onClick={() =>
										!isCardDisalbed(value) && setSelectedOption(value)
									}
									disabled={isCardDisalbed(value)}
								>
									<Icon
										svg={icon}
										customSize={4}
										marginOffset={{ top: 3.9, bottom: 2.3 }}
										colors={{
											color: isCardDisalbed(value)
												? Colors.text.disabled
												: selectedOption === value
												? Colors.primary.normal
												: Colors.text.main
										}}
										propagate
									/>
									<CardLabel>{label}</CardLabel>
								</Card>
							))}
						</CardContainer>
					</>
				)}
				{selectedOption && (
					<>
						<ImportDropzone
							maxFileSize={DATASET_FILE_SIZE_LIMIT}
							importType={selectedOption}
							handleFinishedLoading={handleFinishedLoading}
							handleUploadingError={handleUploadingError}
							setImportFileName={setImportFileName}
							resetFileImportErrors={() => {
								!!fileImportErrors && setFileImportErrors(null);
							}}
							setShouldReset={() => {
								shouldReset && setShouldReset(false);
							}}
							handleApiFileImportErrors={handleApiFileImportErrors}
							importFileName={importFileName}
						/>
						<Spacer size={s => s.s} />
					</>
				)}
				{fileImportErrors && (
					<ImportErrorWarning
						errors={fileImportErrors}
						onClose={setFileImportErrors}
						importFileName={importFileName}
					/>
				)}
				{uploadingFile && (
					<Typography.Hint marginOffset={{ y: 1 }}>
						{translate(dict => dict.projects.createAndImport.dropzoneLabel)}
					</Typography.Hint>
				)}
				{selectedOption === ImportType.ReplaceAll &&
					hasVariables &&
					(isImportVariableSet ? !isNewVariableSet : true) && (
						<>
							<Warning>{splitWarning[0]}</Warning>
							<Warning marginOffset={{ bottom: 1.6 }}>{splitWarning[1]}</Warning>
						</>
					)}
				<ImportText>{importText?.content}</ImportText>
			</NarrowContainer>

			<StickyFooter
				neutral={
					!uploadingFile && {
						label: translate(({ buttons }) => buttons.cancel),
						onClick: handleClose
					}
				}
				maxWidth={65.2}
			/>
		</>
	);
}
