export const PRINT_PAGE_STYLE = `
@media print {
    body {
        height: auto !important;
    }

    textarea {
        box-sizing: content-box !important;
    }
}
`;
