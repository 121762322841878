import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { User } from 'api/auth';
import { Amplify } from 'aws-amplify';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { Button } from '../component/Button';
import { useProjectData } from '../data/useProjectData/useProjectData';
import { FileForUpload } from '../data/useStoreBase64FileMutation';
import { ROUTE_MAP } from '../utils/routeMap';
import { ErrorModal } from '../ErrorModal';
import { Entry } from '../types';
import { FormFile, isFrontendFormFile } from '../utils/zodUtils';
import { Modal } from 'components/UI/Modal';
import { useEntries } from 'hooks/store';
import { useTracking } from 'app/tracking/TrackingProvider';
import { useRef, useState, useEffect, StrictMode } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { EntryHeader } from '../component/EntryHeader';
import { useGetFormsQuery } from '../data/useGetFormsQuery/useGetFormsQuery';
import { useGetProjectOrganizationCollaboratorsQuery } from '../data/useGetProjectOrganizationCollaboratorsQuery';
import { useGetProjectQuery } from '../data/useGetProjectQuery';
import { useInsertDataEntryMutation } from '../data/useInsertDataEntryMutation';
import { EntryForm } from '../EntryForm';
import { SelectForm, useFormSelector } from '../smart-components/SelectForm';
import { ErrorLoadingEntry, EntryFormSkeleton } from '../update-entry/UpdateEntryPageV1_5';

export const CreateEntryPageV1_5 = () => {
	const navigate = useNavigate();
	const params = useParams();

	const projectId = params.projectId as string;

	const { track } = useTracking();

	const allowLeavePageOverrideRef = useRef(false);

	const [_, fetchEntries] = useEntries({ lazy: true });

	const {
		showFormSelector,
		toggleShowFormSelector,
		getFormsQueryIsLoading,
		formId,
		setSelectedFormId
	} = useFormSelector({
		projectId
	});

	const [selectedGroupId, setSelectedGroupId] = useState<string>();

	const projectDataQuery = useProjectData({
		projectId,
		selectedFormId: formId
	});

	const insertDataEntryMutation = useInsertDataEntryMutation();

	const loading = projectDataQuery.isLoading || getFormsQueryIsLoading;
	const error = projectDataQuery.error;

	if (error) {
		return (
			<ErrorLoadingEntry
				isFetching={projectDataQuery.isFetching}
				refetch={projectDataQuery.refetch}
			/>
		);
	}

	const submitEntry = async (entry: Entry) => {
		try {
			if (!projectDataQuery.data) {
				console.error(
					"onSubmit called but projectDataQuery.data doesn't exist, this is a no-op"
				);
				return;
			}

			const insertDataEntryResult = await insertDataEntryMutation.mutateAsync({
				projectId,
				entry,
				organizationGroupId: selectedGroupId
			});
			allowLeavePageOverrideRef.current = true;

			fetchEntries();

			track({
				eventName: 'entry_created',
				data: {
					entryHasStatus: false
				}
			});

			navigate(
				ROUTE_MAP.project.byId.dataset.update.createPath({
					projectId,
					entryId: insertDataEntryResult.insertedEntry.datasetentryid,
					formId
				}),
				{ replace: true }
			);
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<StrictMode>
			<div className="flex flex-col pt-40">
				{!loading && (
					<SelectForm
						expanded={showFormSelector}
						onFormSelected={setSelectedFormId}
						selectedFormId={formId}
					/>
				)}

				<div className="flex flex-col lg:w-[756px] lg:mx-auto">
					<ErrorModal
						onClose={insertDataEntryMutation.reset}
						error={insertDataEntryMutation.error}
					/>

					{!selectedGroupId && (
						<SelectGroup onGroupSelected={setSelectedGroupId} projectId={projectId} />
					)}

					{loading && <EntryFormSkeleton />}

					{!loading && projectDataQuery.data && (
						<EntryForm
							key={formId}
							Header={
								<Header
									isSubmittingEntry={insertDataEntryMutation.loading}
									formSelectorIsShowing={showFormSelector}
									onShowFormSelectorClicked={toggleShowFormSelector}
								/>
							}
							projectData={projectDataQuery.data}
							onSubmit={submitEntry}
							allowLeavePageOverrideRef={allowLeavePageOverrideRef}
						/>
					)}
				</div>
			</div>
		</StrictMode>
	);
};

const Header = ({
	isSubmittingEntry,
	onShowFormSelectorClicked,
	formSelectorIsShowing
}: {
	isSubmittingEntry: boolean;
	onShowFormSelectorClicked: () => void;
	formSelectorIsShowing: boolean;
}) => {
	const params = useParams();
	const projectId = params.projectId as string;

	const getFormsQuery = useGetFormsQuery({
		projectId
	});

	const getProjectQuery = useGetProjectQuery({ projectId });

	return (
		<EntryHeader
			breadCrumbs={[
				{
					title: getProjectQuery.data?.project.projectName,
					url: ROUTE_MAP.project.byId.dataset.createPath({
						projectId
					})
				}
			]}
			title="Create Entry"
		>
			<div className="flex gap-4">
				{getFormsQuery.data && getFormsQuery.data.activeForms.length > 1 && (
					<button onClick={onShowFormSelectorClicked} className="self-end" type="button">
						<Icon
							svg={Svgs.FileText}
							active={formSelectorIsShowing}
							propagate
							variant={v => v.button}
						/>
					</button>
				)}

				<Button
					loading={isSubmittingEntry}
					className="col-start-4 col-span-2"
					data-testid="entry-form-header_save-button"
					title="Save"
					variant="primary"
				/>

				<Link to={ROUTE_MAP.project.byId.dataset.createPath({ projectId })}>
					<Icon
						svg={Svgs.Close}
						marginOffset={{ left: 2.4 }}
						variant={v => v.buttonActive}
						dataTestId="entry-form-header_close-icon"
						propagate
					/>
				</Link>
			</div>
		</EntryHeader>
	);
};

export const extractFilesFromEntry = ({ entry: originalEntry }: { entry: Entry }) => {
	const entry = { ...originalEntry };

	const files: FileForUpload[] = [];
	Object.entries(entry).forEach(([variableName, value]) => {
		if (isFrontendFormFile(value)) {
			const file: FormFile = value;

			if (file === null) {
				// file should be sent as null to backend for deletion, leave it in
				return;
			}

			if (file?.type !== 'frontend-file') {
				console.error(new Error('File is not a frontend file, this is a no-op'));
				return;
			}

			files.push({
				base64Bytes: file.encodedFile,
				fileName: file.fileName,
				variableName: variableName
			});

			// Backend will probably fail validation if we dont supply something here
			entry[variableName] = 'this does not matter, just not empty';
		}
	});

	return {
		files,
		entryWithoutFiles: entry
	};
};

const NO_GROUP_ID = '-1';

/**
 * Hook to get the current user's ID from amplify.
 *
 * @returns the current user's ID once we've retrieved it from amplify, or undefined if we haven't yet.
 */
const useCurrentUserId = () => {
	const [currentUserId, setCurrentUserId] = useState<string>();
	useEffect(() => {
		async function asyncShit() {
			const user: User = await Amplify.Auth.currentAuthenticatedUser();

			setCurrentUserId(user.username);
		}

		asyncShit();
	}, []);

	return currentUserId;
};

/**
 * UX On this isn't great but we ensure that the user is required to select a group with this approach.
 */
export const SelectGroup = ({
	onGroupSelected,
	projectId
}: {
	onGroupSelected: (groupId?: string) => void;
	projectId: string;
}) => {
	const currentUserId = useCurrentUserId();

	const [showModal, setShowModal] = useState<boolean>(false);
	const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>();

	const projectOrganizationCollaboratorsQuery = useGetProjectOrganizationCollaboratorsQuery({
		projectId
	});
	const groupsWhereCurrentUserIsMember =
		projectOrganizationCollaboratorsQuery.data?.project.organizations.filter(org =>
			org.organizationCollaborators.find(c => c.userId === currentUserId)
		);

	useEffect(() => {
		// As currentUserId can be undefined, executing the code below before it is set will cause issues
		// ensure that the query is not in cached state
		if (!currentUserId || !projectOrganizationCollaboratorsQuery.isFetchedAfterMount) {
			return;
		}

		if (groupsWhereCurrentUserIsMember?.length === 0) {
			return onGroupSelected(NO_GROUP_ID);
		}

		if (groupsWhereCurrentUserIsMember?.length === 1) {
			// No need to show the modal if there is only one group
			return onGroupSelected(groupsWhereCurrentUserIsMember[0].organizationId);
		}

		setShowModal(true);
	}, [groupsWhereCurrentUserIsMember, currentUserId]);

	const selectOptions =
		groupsWhereCurrentUserIsMember?.map(group => ({
			label: group.organizationName,
			value: group.organizationId
		})) || [];

	if (selectOptions.length < 1) {
		return null;
	}

	return (
		<Modal
			size={s => s.s}
			onClose={() => {
				// Not allowed to cancel this
				return;
			}}
			visible={showModal}
			title="Group selection"
			primary={{
				onClick: () => {
					onGroupSelected(selectedGroupId);
				},
				label: 'Confirm'
			}}
		>
			<p className="text-sm">
				As a collaborator with multiple group memberships, select your current
				representative group to proceed. Your choice will determine entry ownership and
				impact operation execution.
			</p>

			<CreatableSelect
				className="mt-4"
				scrollIntoView={false}
				label="Select a group"
				items={selectOptions}
				onValueSelected={value => {
					setSelectedGroupId(value || undefined);
				}}
				value={selectOptions.find(item => item.value === selectedGroupId) || null}
			/>
		</Modal>
	);
};
