import { LedidiRequestError } from './data/makeRequest';
import { Dialog } from './component/Dialog';
import { Button } from './component/Button';

export const ErrorModal = ({ onClose, error }: { onClose: () => void; error?: unknown }) => {
	if (!error) {
		return null;
	}

	const errors = parseErrors(error);

	return (
		<Dialog open onClose={onClose} title="Error submitting entry">
			<div className="flex flex-col w-[600px] h-[720px]">
				<p className="text-base text-error-500 font-semibold ">
					Entry submission failed with the following error(s)
				</p>

				<ul className="mt-4 flex-1 overflow-auto list-disc">
					{errors.map((error, index) => {
						return (
							<li key={index} className="text-base">
								{error}
							</li>
						);
					})}
				</ul>

				<Button title="Close" onClick={onClose} />
			</div>
		</Dialog>
	);
};

export const parseErrors = (error: unknown) => {
	if (!(error instanceof LedidiRequestError)) {
		return [
			'An unexpected error occurred, please try again. Contact support if the problem persists.'
		];
	}

	return error.errors?.map(error => {
		if (error.code === 'error.invalidRequest') {
			return 'Some fields are not matching the schema';
		}

		if (error.code === 'error.uniqueFieldValue') {
			return 'Some unique field value is already taken';
		}

		if (error.code === 'error.conflict') {
			return 'This entry has been updated or deleted by another user, please refresh the page and try again.';
		}

		if (error.code === 'error.licence') {
			return 'This project has reached the maximum amount of entries available on our free plan (35). In order to add more entries, please upgrade your licence.';
		}

		if (error.code === 'error.licence.other') {
			return 'This project has reached the maximum amount of entries available on our free plan (35). In order to add more entries, contact the project owner and have them upgrade their licence.';
		}

		console.warn(`Unhandled error code: ${error.code}`);

		return 'An unexpected error occurred, please try again. Contact support if the problem persists.';
	});
};
