import clsx from 'clsx';
import { Icon } from 'components/UI/Icons';
import { Loader } from 'components/UI/Loader';
import { Colors, Svgs } from 'environment';
import { ButtonHTMLAttributes } from 'react';

type Props = {
	title: string;
	variant?: 'primary' | 'secondary' | 'danger';
	loading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = ({
	title,
	loading,
	disabled,
	className,
	variant = 'primary',
	...props
}: Props) => {
	return (
		<button
			className={clsx(
				'text-sm font-semibold min-w-56 min-h-16 px-6 flex items-center justify-center',
				{
					'disabled:opacity-50': disabled,
					'bg-primary-500 text-white rounded-md hover:bg-primary-700':
						variant === 'primary',
					'text-gray-800 border rounded-md border-gray-800/25 hover:bg-gray-800/10':
						variant === 'secondary',
					'bg-error-500/25 text-error-700 rounded-md hover:bg-error-700/25':
						variant === 'danger'
				},
				className
			)}
			disabled={loading || disabled}
			{...props}
		>
			{variant === 'danger' && (
				<Icon
					svg={Svgs.Delete}
					colors={{
						color: Colors.red.dark
					}}
					size={s => s.m}
					className="mr-2"
				/>
			)}
			{loading ? (
				<Loader
					color={c => {
						if (variant === 'danger') {
							return c.error;
						}

						return c.white;
					}}
				/>
			) : (
				title
			)}
		</button>
	);
};
