import { Menu } from '@headlessui/react';
import clsx from 'clsx';
import { Icon } from 'components/UI/Icons';
import { InputLabel } from 'components/UI/Inputs/InputLabel';
import { Loader } from 'components/UI/Loader';
import { Svgs } from 'environment';
import { OptionalDescriptionTooltip } from 'features/entry-form-v2/component/OptionalDescriptionTooltip';
import { useFileInfoQuery } from 'features/entry-form-v2/data/useFileInfoQuery';
import { FormFile, isBackendFormFile } from 'features/entry-form-v2/utils/zodUtils';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PreviewDocument } from './PreviewDocument/PreviewDocument';
import { useTracking } from 'app/tracking/TrackingProvider';
import { extractFileExtension } from './utils';

interface Props {
	variableName: string;
	label: string;

	onValueChange: (frontendFile: FormFile | null) => void;
	required?: boolean;

	initialFile?: FormFile;
	onError: (error?: string) => void;
	error?: string;
	description?: string;
	className?: string;
}

export const FileInput = ({
	variableName,
	onValueChange,
	label,
	required,
	initialFile,
	onError,
	error,
	description,
	className
}: Props) => {
	const params = useParams();
	const entryId = params.entryId as string;
	const projectId = params.projectId as string;

	const id = variableName + '_file-input';

	const fileInfoQuery = useFileInfoQuery({
		entryId,
		projectId,
		fileId: isBackendFormFile(initialFile) ? initialFile.fileId : '',
		enabled: initialFile?.type === 'backend-file'
	});

	const inputRef = useRef<HTMLInputElement>(null);
	const [file, setFile] = useState<File>();
	const [previewFileUrl, setPreviewFileUrl] = useState<string>();

	const { track } = useTracking();

	useEffect(() => {
		if (fileInfoQuery.data && fileInfoQuery.data.doc.metadata.fileName !== file?.name) {
			setFile(new File([], fileInfoQuery.data.doc.metadata.fileName));
		}
	}, [fileInfoQuery]);

	const handlePreview = (args: { fileName: string; mimeType: string; fileUrl: string }) => {
		const { fileName, mimeType, fileUrl } = args;
		const fileExtension = extractFileExtension(fileName);

		track({
			eventName: 'file_preview',
			data: {
				fileExtension,
				mimeType
			}
		});

		setPreviewFileUrl(fileUrl);
	};

	const handleDelete = () => {
		setFile(undefined);
		onValueChange(null);
		if (inputRef.current) {
			inputRef.current.value = '';
		}
	};

	return (
		<div className={clsx('flex flex-col gap-2 col-span-full', className)}>
			<div className="flex items-center gap-1">
				<InputLabel label={label} required={required} />
				<OptionalDescriptionTooltip description={description} />
			</div>

			<div className="flex gap-2 items-center">
				<input
					ref={inputRef}
					type="file"
					hidden
					data-testid={`file-input_${variableName}`}
					id={id}
					onChange={async e => {
						if (e.target.files) {
							onError(undefined);
							const file = e.target.files[0];

							if (!file) {
								onValueChange(null);
								return;
							}

							const fileSizeInMb = file.size / 1024 / 1024;
							if (fileSizeInMb > 5) {
								onError('File size must be less than 5MB');
								return;
							}

							try {
								const encodedFile = await base64EncodeFile(file); // `file` your img file

								setFile(file);
								onValueChange({
									encodedFile: encodedFile,
									fileName: file.name,
									type: 'frontend-file'
								});
							} catch (e) {
								onError("Could not enocde file, ensure it's a valid file.");
							}
						}
					}}
				/>

				<label
					htmlFor={id}
					className={clsx(
						'flex grow bg-stone-100 items-center justify-center border-dashed border-stone-300 border-2 rounded-md self-stretch',
						!file && !fileInfoQuery.isLoading && 'p-10',
						(file || fileInfoQuery.isLoading) && 'px-10 py-4'
					)}
				>
					{fileInfoQuery.isLoading && <Loader />}

					{!file && !fileInfoQuery.isLoading && (
						<p className="text-base">
							Click to <b className="text-primary-500 text-base">Browse</b> for a file
							to upload
						</p>
					)}

					{file && <p className="text-base">{file.name}</p>}
				</label>

				{file && initialFile?.type === 'frontend-file' ? (
					<Icon
						className="shrink-0 aspect-square"
						variant={v => v.button}
						svg={Svgs.Delete}
						onClick={handleDelete}
					/>
				) : (
					file &&
					fileInfoQuery.data && (
						<OptionsMenu
							onDownloadClicked={() => {
								downloadFile({
									fileUrl: fileInfoQuery.data.doc.fileURL,
									fileName: file.name
								});
							}}
							onPreviewClicked={() =>
								handlePreview({
									fileName: fileInfoQuery.data.doc.metadata.fileName,
									mimeType: fileInfoQuery.data.doc.metadata.mimeType,
									fileUrl: fileInfoQuery.data.doc.fileURL
								})
							}
							onDeleteClicked={handleDelete}
						/>
					)
				)}
			</div>

			{error && <p className="text-error-500 font-semibold text-sm">{error}</p>}
			{previewFileUrl && (
				<PreviewDocument
					projectId={projectId}
					fileUrl={previewFileUrl}
					onClose={() => {
						setPreviewFileUrl(undefined);
					}}
				/>
			)}
		</div>
	);
};

async function downloadFile({ fileName, fileUrl }: { fileUrl: string; fileName: string }) {
	try {
		const response = await fetch(fileUrl);

		const contentType = response.headers.get('content-type');
		const blob = await response.blob();

		let extension = '';
		if (contentType) {
			extension = EXTENSIONS_BY_MIME_TYPE[contentType] || '';
		}

		const finalFileName = fileName.endsWith(extension) ? fileName : `${fileName}${extension}`;

		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = finalFileName;

		document.body.appendChild(link);
		link.click();

		// Cleanup
		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	} catch (error) {
		console.error(error);
	}
}

export async function base64EncodeFile(file: File): Promise<string> {
	const result = await new Promise<string | ArrayBuffer | null>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.onerror = reject;
	});

	if (result === null || result === undefined) {
		throw new Error('Could not read file, ensure it is a valid file');
	}

	let encoded = typeof result === 'string' ? result : new TextDecoder().decode(result);

	if (encoded.startsWith('data:')) {
		// Altho the backend accepts this data, it will get an internal server error at a later stage when trying to read the file.
		// Removing the mimeType info from the encoded file will fix this issue.
		const [_mimeTypeInfo, actualFile] = encoded.split(',');
		encoded = actualFile;
	}

	return encoded;
}

const OptionsMenu = ({
	onDeleteClicked,
	onDownloadClicked,
	onPreviewClicked
}: {
	onDeleteClicked: () => void;
	onDownloadClicked: () => void;
	onPreviewClicked: () => void;
}) => {
	return (
		<div className="relative">
			<Menu>
				<Menu.Button>
					<Icon svg={Svgs.More} variant={v => v.buttonActive} />
				</Menu.Button>

				<Menu.Items className="absolute overflow-hidden top-12 right-0 w-48 bg-white rounded-lg z-50 shadow-normal flex flex-col items-stretch">
					<Menu.Item>
						<button
							className="hover:text-white hover:bg-primary-500 p-4 text-start text-base"
							type="button"
							onClick={onPreviewClicked}
						>
							Preview
						</button>
					</Menu.Item>

					<Menu.Item>
						<button
							className="hover:text-white hover:bg-primary-500 p-4 text-start text-base"
							type="button"
							onClick={onDownloadClicked}
						>
							Download
						</button>
					</Menu.Item>

					<Menu.Item>
						<button
							className="hover:text-white hover:bg-primary-500 p-4 text-start text-base"
							type="button"
							onClick={onDeleteClicked}
						>
							Delete
						</button>
					</Menu.Item>
				</Menu.Items>
			</Menu>
		</div>
	);
};

const EXTENSIONS_BY_MIME_TYPE: Record<string, string> = {
	// Documents
	'application/msword': '.doc',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
	'application/vnd.ms-excel': '.xls',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
	'application/vnd.ms-powerpoint': '.ppt',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
	'application/pdf': '.pdf',
	'application/rtf': '.rtf',
	'text/csv': '.csv',

	// Images
	'image/jpeg': '.jpg',
	'image/png': '.png',
	'image/gif': '.gif',
	'image/svg+xml': '.svg',
	'image/webp': '.webp',
	'image/tiff': '.tiff',

	// Web & Data
	'text/html': '.html',
	'text/plain': '.txt',
	'application/json': '.json',
	'application/xml': '.xml',
	'text/xml': '.xml',

	// Archives
	'application/zip': '.zip',
	'application/x-rar-compressed': '.rar',
	'application/x-7z-compressed': '.7z',
	'application/gzip': '.gz',

	// Audio
	'audio/mpeg': '.mp3',
	'audio/wav': '.wav',
	'audio/midi': '.midi',

	// Video
	'video/mp4': '.mp4',
	'video/mpeg': '.mpeg',
	'video/webm': '.webm',

	// Email
	'message/rfc822': '.eml',

	// Others
	'application/vnd.ms-visio.drawing': '.vsd',
	'application/x-msdos-program': '.exe',
	'application/x-msdownload': '.exe',
	'application/vnd.android.package-archive': '.apk'
};
