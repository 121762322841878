import { Dialog as HeadlessUIDialog } from '@headlessui/react';
import { Icon } from 'components/UI/Icons/Icon';
import { Svgs } from 'environment';

interface DialogProps {
	children: React.ReactNode;
	open: boolean;
	onClose: () => void;
	title: string;
	description?: string;
}

export const Dialog = ({ children, open, onClose, title, description }: DialogProps) => {
	return (
		<HeadlessUIDialog open={open} onClose={onClose} className="relative z-50">
			{/* The backdrop, rendered as a fixed sibling to the panel container */}
			<div className="fixed inset-0 bg-black/30" aria-hidden="true" />

			{/* Full-screen container to center the panel */}
			<div className="fixed inset-0 flex w-screen items-center justify-center">
				{/* The actual dialog panel  */}
				<HeadlessUIDialog.Panel className="mx-auto rounded bg-white p-10 flex flex-col gap-10 max-h-[90vh] max-w-[90vw] overflow-y-auto">
					<div className="flex justify-between items-center">
						<HeadlessUIDialog.Title className="text-2xl">
							{title}
						</HeadlessUIDialog.Title>

						{onClose ? (
							<button onClick={onClose}>
								<Icon svg={Svgs.Close} propagate variant={v => v.button} />
							</button>
						) : null}
					</div>

					{description && (
						<HeadlessUIDialog.Description className="text-base">
							{description}
						</HeadlessUIDialog.Description>
					)}

					{children}
				</HeadlessUIDialog.Panel>
			</div>
		</HeadlessUIDialog>
	);
};
