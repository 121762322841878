import { OptionalDescriptionTooltip } from './OptionalDescriptionTooltip';
import { FormControl } from '../EntryForm';
import { Controller, useController } from 'react-hook-form';
import clsx from 'clsx';
import { InputError } from 'components/UI/Inputs/InputError';
import { Asterisk } from 'components/UI/Asterisk';
import { useState } from 'react';
import { Svgs } from 'environment';
import { Icon } from 'components/UI/Icons';
import { CategoryOrientation } from '../data/useGetFormsQuery/useGetFormsQuery';

type Props = {
	description: string;
	categories: { value: string; label: string }[];
	allowCreate?: boolean;
	control: FormControl;
	disabled?: boolean;
	className?: string;
	variableName: string;
	label: string;
	obligatory?: boolean;
	orientation: CategoryOrientation;
};

export const RadioGroup = ({
	description,
	categories,
	allowCreate,
	control,
	disabled,
	className,
	variableName,
	label,
	obligatory,
	orientation
}: Props) => {
	const { field, fieldState } = useController({ control, name: variableName });

	const error = fieldState.error?.message?.toString();

	const [showNewValueInput, setShowNewValueInput] = useState(false);
	const onAddEditNewValueClick = () => {
		if (categories.find(category => category.value === field.value)) {
			field.onChange('');
		}
		setShowNewValueInput(true);
	};

	const onNewValueSubmit = () => {
		setShowNewValueInput(false);
	};

	let allCategories = [...categories];
	if (
		field.value &&
		!allCategories.find(category => category.value === field.value) &&
		!showNewValueInput
	) {
		allCategories = [...allCategories, { value: field.value, label: field.value }];
	}

	return (
		<fieldset className={clsx('flex flex-col', className)} role="radiogroup">
			<div className="flex gap-1">
				<legend className={clsx('font-semibold text-sm', disabled && 'text-gray-500')}>
					{label}
				</legend>

				{obligatory && <Asterisk />}

				<OptionalDescriptionTooltip description={description} />
			</div>

			<div
				className={clsx(
					'flex gap-3 flex-wrap mt-4',
					orientation === 'vertical' && 'flex-col'
				)}
			>
				{allCategories.map(category => {
					const id = createInputDataTestId({
						value: category.value,
						variableName
					});

					return (
						<Controller
							key={category.value}
							control={control}
							name={variableName}
							render={({ field }) => (
								<div className="flex gap-1 items-center">
									<input
										ref={field.ref}
										value={category.value}
										onChange={() => {
											// do nothing, handled in onClick
										}}
										onClick={() => {
											if (field.value === category.value) {
												field.onChange('');
												return;
											}

											field.onChange(category.value);
										}}
										checked={
											showNewValueInput
												? false
												: field.value === category.value
										}
										type="radio"
										id={id}
										data-testid={id}
										disabled={disabled}
									/>

									<label
										className={clsx('text-base', disabled && 'text-gray-500')}
										htmlFor={id}
									>
										{category.label}
									</label>
								</div>
							)}
						/>
					);
				})}

				{showNewValueInput && (
					<div className={clsx('flex gap-2 items-center')}>
						<div>
							<input
								autoFocus
								placeholder="New value"
								type="text"
								onChange={field.onChange}
								value={field.value}
								className={clsx(
									'border border-gray-300 rounded-md p-2 px-3 text-base',
									error && 'border-error-500'
								)}
								onKeyDown={e => {
									if (e.key === 'Enter') {
										e.preventDefault();
										onNewValueSubmit();
									}
								}}
								onBlur={onNewValueSubmit}
							/>
						</div>

						<button type="button" onClick={onNewValueSubmit}>
							<Icon svg={Svgs.Checkmark} size={s => s.m} />
						</button>
					</div>
				)}

				{allowCreate && !showNewValueInput && (
					<button
						onClick={onAddEditNewValueClick}
						type="button"
						className={clsx(
							'text-blue-600 ml-4 hover:underline text-base',
							orientation === 'vertical' && 'self-start'
						)}
					>
						{categories.find(category => category.value === field.value) ||
						field.value === ''
							? '+ Add new value'
							: 'Edit value'}
					</button>
				)}
			</div>

			<InputError error={error} />
		</fieldset>
	);
};

export const createInputDataTestId = ({
	value,
	variableName
}: {
	variableName: string;
	value: string;
}) => variableName + '_' + value;
