import { Entry } from '../types';
import { extractFilesFromEntry } from '../create-entry/CreateEntryPageV1_5';
import { submitFiles } from './submitFiles';
import { useMutation } from '@tanstack/react-query';
import { makeRequest } from './makeRequest';

export type DataEntryMutationResult = {
	httpStatusCode: number;
	insertedEntry: {
		datasetentryid: string;
	};
	errors?: {
		code: string; // some unique field value is already taken
	}[];
};

export const useInsertSeriesDataEntryMutation = () => {
	return useMutation({
		mutationFn: async ({
			projectId,
			entry,
			organizationGroupId,
			mainSetRevisionId,
			setName
		}: {
			projectId: string;
			entry: Entry;
			organizationGroupId?: string;
			mainSetRevisionId: string;
			setName: string;
		}) => {
			const { entryWithoutFiles, files } = extractFilesFromEntry({
				entry
			});

			const result: DataEntryMutationResult = await makeRequest({
				method: 'insertDataEntry',
				service: 'data-entry',
				data: {
					projectId,
					observationData: entryWithoutFiles,
					organizationId: organizationGroupId,
					mainSetRevisionId,
					set: {
						setName
					}
				}
			});

			if (files.length > 0) {
				await submitFiles({
					entryId: result.insertedEntry.datasetentryid,
					files,
					projectId
				});
			}

			return result;
		}
	});
};
