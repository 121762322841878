import { Link, useNavigate, useParams } from 'react-router-dom';
import { EntryHeader } from '../../component/EntryHeader';
import { useGetProjectQuery } from '../../data/useGetProjectQuery';
import { ROUTE_MAP } from '../../utils/routeMap';

import { EntryForm } from 'features/entry-form-v2/EntryForm';
import { Button } from 'features/entry-form-v2/component/Button';
import { Entry } from 'features/entry-form-v2/types';
import { useEffect, useRef, useState } from 'react';
import { ErrorModal } from 'features/entry-form-v2/ErrorModal';
import { useTracking } from 'app/tracking/TrackingProvider';
import { Menu } from '@headlessui/react';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { useDeleteDataEntryMutation } from 'features/entry-form-v2/data/deleteDataEntryMutation';
import { useReactToPrint } from 'react-to-print';
import { PRINT_PAGE_STYLE } from 'features/entry-form-v2/utils/print';
import { useGetLatestDataEntryVersionQuery } from 'features/entry-form-v2/data/useGetLatestDataEntryVersionQuery';
import { PrintEntryForm } from 'features/entry-form-v2/PrintEntryForm';
import { useUpdateDataEntryMutation } from 'features/entry-form-v2/data/useUpdateDataEntryMutation';
import { useInsertSeriesDataEntryMutation } from 'features/entry-form-v2/data/useInsertSeriesDataEntryMutation';
import {
	isSeriesFormItem,
	useProjectData
} from 'features/entry-form-v2/data/useProjectData/useProjectData';
import { SelectGroup } from 'features/entry-form-v2/create-entry/CreateEntryPageV1_5';
import {
	EntryFormSkeleton,
	ErrorLoadingEntry,
	OptionsMenuButton,
	SubmitDiffModal
} from 'features/entry-form-v2/update-entry/UpdateEntryPageV1_5';
import { SelectForm, useFormSelector } from 'features/entry-form-v2/smart-components/SelectForm';
import { useGetFormsQuery } from 'features/entry-form-v2/data/useGetFormsQuery/useGetFormsQuery';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog } from 'features/entry-form-v2/component/Dialog';
import { Input } from 'features/entry-form-v2/component/Input';
import { EntryDetailsModal } from 'features/entry-form-v2/smart-components/EntryDetailsModal';
import { EntryHistoryModal } from 'features/entry-form-v2/smart-components/EntryHistoryModal';
import { MoreIcon } from 'components/Icons';

export const UpdateSeriesEntryPageV1_5 = ({
	hidePrintEntryForm
}: {
	hidePrintEntryForm?: boolean;
}) => {
	const { track } = useTracking();
	const params = useParams();
	const navigate = useNavigate();

	const entryId = params.entryId as string;
	const projectId = params.projectId as string;
	const seriesName = params.seriesName as string;
	const seriesEntryId = params.seriesEntryId as string;

	const allowLeavePageOverrideRef = useRef(false);

	const [selectedGroupId, setSelectedGroupId] = useState<string>();

	const insertSeriesDataEntryMutation = useInsertSeriesDataEntryMutation();

	const projectDataQuery = useProjectData({
		projectId
	});
	const seriesFormItem = projectDataQuery.data?.formItems
		.filter(isSeriesFormItem)
		.find(formItem => formItem.name === seriesName);

	const {
		formId,
		showFormSelector,
		toggleShowFormSelector,
		setSelectedFormId,
		getFormsQueryIsLoading
	} = useFormSelector({
		projectId,
		seriesName
	});

	const seriesProjecDataQuery = useProjectData({
		projectId,
		seriesName,
		selectedFormId: formId
	});

	const getLatestDataEntryVersionQuery = useGetLatestDataEntryVersionQuery({
		entryId: seriesEntryId,
		projectId,
		setName: seriesName
	});
	useEffect(() => {
		// Make sure the url matches the latest entry id so updates are most likely to work
		if (
			getLatestDataEntryVersionQuery.data &&
			getLatestDataEntryVersionQuery.data.entry.datasetentryid !== entryId
		) {
			navigate(
				ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.update.createPath({
					projectId,
					entryId,
					seriesName,
					seriesEntryId: getLatestDataEntryVersionQuery.data.entry.datasetentryid,
					formId
				}),
				{ replace: true }
			);
		}
	}, [getLatestDataEntryVersionQuery.data]);

	const updateDataEntryMutation = useUpdateDataEntryMutation();
	const [dataPendingSubmission, setDataPendingSubmission] = useState<Entry | undefined>(
		undefined
	);
	const [entrySubmissionError, setEntrySubmissionError] = useState<string>();

	const submitSeriesEntry = async (entry: Entry) => {
		if (!seriesProjecDataQuery.data) {
			console.error(
				"onSubmit called but projectDataQuery.data doesn't exist, this is a no-op"
			);
			return;
		}

		try {
			setEntrySubmissionError(undefined);

			const result = await updateDataEntryMutation.mutate({
				projectId,
				entryId: seriesEntryId,
				entry,
				setName: seriesName
			});
			track({
				eventName: 'entry_updated',
				data: {
					entryHasStatus: false,
					type: 'series-entry'
				}
			});
			allowLeavePageOverrideRef.current = true;

			navigate(
				ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.update.createPath({
					projectId,
					entryId,
					seriesName,
					seriesEntryId: result.updatedEntry.datasetentryid,
					formId
				}),
				{ replace: true }
			);
		} catch (e) {
			console.error(e);
			setEntrySubmissionError(
				'Error updating entry, please try again. Contact support if the problem persists.'
			);
		} finally {
			setDataPendingSubmission(undefined);
		}
	};

	const errorLoadingEntry = seriesProjecDataQuery.error || getLatestDataEntryVersionQuery.error;
	const loading =
		seriesProjecDataQuery.isLoading ||
		getLatestDataEntryVersionQuery.isLoading ||
		getFormsQueryIsLoading;

	return (
		<div className="flex flex-col pt-40">
			{!loading && (
				<SelectForm
					expanded={showFormSelector}
					onFormSelected={setSelectedFormId}
					selectedFormId={formId}
					seriesName={seriesName}
				/>
			)}

			<div className="flex flex-col md:w-[756px] md:mx-auto px-4 md:px-10">
				{loading && <EntryFormSkeleton />}

				{errorLoadingEntry && (
					<ErrorLoadingEntry
						isFetching={seriesProjecDataQuery.isFetching}
						refetch={seriesProjecDataQuery.refetch}
					/>
				)}

				{entrySubmissionError && (
					<p className="font-semibold text-base text-error-700/75 bg-error-700/10 border border-error-700/75 rounded-md p-4 text-center mb-10">
						{entrySubmissionError}
					</p>
				)}

				{!loading && seriesProjecDataQuery.data && getLatestDataEntryVersionQuery.data && (
					<>
						<ErrorModal
							onClose={insertSeriesDataEntryMutation.reset}
							error={insertSeriesDataEntryMutation.error}
						/>

						{!selectedGroupId && (
							<SelectGroup
								onGroupSelected={setSelectedGroupId}
								projectId={projectId}
							/>
						)}

						<SubmitDiffModal
							dataPendingSubmission={dataPendingSubmission}
							initialEntry={getLatestDataEntryVersionQuery.data.entry}
							onClose={() => setDataPendingSubmission(undefined)}
							onConfirm={submitSeriesEntry}
							onDelete={() =>
								navigate(
									ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.createPath(
										{
											projectId,
											entryId,
											seriesName
										}
									)
								)
							}
							submitting={updateDataEntryMutation.loading}
							variables={seriesProjecDataQuery.data.variables}
						/>

						<EntryForm
							key={getLatestDataEntryVersionQuery.data.entry.datasetentryid + formId}
							Header={
								<Header
									isSubmitting={insertSeriesDataEntryMutation.isLoading}
									seriesLabel={seriesFormItem?.label}
									hidePrintEntryForm={hidePrintEntryForm}
									formSelectorIsShowing={showFormSelector}
									onShowFormSelectorClicked={toggleShowFormSelector}
								/>
							}
							onSubmit={async entry => setDataPendingSubmission(entry)}
							projectData={seriesProjecDataQuery.data}
							initialEntry={getLatestDataEntryVersionQuery.data.entry}
							allowLeavePageOverrideRef={allowLeavePageOverrideRef}
						/>
					</>
				)}
			</div>
		</div>
	);
};

const Header = ({
	isSubmitting,
	seriesLabel,
	hidePrintEntryForm,
	formSelectorIsShowing,
	onShowFormSelectorClicked
}: {
	seriesLabel?: string;
	isSubmitting: boolean;
	hidePrintEntryForm?: boolean;
	formSelectorIsShowing: boolean;
	onShowFormSelectorClicked: () => void;
}) => {
	const params = useParams();
	const navigate = useNavigate();

	const contentRef = useRef<HTMLDivElement>(null);
	const reactToPrint = useReactToPrint({ contentRef, pageStyle: PRINT_PAGE_STYLE });

	const projectId = params.projectId as string;
	const entryId = params.entryId as string;
	const seriesName = params.seriesName as string;
	const seriesEntryId = params.seriesEntryId as string;

	const getProjectQuery = useGetProjectQuery({ projectId });

	const dataEntryQuery = useGetLatestDataEntryVersionQuery({
		entryId,
		projectId
	});

	const getFormsQuery = useGetFormsQuery({
		projectId,
		seriesName
	});

	const getLatestDataEntryVersionQuery = useGetLatestDataEntryVersionQuery({
		entryId: seriesEntryId,
		projectId,
		setName: seriesName
	});

	const projectDataQuery = useProjectData({
		projectId,
		seriesName,
		selectedFormId: params.formId
	});

	return (
		<>
			{projectDataQuery.data &&
				getLatestDataEntryVersionQuery.data &&
				!hidePrintEntryForm && (
					<div className="hidden print:block">
						<PrintEntryForm
							projectData={projectDataQuery.data}
							initialEntry={getLatestDataEntryVersionQuery.data.entry}
							contentRef={contentRef}
						/>
					</div>
				)}

			<EntryHeader
				breadCrumbs={[
					{
						title: getProjectQuery.data?.project.projectName,
						url: ROUTE_MAP.project.byId.dataset.createPath({
							projectId
						})
					},
					{
						title: 'Update Entry',
						url: ROUTE_MAP.project.byId.dataset.update.createPath({
							projectId,
							entryId
						})
					},
					{
						title: seriesLabel,
						url: ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.createPath({
							projectId,
							entryId,
							seriesName
						})
					}
				]}
				title="Update Series Entry"
			>
				<div className="flex gap-4 items-center">
					{getFormsQuery.data && getFormsQuery.data.activeForms.length > 1 && (
						<button
							onClick={onShowFormSelectorClicked}
							className="self-end"
							type="button"
						>
							<Icon
								svg={Svgs.FileText}
								active={formSelectorIsShowing}
								propagate
								variant={v => v.button}
							/>
						</button>
					)}

					<OptionsMenu
						onEntryDeleted={() =>
							navigate(
								ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.createPath(
									{ projectId, entryId, seriesName }
								)
							)
						}
						entryId={seriesEntryId}
						projectId={projectId}
						hasDeleteAccess={!!dataEntryQuery.data?.entryAccess.delete}
						handlePrint={reactToPrint}
						seriesName={seriesName}
					/>

					<Button title="Save" loading={isSubmitting} />

					<Link
						to={ROUTE_MAP.project.byId.dataset.update.series.bySeriesName.createPath({
							projectId,
							entryId,
							seriesName
						})}
					>
						<Icon
							svg={Svgs.Close}
							variant={v => v.buttonActive}
							dataTestId="entry-form-header_close-icon"
							propagate
						/>
					</Link>
				</div>
			</EntryHeader>
		</>
	);
};

const OptionsMenu = ({
	entryId,
	projectId,
	onEntryDeleted,
	hasDeleteAccess,
	handlePrint,
	seriesName
}: {
	entryId: string;
	projectId: string;
	onEntryDeleted: () => void;
	hasDeleteAccess: boolean;
	handlePrint: () => void;
	seriesName: string;
}) => {
	const { track } = useTracking();

	const [showDeleteEntryModal, setShowDeleteEntryModal] = useState(false);
	const [showEntryHistoryModal, setShowEntryHistoryModal] = useState(false);
	const [showEntryDetailsModal, setShowEntryDetailsModal] = useState(false);

	return (
		<div className="relative">
			<Menu>
				<Menu.Button className="rounded-full p-2 hover:bg-gray-700/10">
					<MoreIcon className="text-icon-base" aria-label="More options" />
				</Menu.Button>

				<Menu.Items className="absolute overflow-hidden top-14 right-0 w-48 bg-white rounded-lg z-50 shadow-normal flex flex-col items-stretch">
					<Menu.Item>
						<OptionsMenuButton
							ariaLabel="Print entry"
							onClick={() => {
								handlePrint();
								track({
									eventName: 'print_entry_clicked',
									data: {
										entryType: 'entry'
									}
								});
							}}
						>
							Print entry
						</OptionsMenuButton>
					</Menu.Item>

					<Menu.Item>
						<OptionsMenuButton
							onClick={() => setShowEntryDetailsModal(true)}
							ariaLabel="Entry details"
						>
							Entry details
						</OptionsMenuButton>
					</Menu.Item>

					<Menu.Item>
						<OptionsMenuButton
							onClick={() => setShowEntryHistoryModal(true)}
							ariaLabel="Entry history"
						>
							Entry history
						</OptionsMenuButton>
					</Menu.Item>

					{hasDeleteAccess && (
						<Menu.Item>
							<OptionsMenuButton
								ariaLabel="Delete entry"
								onClick={() => setShowDeleteEntryModal(true)}
							>
								Delete entry
							</OptionsMenuButton>
						</Menu.Item>
					)}
				</Menu.Items>
			</Menu>

			{hasDeleteAccess && (
				<DeleteEntryModal
					entryId={entryId}
					projectId={projectId}
					show={showDeleteEntryModal}
					seriesName={seriesName}
					onClose={() => setShowDeleteEntryModal(false)}
					onEntryDeleted={onEntryDeleted}
				/>
			)}

			<EntryDetailsModal
				open={showEntryDetailsModal}
				onClose={() => setShowEntryDetailsModal(false)}
				entryId={entryId}
				projectId={projectId}
				seriesName={seriesName}
			/>

			<EntryHistoryModal
				open={showEntryHistoryModal}
				onClose={() => setShowEntryHistoryModal(false)}
				entryId={entryId}
				projectId={projectId}
				seriesName={seriesName}
			/>
		</div>
	);
};

const CONFIRMATION_MESSAGE = 'Delete entry permanently';
const deleteEntrySchema = z.object({
	confirmationMessage: z.literal(CONFIRMATION_MESSAGE)
});
const DeleteEntryModal = ({
	show,
	onClose,
	entryId,
	projectId,
	onEntryDeleted,
	seriesName
}: {
	show: boolean;
	onClose: () => void;
	onEntryDeleted: () => void;
	entryId: string;
	projectId: string;
	seriesName: string;
}) => {
	const deleteDataEntryMutation = useDeleteDataEntryMutation();
	const { track } = useTracking();

	const form = useForm({
		resolver: zodResolver(deleteEntrySchema)
	});

	return (
		<Dialog open={show} onClose={onClose} title="Delete Series Entry">
			<form
				className="flex flex-col gap-10"
				onSubmit={form.handleSubmit(async () => {
					try {
						await deleteDataEntryMutation.mutate({
							entryId,
							projectId,
							setName: seriesName
						});
						track({
							eventName: 'entry_deleted',
							data: {
								type: 'series-entry'
							}
						});

						onEntryDeleted();
					} catch (e) {
						console.error(e);
					}
				})}
			>
				<p className="text-base">Are you sure you want to delete this series entry?</p>

				<p className="font-bold text-error-500 text-base">
					Warning! This action cannot be undone!
				</p>

				<Input
					{...form.register('confirmationMessage')}
					label={`Type "${CONFIRMATION_MESSAGE}" to confirm deletion`}
					id="confirmation_message"
				/>

				{deleteDataEntryMutation.error && (
					<p className="text-error-500 font-semibold text-base">
						Failed to delete entry, please try again.
					</p>
				)}

				<div className="flex justify-end gap-3">
					<Button
						variant="secondary"
						className="flex-1"
						type="button"
						onClick={onClose}
						title="Cancel"
					/>

					<Button
						variant="danger"
						className="flex-1"
						disabled={form.watch('confirmationMessage') !== CONFIRMATION_MESSAGE}
						type="submit"
						title="Delete entry"
						loading={deleteDataEntryMutation.loading}
					/>
				</div>
			</form>
		</Dialog>
	);
};
