import { Entry } from 'features/entry-form-v2/types';
import { BackendEntryValue } from './entriesSort';

export const createFilterByFreeText = (freeText: string) => {
	const lowerCasedFreeText = freeText.toLowerCase();

	return (entry: Entry) => {
		return Object.values(entry).some((value: BackendEntryValue) => {
			if (typeof value === 'string') {
				return value.toLowerCase().includes(lowerCasedFreeText);
			}

			if (Array.isArray(value)) {
				return value.some(v => v.toLowerCase().includes(lowerCasedFreeText));
			}

			if (typeof value === 'number') {
				return value.toString().includes(freeText);
			}

			return false;
		});
	};
};
