import { makeRequest } from './makeRequest';
import { Entry, EntryAccess, Status } from '../types';
import { useQuery } from '@tanstack/react-query';

export type GetLatesetDataEntryVersionResponse = {
	entry: Entry;
	entryAccess: EntryAccess;
	httpStatusCode: number;
	statuses?: Status;
	latestEntryId: string;
};

export const useGetLatestDataEntryVersionQuery = ({
	entryId,
	projectId,
	setName
}: {
	entryId: string;
	projectId: string;
	setName?: string;
}) => {
	return useQuery<GetLatesetDataEntryVersionResponse>({
		queryKey: ['latestDataEntryVersion', entryId],
		queryFn: () => getLatestEntryVersion({ entryId, projectId, setName })
	});
};

export const getLatestEntryVersion = async ({
	entryId,
	projectId,
	setName
}: {
	entryId: string;
	projectId: string;
	setName?: string;
}): Promise<GetLatesetDataEntryVersionResponse> => {
	return makeRequest({
		method: 'getLatestDataEntryVersion',
		service: 'data-entry',
		data: {
			datasetentryid: entryId,
			projectId,

			...(setName ? { set: { setName } } : {})
		}
	});
};
