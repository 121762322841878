import { makeRequest } from './makeRequest';
import { useQuery } from '@tanstack/react-query';

export type RevisionWithChanges = {
	active: boolean;
	changes: {
		variableName: string;
		from: any;
		to: any;
	}[];
	creationDate: string;
	revisionId: string;
	userName: string;
};

export type GetDataEntryHistoryResponse = {
	revisionsWithChanges: RevisionWithChanges[];
};

export const useGetDataEntryHistoryQuery = ({
	entryId,
	projectId,
	setName
}: {
	entryId: string;
	projectId: string;
	setName?: string;
}) => {
	return useQuery({
		queryKey: ['entryHistory', entryId],
		queryFn: () =>
			makeRequest<GetDataEntryHistoryResponse>({
				method: 'getDataEntryHistory',
				service: 'data-entry',
				data: {
					datasetentryid: entryId,
					projectId,
					...(setName ? { set: { setName } } : {})
				}
			})
	});
};
