import { FormControl } from '../EntryForm';
import { useController } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import { InputError } from '../component/InputError';
import { Label } from '../component/Label';
import { OptionalDescriptionTooltip } from '../component/OptionalDescriptionTooltip';
import { StringFormItem } from '../data/useProjectData/useProjectData';
import clsx from 'clsx';

interface Props {
	control: FormControl;
	formItem: StringFormItem;
}

export const StringVariableInput = ({ control, formItem }: Props) => {
	const { field, fieldState } = useController({
		name: formItem.variable.variableName,
		control
	});

	const textareaRef = useRef<HTMLTextAreaElement>(null);

	const adjustHeight = () => {
		const textarea = textareaRef.current;
		if (textarea) {
			textarea.style.height = '0px';
			const scrollHeight = textarea.scrollHeight;

			// We then set the height directly, outside of the render loop
			// Trying to set this with state or a ref will product an incorrect value.
			textarea.style.height = scrollHeight + 'px';
		}
	};

	// Adjust height on mount and when content changes
	useEffect(() => {
		adjustHeight();
	}, [field.value]);

	return (
		<div className={clsx('flex flex-col', formItem.hideFromUi && 'hidden')}>
			<div className="flex items-center gap-1">
				<Label
					htmlFor={formItem.variable.variableName}
					label={formItem.variable.variableLabel}
					required={formItem.variable.obligatory}
				/>
				<OptionalDescriptionTooltip description={formItem.variable.description} />
			</div>

			<textarea
				{...field}
				ref={textareaRef}
				id={formItem.variable.variableName}
				className="rounded-md border border-gray-400 p-3 pt-[12px] text-base resize-none min-h-[40px]"
				disabled={formItem.variable.entryType === 'calculated'}
				onChange={e => {
					field.onChange(e);
					adjustHeight();
				}}
			/>

			<InputError error={fieldState.error?.message?.toString()} />
		</div>
	);
};
