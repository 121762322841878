import { useQuery } from '@tanstack/react-query';
import { makeRequest } from '../makeRequest';

export type GetFormsFrontendData = {
	forms: FrontendForm[];
	formsBySetName?: Record<string, FrontendForm[]>;
};

export const useGetFormsQuery = ({
	projectId,
	seriesName
}: {
	projectId: string;
	seriesName?: string;
}) => {
	return useQuery({
		queryKey: ['forms', projectId, seriesName],
		queryFn: async (): Promise<GetFormsQueryResult> => {
			const result = await makeRequest<GetFormsFrontendData>({
				method: 'getForms',
				service: 'stats',
				data: {
					projectId: parseInt(projectId)
				}
			});

			if (!seriesName) {
				const formsWithParsedElements = result.forms.map(toFrontendForm);

				return {
					allForms: formsWithParsedElements,
					activeForms: formsWithParsedElements.filter(form => form.is_active)
				};
			}

			const seriesForms = result.formsBySetName?.[seriesName];
			if (!seriesForms) {
				console.error(new Error('Forms for series not found'), {
					seriesName
				});

				return {
					allForms: [],
					activeForms: []
				};
			}

			const formsWithParsedElements = seriesForms.map(toFrontendForm);

			return {
				allForms: formsWithParsedElements,
				activeForms: formsWithParsedElements.filter(form => form.is_active)
			};
		}
	});
};

// Really the type for form here should be a separate type, but we're just doing this for now
const toFrontendForm = (form: FrontendForm): FrontendForm => {
	return {
		...form,
		formDefinition: {
			...form.formDefinition,
			formElements: form.formDefinition.formElements.map(row => {
				return row.map(element => {
					if ('setLabel' in element) {
						return {
							...element,
							elementType: 'series'
						};
					}

					return element;
				});
			})
		}
	};
};

export type GetFormsQueryResult = {
	allForms: FrontendForm[];
	activeForms: FrontendForm[];
};

export type FrontendForm = {
	formDefinition: FormDefinition;
	is_active: boolean;
	formId: number;
};

export type FormDefinition = {
	formElements: FormElement[][];
	formName: string;
	formTitle?: string;
	formTitleEnabled: boolean;
};

export type SubtitleFormElement = {
	elementType: 'subtitle';
	text: string;
};

export type VariableFormElementBase = {
	variableRef: string;
	text?: string;
};

export type InputFormElement = VariableFormElementBase & {
	elementType: 'input';
};

export type CategoryOrientation = 'horizontal' | 'vertical';
export type CategoryDisplayType = 'value' | 'label';
export type RadioButtonsFormElement = VariableFormElementBase & {
	elementType: 'radiobuttons';
	variableRef: string;
	orientation: CategoryOrientation;
	displayType: CategoryDisplayType;
};
export type CheckboxesFormElement = VariableFormElementBase & {
	elementType: 'checkboxes';
	variableRef: string;
	orientation: CategoryOrientation;
	displayType: CategoryDisplayType;
};
export type DropdownFormElement = VariableFormElementBase & {
	elementType: 'dropdown';
	displayType: CategoryDisplayType;
};
export type FileFormElement = VariableFormElementBase & {
	elementType: 'file';
};
export type FrontendSeriesFormElement = {
	setLabel: string;
	setName: string;

	// This property is not sent from backend, so we stitch it together manually here for now. Ideally move this to backend
	elementType: 'series';
};
export const isFrontendSeriesFormElement = (
	formElement: FormElement
): formElement is FrontendSeriesFormElement => {
	return isNonGroupFormElement(formElement) && formElement.elementType === 'series';
};

export type VariableFormElement =
	| InputFormElement
	| RadioButtonsFormElement
	| CheckboxesFormElement
	| DropdownFormElement
	| FileFormElement;
export const isVariableFormElement = (
	formElement: FormElement
): formElement is VariableFormElement => {
	if (!('elementType' in formElement)) {
		return false;
	}

	return ['input', 'radioButtons'].includes(formElement.elementType);
};

type TextFormElement = {
	elementType: 'text';
	text: string;
};

type SeparatorFormElement = {
	elementType: 'separator';
};

export type NonGroupFormElement =
	| VariableFormElement
	| SubtitleFormElement
	| TextFormElement
	| FrontendSeriesFormElement
	| SeparatorFormElement;
export const isNonGroupFormElement = (
	formElement: FormElement
): formElement is NonGroupFormElement => {
	return !isGroupFormElement(formElement);
};

export type GroupFormElement = {
	groupLabel: string;
	groupName: string;
	formElements: NonGroupFormElement[][];
};
export const isGroupFormElement = (element: FormElement): element is GroupFormElement => {
	return (element as GroupFormElement).groupName !== undefined;
};
export const isNotGroupFormElement = (element: FormElement): element is NonGroupFormElement => {
	return !isGroupFormElement(element);
};

export type FormElement = NonGroupFormElement | GroupFormElement;
