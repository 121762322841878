import { SVGProps } from 'react';
const More = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M12 10a2 2 0 1 1 .001 3.999A2 2 0 0 1 12 10zm7 0a2 2 0 1 1 .001 3.999A2 2 0 0 1 19 10zM5 10a2 2 0 1 1 .001 3.999A2 2 0 0 1 5 10z"
		/>
	</svg>
);
export default More;
