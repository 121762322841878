import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { Label } from './Label';
import { OptionalDescriptionTooltip } from './OptionalDescriptionTooltip';

type InputProps = {
	error?: string;
	description?: string;
	label: string;
	labelHint?: string;
	id: string;
	obligatory?: boolean;
	className?: string;
	disabled?: boolean;
	hideFromUi?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

type Variant = 'normal' | 'error' | 'disabled';

const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			error,
			className,
			obligatory,
			disabled,
			label,
			labelHint,
			description,
			hideFromUi,
			id,
			...forwardProps
		},
		ref
	) => {
		let variant: Variant = 'normal';
		if (error) {
			variant = 'error';
		} else if (disabled) {
			variant = 'disabled';
		}

		return (
			<div
				className={clsx(
					!hideFromUi && 'flex flex-col gap-1',
					hideFromUi && 'hidden',
					className
				)}
			>
				<div className="flex items-center gap-1">
					<Label disabled={disabled} htmlFor={id} label={label} required={obligatory} />
					{labelHint && <p className="text-sm text-gray-700">{labelHint}</p>}
					<OptionalDescriptionTooltip description={description} />
				</div>

				<input
					{...forwardProps}
					id={id}
					ref={ref}
					className={clsx(
						// idk why h-10 doesnt give 40px, please enlighten me if you do
						'rounded-md border w-full p-2 px-3 text-base h-[40px]',
						variant === 'normal' && 'border-gray-400',
						variant === 'error' && 'border-error-500',
						variant === 'disabled' && 'border-gray-300 bg-gray-300 text-gray-500'
					)}
					disabled={disabled}
				/>

				{error && <p className="text-error-500 font-bold">{error}</p>}
			</div>
		);
	}
);

Input.displayName = 'Input';

export { Input };
