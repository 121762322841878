/**
 * This file contains utility functions for converting variables to Zod schemas.
 * The zod schema needs to be compatible with how we handle values in the react-hook-form.
 * We wan't to handle empty string as null, as sending null to the backend will clear the value.
 * We also want to reject null values if the variable is obligatory.
 * See this zod issue on strategies for allowing null as input but rejecting it in the schema:
 * https://github.com/colinhacks/zod/issues/1206
 *
 * We use the following implementation:
 * 1. Add preprocessors to normalize empty strings to null
 * 2. Add preprocessors checks to reject null values if the variable is obligatory
 * 3. We use coerce to handle type coercion, for example from string to number
 * 4. We use strict mode to catch any unknown keys in the input
 */

import { isObject } from 'lodash';
import {
	ValidationCase,
	Variable,
	IntegerVariable,
	FloatVariable,
	DateTimeVariable,
	DateVariable,
	UserDefinedUniqueVariable,
	CategoryMultipleVariable,
	CategoryVariable,
	TimeDurationVariable,
	StringVariable,
	FileVariable
} from '../types';
import { z } from 'zod';
import { logWithDetails } from 'sentry';
import { DateTime } from 'luxon';

// CONSTS
const MIN_DATE = new Date('1000-01-01');
const MAX_DATE = new Date('2100-01-01');
const MAX_SAFE_INTEGER_VARIABLE_VALUE = 18446744073709551616;

// TYPES
type ValidationRule = {
	minValue: string | null;
	maxValue: string | null;
};

// FUNCTIONS
function getValidationRule(validationCases: ValidationCase[]): ValidationRule | null {
	if (!validationCases || validationCases.length === 0) {
		return null;
	}

	if (validationCases.length > 2) {
		throw new Error('Validation cases must have 1 or 2 elements');
	}

	const result: ValidationRule = {
		minValue: null,
		maxValue: null
	};

	for (const validationCase of validationCases) {
		const { validationExpression } = validationCase;
		const { operator: rootOperator, operands: rootOperands } = validationExpression;
		const [assumedGarbageOperand, boundingOperand] = rootOperands;

		if (rootOperator !== 'op1_or_op2') {
			throw new Error('Validation expression must have operator "op1_or_op2"');
		}

		if (
			!isObject(assumedGarbageOperand) ||
			assumedGarbageOperand.operator !== 'op1_equals_op2'
		) {
			throw new Error('First operand must be "op1_equals_op2"');
		}

		if (!isObject(boundingOperand)) {
			throw new Error('Bounding operand must be an object');
		}

		const {
			operator: boundingOperator,
			operands: [refOperand, valueOperand]
		} = boundingOperand;

		if (
			!['op1_greater_than_or_equals_op2', 'op1_less_than_or_equals_op2'].includes(
				boundingOperator
			)
		) {
			throw new Error(
				'Bounding operator must be "op1_greater_than_or_equals_op2" or "op1_less_than_or_equals_op2"'
			);
		}

		if (!isObject(refOperand) || refOperand.operator !== 'op1_variable_ref') {
			throw new Error('Reference operand must be a variable reference');
		}

		if (!isObject(valueOperand)) {
			throw new Error('Value operand must be an object');
		}

		const value = valueOperand.operands[0];

		if (boundingOperator === 'op1_greater_than_or_equals_op2') {
			result.minValue = value.toString();
		}
		if (boundingOperator === 'op1_less_than_or_equals_op2') {
			result.maxValue = value.toString();
		}
	}

	return result;
}

function getValidationRuleSafe(validationCases?: ValidationCase[]): ValidationRule | null {
	try {
		return validationCases ? getValidationRule(validationCases) : null;
	} catch (error) {
		console.log('error', error);
		logWithDetails(error, { validationCases });
		return null;
	}
}

function getMinMaxValue(validationCases?: ValidationCase[]): {
	minValue: number;
	maxValue: number;
} {
	const validationRule = getValidationRuleSafe(validationCases);

	const minValue = Number(validationRule?.minValue ?? '-Infinity');
	const maxValue = Math.min(
		Number(validationRule?.maxValue ?? 'Infinity'),
		Number(MAX_SAFE_INTEGER_VARIABLE_VALUE)
	);

	return { minValue, maxValue };
}

function getMinMaxDate(validationCases?: ValidationCase[]): {
	minDate: Date;
	maxDate: Date;
} {
	// Set an acceptable range on min and max date as we know values outside this kind of range is likely a mistake
	const validationRule = getValidationRuleSafe(validationCases);

	const minDate = validationRule?.minValue ? new Date(validationRule.minValue) : MIN_DATE;
	const maxDate = validationRule?.maxValue ? new Date(validationRule.maxValue) : MAX_DATE;

	return { minDate, maxDate };
}

function compose(
	...preprocessors: ((arg: unknown, ctx: z.RefinementCtx) => unknown)[]
): (arg: unknown, ctx: z.RefinementCtx) => unknown {
	return (arg: unknown, ctx: z.RefinementCtx) => {
		for (const preprocessor of preprocessors) {
			arg = preprocessor(arg, ctx);
		}
		return arg;
	};
}

function check(
	check: (arg: unknown) => boolean,
	message: string
): (arg: unknown, ctx: z.RefinementCtx) => unknown {
	return (arg: unknown, ctx: z.RefinementCtx) => {
		if (!check(arg)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: message,
				fatal: true
			});
		}
		return arg;
	};
}

function emptyAndUndefinedAsNull(value: unknown): unknown {
	if (value === undefined) {
		return null;
	}
	if (typeof value === 'string') {
		return value.trim() === '' ? null : value;
	}
	if (Array.isArray(value) && value.length === 0) {
		return null;
	}
	return value;
}

function toIntegerZodType(variable: IntegerVariable) {
	const { minValue, maxValue } = getMinMaxValue(variable.validationCases);

	return z.preprocess(
		compose(
			emptyAndUndefinedAsNull,
			check(value => (variable.obligatory ? value !== null : true), 'This field is required')
		),
		z.coerce
			.number({
				invalid_type_error: 'The value must be a number without decimals'
			})
			.finite()
			.int({ message: 'The value must be a number without decimals' })
			.min(minValue, `The value must be greater than or equal to ${minValue}`)
			.max(maxValue, `The value must be less than or equal to ${maxValue}`)
			.nullable()
	);
}

export type FormInteger = z.infer<ReturnType<typeof toIntegerZodType>>;

function toFloatZodType(variable: FloatVariable) {
	const { minValue, maxValue } = getMinMaxValue(variable.validationCases);

	return z.preprocess(
		compose(
			emptyAndUndefinedAsNull,
			check(value => (variable.obligatory ? value !== null : true), 'This field is required')
		),
		z.coerce
			.number({
				invalid_type_error: 'The value must be a number'
			})
			.finite()
			.min(minValue, `The value must be greater than or equal to ${minValue}`)
			.max(maxValue, `The value must be less than or equal to ${maxValue}`)
			.nullable()
	);
}

export type FormFloat = z.infer<ReturnType<typeof toFloatZodType>>;

function toDateZodType(variable: DateVariable | DateTimeVariable) {
	const { minDate, maxDate } = getMinMaxDate(variable.validationCases);

	return z.preprocess(
		compose(
			emptyAndUndefinedAsNull,
			check(value => (variable.obligatory ? value !== null : true), 'This field is required')
		),
		z.coerce
			.date()
			.min(minDate, `The value must be after ${minDate.toLocaleDateString()}`)
			.max(maxDate, `The value must be before ${maxDate.toLocaleDateString()}`)
			.transform(date => DateTime.fromJSDate(date).setZone('UTC').toFormat('yyyy-MM-dd'))
			.nullable()
	);
}

export type FormDate = z.infer<ReturnType<typeof toDateZodType>>;

function toDateTimeZodType(variable: DateVariable | DateTimeVariable) {
	const { minDate, maxDate } = getMinMaxDate(variable.validationCases);

	return z.preprocess(
		compose(
			emptyAndUndefinedAsNull,
			check(value => (variable.obligatory ? value !== null : true), 'This field is required')
		),
		z.coerce
			.date()
			.min(minDate, `The value must be after ${minDate.toLocaleString()}`)
			.max(maxDate, `The value must be before ${maxDate.toLocaleString()}`)
			.transform(date => DateTime.fromJSDate(date).toFormat("yyyy-MM-dd'T'HH:mm:ssZZ"))
			.nullable()
	);
}

export type FormDateTime = z.infer<ReturnType<typeof toDateTimeZodType>>;
function toCategoryZodType(variable: CategoryVariable) {
	return z.preprocess(
		compose(
			emptyAndUndefinedAsNull,
			check(value => (variable.obligatory ? value !== null : true), 'This field is required')
		),
		z.string().nullable()
	);
}

export type FormCategory = z.infer<ReturnType<typeof toCategoryZodType>>;

function toCategoryMultipleZodType(variable: CategoryMultipleVariable) {
	return z.preprocess(
		compose(
			emptyAndUndefinedAsNull,
			check(value => {
				if (!variable.obligatory) {
					return true;
				}
				return value !== null && Array.isArray(value) && value.length > 0;
			}, 'This field is required')
		),
		z.array(z.string()).nullable()
	);
}

export type FormCategoryMultiple = z.infer<ReturnType<typeof toCategoryMultipleZodType>>;

function toStringZodType(variable: StringVariable) {
	return z.preprocess(
		compose(
			emptyAndUndefinedAsNull,
			check(value => (variable.obligatory ? value !== null : true), 'This field is required')
		),
		z.string().nullable()
	);
}

export type FormString = z.infer<ReturnType<typeof toStringZodType>>;

function toUserDefinedUniqueZodType(variable: UserDefinedUniqueVariable) {
	switch (variable.uniquenessType) {
		case 'Manual':
			return z.preprocess(
				compose(
					emptyAndUndefinedAsNull,
					check(
						value => (variable.obligatory ? value !== null : true),
						'This field is required'
					)
				),
				z.string().nullable()
			);
		case 'Sequence':
		case 'UUID':
			return z.any(); // we don't apply validation to these types as they are handled by the backend
	}
}

export type FormUserDefinedUnique = z.infer<ReturnType<typeof toUserDefinedUniqueZodType>>;

const TIME_UNITS = [
	'weeks',
	'days',
	'hours',
	'minutes',
	'seconds',
	'milliseconds',
	'microseconds'
] as const;

const CONVERSION_FACTORS: Record<string, number> = {
	microseconds: 1,
	milliseconds: 1000,
	seconds: 1000 * 1000,
	minutes: 60 * 1000 * 1000,
	hours: 60 * 60 * 1000 * 1000,
	days: 24 * 60 * 60 * 1000 * 1000,
	weeks: 7 * 24 * 60 * 60 * 1000 * 1000
} as const;

function createTimeDurationParser(variable: TimeDurationVariable): (value: string) => number {
	return (value: string): number => {
		const { maxTimeUnit, minTimeUnit } = variable.durationFormat;

		const relevantUnits = TIME_UNITS.slice(
			TIME_UNITS.indexOf(maxTimeUnit),
			TIME_UNITS.indexOf(minTimeUnit) + 1
		);

		if (relevantUnits.length === 0) {
			throw new Error('Invalid time unit specified');
		}

		const parts = value.split(':');
		const expectedUnits = relevantUnits.length;

		if (parts.length !== expectedUnits) {
			throw new Error(`Expected ${expectedUnits} units, got ${parts.length}`);
		}

		let result = 0;
		parts.forEach((part, index) => {
			const numericValue = parseInt(part);
			result += numericValue * CONVERSION_FACTORS[relevantUnits[index]];
		});

		return result;
	};
}

function toTimeDurationZodType(variable: TimeDurationVariable) {
	return z.preprocess(
		compose(
			emptyAndUndefinedAsNull,
			check(value => (variable.obligatory ? value !== null : true), 'This field is required')
		),
		z
			.string()
			.regex(/^\d+(:?\d+)*$/, 'Input must contain only numbers and colons')
			.transform(createTimeDurationParser(variable))
			.nullable()
	);
}

export type FormTimeDuration = z.infer<ReturnType<typeof toTimeDurationZodType>>;

const frontendFileSchema = z.object({
	type: z.literal('frontend-file'),
	fileName: z.string(),
	encodedFile: z.string()
});

const nonEmptyBackendFileSchema = z.object({
	type: z.literal('backend-file'),
	fileId: z.string()
});
function toFileZodType(variable: FileVariable) {
	return z.preprocess(
		compose(
			emptyAndUndefinedAsNull,
			check(value => (variable.obligatory ? value !== null : true), 'This field is required')
		),
		z.discriminatedUnion('type', [frontendFileSchema, nonEmptyBackendFileSchema]).nullable()
	);
}

export type FrontendFormFile = z.infer<typeof frontendFileSchema>;
export const isFrontendFormFile = (value: unknown): value is FrontendFormFile =>
	frontendFileSchema.safeParse(value).success;
export type BackendFormFile = z.infer<typeof nonEmptyBackendFileSchema>;
export const isBackendFormFile = (value: unknown): value is BackendFormFile =>
	nonEmptyBackendFileSchema.safeParse(value).success;

export type FormFile = z.infer<ReturnType<typeof toFileZodType>>;

export function variableToZodSchema(variable: Variable) {
	switch (variable.variableType) {
		case 'integer': {
			return toIntegerZodType(variable);
		}
		case 'float': {
			return toFloatZodType(variable);
		}
		case 'date': {
			return toDateZodType(variable);
		}
		case 'datetime': {
			return toDateTimeZodType(variable);
		}
		case 'category': {
			return toCategoryZodType(variable);
		}
		case 'categoryMultiple': {
			return toCategoryMultipleZodType(variable);
		}
		case 'string': {
			return toStringZodType(variable);
		}
		case 'userDefinedUnique': {
			return toUserDefinedUniqueZodType(variable);
		}
		case 'file': {
			return toFileZodType(variable);
		}
		case 'timeDuration': {
			return toTimeDurationZodType(variable);
		}
		default:
			console.error(new Error('Unknown variable type'), {
				// @ts-expect-error
				// This should never happen if it happens the backend has a new type which we don't know about
				variableType: variable.variableType
			});
			return z.string();
	}
}

export function toZodSchema(
	variables: Variable[]
): z.ZodObject<Record<string, ReturnType<typeof variableToZodSchema>>> {
	const shape: Record<string, ReturnType<typeof variableToZodSchema>> = {};

	for (const variable of variables) {
		shape[variable.variableName] = variableToZodSchema(variable);
	}

	return z.object(shape);
}
