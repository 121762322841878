// exports

export const Dictionary = {
	buttons: {
		add: 'buttons.add',
		addUser: 'buttons.addUser',
		back: 'buttons.back',
		goBack: 'buttons.goBack',
		cancel: 'buttons.cancel',
		change: 'buttons.change',
		continue: 'buttons.continue',
		continueToPayment: 'buttons.continueToPayment',
		continueToBilling: 'buttons.continueToBilling',
		completePayment: 'buttons.completePayment',
		create: 'buttons.create',
		close: 'buttons.close',
		clear: 'buttons.clear',
		delete: 'buttons.delete',
		done: 'buttons.done',
		discard: 'buttons.discard',
		export: 'buttons.export',
		edit: 'buttons.edit',
		generate: 'buttons.generate',
		import: 'buttons.import',
		next: 'buttons.next',
		finish: 'buttons.finish',
		ok: 'buttons.ok',
		remove: 'buttons.remove',
		refresh: 'buttons.refresh',
		reload: 'buttons.reload',
		rename: 'buttons.rename',
		reset: 'buttons.reset',
		restore: 'buttons.restore',
		signup: 'buttons.signup',
		save: 'buttons.save',
		saveAs: 'buttons.saveAs',
		confirm: 'buttons.confirm',
		abort: 'buttons.abort',
		skip: 'buttons.skip',
		submit: 'buttons.submit',
		send: 'buttons.send',
		update: 'buttons.update',
		understand: 'buttons.understand',
		logOut: 'buttons.logOut',
		overwrite: 'buttons.overwrite',
		dropdown: 'buttons.dropdown',
		download: 'buttons.download',
		replace: 'buttons.replace',
		preview: 'buttons.preview',
		print: 'buttons.print',
		radioHorizontalLayout: 'buttons.radioHorizontalLayout',
		radioVerticalLayout: 'buttons.radioVerticalLayout',
		sendInvitation: 'buttons.sendInvitation',
		goToProjects: 'buttons.goToProjects',
		requestTransfer: 'buttons.requestTransfer',
		share: 'buttons.share',
		unshare: 'buttons.unshare',
		subscribe: 'buttons.subscribe',
		upgrade: 'buttons.upgrade',
		accept: 'buttons.accept',
		decline: 'buttons.decline',
		apply: 'buttons.apply',
		downgrade: 'buttons.downgrade',
		yes: 'buttons.yes',
		merge: 'buttons.merge',
		transfer: 'buttons.transfer',
		convert: 'buttons.convert',
		proceed: 'buttons.proceed',
		signAndContinue: 'buttons.signAndContinue',
		resizeCard: 'buttons.resizeCard',
		layout: 'buttons.layout',
		fullScreen: 'buttons.fullScreen',
		retry: 'buttons.retry',
		pending: 'buttons.pending',
		assign: 'buttons.assign',
		set: 'buttons.set',
		learnMore: 'buttons.learnMore',
		reactivate: 'buttons.reactivate'
	},
	errors: {
		api: {
			importFail: 'errors.api.importFail',
			tokenExpired: 'errors.api.tokenExpired',
			noAccess: 'errors.api.noAccess',
			notifications: {
				topicName: 'errors.api.notifications.topicName',
				notificationHistory: 'errors.api.notifications.notificationHistory',
				getProjectNotificationSettings:
					'errors.api.notifications.getProjectNotificationSettings',
				updateProjectNotificationSettings:
					'errors.api.notifications.updateProjectNotificationSettings',
				getProjectsLabels: 'errors.api.notifications.getProjectsLabels'
			},
			accountUM: {
				couldNotGetAccount: 'errors.api.accountUM.couldNotGetAccount',
				couldNotUpdateAccount: 'errors.api.accountUM.couldNotUpdateAccount',
				couldNotUploadUserAvatar: 'errors.api.accountUM.couldNotUploadUserAvatar',
				couldNotGetUserAvatar: 'errors.api.accountUM.couldNotGetUserAvatar',
				couldNotGetOrganizations: 'errors.api.accountUM.couldNotGetOrganizations',
				couldNotGetPendingInvitation: 'errors.api.accountUM.couldNotGetPendingInvitation',
				couldNotGetSubscription: 'errors.api.accountUM.couldNotGetSubscription',
				couldNotAddSubscriptionUser: 'errors.api.accountUM.couldNotAddSubscriptionUser',
				couldNotVerifyIfUserExists: 'errors.api.accountUM.couldNotVerifyIfUserExists',
				couldNotCancelUserInvitation: 'errors.api.accountUM.couldNotCancelUserInvitation',
				getSubscriptionPaymentMethods: 'errors.api.accountUM.getSubscriptionPaymentMethods',
				addPaymentSubscriptionMethod: 'errors.api.accountUM.addPaymentSubscriptionMethod',
				removePaymentSubscriptionMethod:
					'errors.api.accountUM.removePaymentSubscriptionMethod',
				makePaymentSourcePrimary: 'errors.api.accountUM.makePaymentSourcePrimary',
				createSubscriptionPaymentIntent:
					'errors.api.accountUM.createSubscriptionPaymentIntent',
				updateSubscriptionCardPaymentSource:
					'errors.api.accountUM.updateSubscriptionCardPaymentSource',
				removeSubscriptionCardPaymentSource:
					'errors.api.accountUM.removeSubscriptionCardPaymentSource',
				getLicenceModels: 'admin.users.errors.getLicenceModels',
				couldNotGetInvoices: 'errors.api.accountUM.couldNotGetInvoices',
				couldNotDownloadInvoice: 'errors.api.accountUM.couldNotDownloadInvoice',
				couldNotGetLastPayment: 'errors.api.accountUM.couldNotGetLastPayment',
				couldNotGetSubscriptionBillingInfo:
					'errors.api.accountUM.couldNotGetSubscriptionBillingInfo',
				couldNotCreateCustomerSubscription:
					'errors.api.accountUM.couldNotCreateCustomerSubscription',
				couldNotUpdateCustomerSubscription:
					'errors.api.accountUM.couldNotUpdateCustomerSubscription',
				couldNotDowngradeCustomerSubscriptionToFree:
					'errors.api.accountUM.couldNotDowngradeCustomerSubscriptionToFree',
				couldNotCompareUserLicenceTypes:
					'errors.api.accountUM.couldNotCompareUserLicenceTypes',
				couldNotGetVATStatus: 'errors.api.accountUM.couldNotGetVATStatus'
			},
			enterprise: {
				projects: {
					couldNotGetAllEnterpriseOwnedProjects:
						'errors.api.enterprise.projects.couldNotGetAllEnterpriseOwnedProjects'
				}
			},
			accounts: {
				couldNoGetUsersByEmail: 'errors.api.accounts.couldNoGetUsersByEmail',
				couldNoGetUsersByIds: 'errors.api.accounts.couldNoGetUsersByIds'
			},
			addons: {
				getAllSubsriptionAddons: 'errors.api.addons.getAllSubsriptionAddons',
				getActiveSubsriptionAddons: 'errors.api.addons.getActiveSubsriptionAddons',
				activateSubscriptionAddon: 'errors.api.addons.activateSubscriptionAddon',
				deactivateSubscriptionAddon: 'errors.api.addons.deactivateSubscriptionAddon',
				JADBioLogin: 'errors.api.addons.JADBioLogin',
				JADBioAnalysis: 'errors.api.addons.JADBioAnalysis',
				JADBioInitProject: 'errors.api.addons.JADBioInitProject',
				JADBioGetProject: 'errors.api.addons.JADBioGetProject',
				JADBioInitDataset: 'errors.api.addons.JADBioInitDataset'
			},
			analyses: {
				statistics: {
					shapiro: 'errors.api.analyses.statistics.shapiro',
					mannWhitney: 'errors.api.analyses.statistics.mannWhitney',
					independent: 'errors.api.analyses.statistics.independent',
					fisher: 'errors.api.analyses.statistics.fisher',
					chiSquare: 'errors.api.analyses.statistics.chiSquare',
					pearson: 'errors.api.analyses.statistics.pearson',
					spearman: 'errors.api.analyses.statistics.spearman',
					linearRegression: 'errors.api.analyses.statistics.linearRegression',
					oneWayAnova: 'errors.api.analyses.statistics.oneWayAnova',
					twoWayAnova: 'errors.api.analyses.statistics.twoWayAnova',
					logRank: 'errors.api.analyses.statistics.logRank',
					pairedTTest: 'errors.api.analyses.statistics.pairedTTest',
					pairedWilcoxon: 'errors.api.analyses.statistics.pairedWilcoxon',
					kruskal: 'errors.api.analyses.statistics.kruskal',
					mcNemar: 'errors.api.analyses.statistics.mcNemar',
					twoWayManova: 'errors.api.analyses.statistics.twoWayManova',
					tukey: 'errors.api.analyses.statistics.tukey',
					oneWayManova: 'errors.api.analyses.statistics.oneWayManova',
					logisticRegression: 'errors.api.analyses.statistics.logisticRegression'
				}
			},
			dashboard: {
				statisticsFetchError: 'errors.api.dashboard.statisticsFetchError'
			},
			projectDashboard: {
				getDashboardError: 'errors.api.projectDashboard.getDashboardError',
				getDashboardsError: 'errors.api.projectDashboard.getDashboardsError',
				createDashboardError: 'errors.api.projectDashboard.createDashboardError',
				updateDashboardError: 'errors.api.projectDashboard.updateDashboardError',
				deleteDashboardError: 'errors.api.projectDashboard.deleteDashboardError',
				createDashboardCardsError: 'errors.api.projectDashboard.createDashboardCardsError',
				updateDashboardCardsError: 'errors.api.projectDashboard.updateDashboardCardsError',
				deleteDashboardCardsError: 'errors.api.projectDashboard.deleteDashboardCardsError'
			},
			entries: {
				couldNotLoadDataset: 'errors.api.entries.couldNotLoadDataset',
				couldNotRecalculateEntries: 'errors.api.entries.couldNotRecalculateEntries',
				couldNotDownloadDataset: 'errors.api.entries.couldNotDownloadDataset',
				emptyDataset: 'errors.api.entries.emptyDataset',
				couldNotCreateNewEntry: 'errors.api.entries.couldNotCreateNewEntry',
				couldNotEditEntry: 'errors.api.entries.couldNotEditEntry',
				couldNotDeleteEntry: 'errors.api.entries.couldNotDeleteEntry',
				couldNotGetDataEntryHistory: 'errors.api.entries.couldNotGetDataEntryHistory',
				couldNotSetLiveRevision: 'errors.api.entries.couldNotDeleteEntry',
				couldNotGetDataEntry: 'errors.api.entries.couldNotGetDataEntry',
				couldNotGetEntry: 'errors.api.entries.couldNotGetEntry',
				licenceError: 'errors.api.entries.licenceError',
				collaboratorLicenceError: 'errors.api.entries.collaboratorLicenceError',
				couldNotGetSeriesCsv: 'errors.api.entries.couldNotGetSeriesCsv'
			},
			entriesFiles: {
				couldNotStoreEntryFile: 'errors.api.entriesFiles.couldNotStoreEntryFile',
				couldNotStoreEntryFiles: 'errors.api.entriesFiles.couldNotStoreEntryFiles',
				couldNotRetrieveEntryFile: 'errors.api.entriesFiles.couldNotRetrieveEntryFile'
			},
			forms: {
				couldNotGetForms: 'errors.api.forms.couldNotGetForms',
				couldNotCreateNewForm: 'errors.api.forms.couldNotCreateNewForm',
				couldNotUpdateForm: 'errors.api.forms.couldNotUpdateForm',
				couldNotDeleteForm: 'errors.api.forms.couldNotDeleteForm'
			},
			templates: {
				couldNotGetTemplates: 'errors.api.templates.couldNotGetTemplates',
				couldNotGetUserTemplates: 'errors.api.templates.couldNotGetUserTemplates',
				couldNotGetProjectTemplates: 'errors.api.templates.couldNotGetProjectTemplates',
				couldNotGetPublicTemplates: 'errors.api.templates.couldNotGetPublicTemplates',
				couldNotGetTemplate: 'errors.api.templates.couldNotGetTemplate',
				couldNotGetListOfSharedWith: 'errors.api.templates.couldNotGetListOfSharedWith',
				couldNotPublishNewTemplate: 'errors.api.templates.couldNotPublishNewTemplate',
				couldNotUpdateTemplate: 'errors.api.templates.couldNotUpdateTemplate',
				couldNotDeleteTemplate: 'errors.api.templates.couldNotDeleteTemplate',
				couldNotShareTemplate: 'errors.api.templates.couldNotShareTemplate'
			},
			patients: {
				couldNotGetDistributionPatientList:
					'errors.api.patients.couldNotGetDistributionPatientList',
				couldNotCreateNewPatient: 'errors.api.patients.couldNotCreateNewPatient',
				couldNotUpdatePatient: 'errors.api.patients.couldNotUpdatePatient',
				couldNotDeletePatient: 'errors.api.patients.couldNotDeletePatient',
				couldNotUpdateDistribution: 'errors.api.patients.couldNotUpdateDistribution',
				couldNotGetDistribution: 'errors.api.patients.couldNotGetDistribution'
			},
			projects: {
				couldNotLoadProjects: 'errors.api.projects.couldNotLoadProjects',
				couldNotLoadProject: 'errors.api.projects.couldNotLoadProject',
				couldNotLoadEntryCounts: 'errors.api.projects.couldNotLoadEntryCounts',
				couldNotCreateProject: 'errors.api.projects.couldNotCreateProject',
				couldNotUpdateProject: 'errors.api.projects.couldNotUpdateProject',
				couldNotCopyProject: 'errors.api.projects.couldNotCopyProject',
				couldNotConvert: 'errors.api.projects.couldNotConvert',
				requestCanceled: 'errors.api.projects.requestCanceled',
				datasetUploadFailed: 'errors.api.projects.datasetUploadFailed',
				couldNotShareProject: 'errors.api.projects.couldNotShareProject',
				couldNotUnshareProject: 'errors.api.projects.couldNotUnshareProject',
				couldNoGetUsersSharedProject: 'errors.api.projects.couldNoGetUsersSharedProject',
				couldNotGetUsersByEmail: 'errors.api.projects.couldNotGetUsersByEmail',
				couldNotUpdatePermissionsForUser:
					'errors.api.projects.couldNotUpdatePermissionsForUser',
				couldNotLeaveProject: 'errors.api.projects.couldNotLeaveProject',
				licenceError: 'errors.api.projects.licenceError',
				couldNotUploadFile: 'errors.api.projects.couldNotUploadFile',
				couldNotCompleteImport: 'errors.api.projects.couldNotCompleteImport',
				fileFormatUnsupported: 'errors.api.projects.fileFormatUnsupported',
				licenceErrorProjectNewOwner: 'errors.api.projects.licenceErrorProjectNewOwner'
			},
			proms: {
				couldNotLoadProms: 'errors.api.proms.couldNotLoadProms',
				couldNotLoadProm: 'errors.api.proms.couldNotLoadProm'
			},
			snapshots: {
				couldNotLoadSnapshots: 'errors.api.snapshots.couldNotLoadSnapshots',
				couldNotLoadSnapshot: 'errors.api.snapshots.couldNotLoadSnapshot',
				couldNotCreateSnapshot: 'errors.api.snapshots.couldNotCreateSnapshot',
				couldNotUpdateSnapshot: 'errors.api.snapshots.couldNotUpdateSnapshot',
				couldNotDeleteSnapshot: 'errors.api.snapshots.couldNotDeleteSnapshot'
			},
			variables: {
				couldNotLoadVariables: 'errors.api.variables.couldNotLoadVariables',
				couldNotLoadGroups: 'errors.api.variables.couldNotLoadGroups',
				couldNotCheckVariableTypeChange:
					'errors.api.variables.couldNotCheckVariableTypeChange',
				couldNotUpdateVariables: 'errors.api.variables.couldNotUpdateVariables',
				couldNotCreateVariable: 'errors.api.variables.couldNotCreateVariable'
			},
			login: {
				setupMFA: 'errors.api.login.setupMFA',
				verifyMFA: 'errors.api.login.verifyMFA'
			}
		},
		ui: {
			entries: {
				empty: 'errors.ui.entries.empty',
				uniqueValue: 'errors.ui.entries.uniqueValue'
			}
		},
		general: {
			notAvailable: 'errors.general.notAvailable'
		}
	},
	validation: {
		username: {
			required: 'validation.username.required',
			noSpaces: 'validation.username.noSpaces'
		},
		password: {
			required: 'validation.password.required',
			confirm: 'validation.password.confirm',
			match: 'validation.password.match',
			length: 'validation.password.length',
			lowercase: 'validation.password.lowercase',
			uppercase: 'validation.password.uppercase',
			digit: 'validation.password.digit'
		},
		code: {
			required: 'validation.code.required',
			valid: 'validation.code.valid'
		},
		groups: {
			resolveErrors: 'validation.groups.resolveErrors'
		},
		formVariables: {
			mustBeBetween: 'validation.formVariables.mustBeBetween',
			and: 'validation.formVariables.and',
			fieldRequired: 'validation.formVariables.fieldRequired',
			numeric: 'validation.formVariables.numeric',
			noDecimals: 'validation.formVariables.noDecimals',
			greaterThan: 'validation.formVariables.greaterThan',
			lowerThan: 'validation.formVariables.lowerThan',
			invalidDate: 'validation.formVariables.invalidDate',
			invalidDateTime: 'validation.formVariables.invalidDateTime',
			dateOutOfBounds: 'validation.formVariables.dateOutOfBounds',
			variableAsDropdown: 'validation.formVariables.variableAsDropdown',
			specialCharacters: 'validation.formVariables.specialCharacters'
		}
	},
	login: {
		title: 'login.title',
		tip: 'login.tip',
		browserInfo: 'login.browserInfo',
		logoImageAlt: 'login.logoImageAlt',
		largeLogoImageAlt: 'login.largeLogoImageAlt',
		secondaryLogoImageAlt: 'login.secondaryLogoImageAlt',
		chromeLogoAlt: 'login.chromeLogoAlt'
	},
	loginForm: {
		username: 'loginForm.username',
		password: 'loginForm.password',
		usernamePlaceholder: 'loginForm.usernamePlaceholder',
		passwordPlaceholder: 'loginForm.passwordPlaceholder',
		login: 'loginForm.login',
		pin: 'loginForm.pin',
		pinPlaceholder: 'loginForm.pinPlaceholder',
		firstname: 'loginForm.firstname',
		lastname: 'loginForm.lastname',
		organisation: 'loginForm.organisation',
		country: 'loginForm.country',
		city: 'loginForm.city',
		position: 'loginForm.position',
		department: 'loginForm.department',
		finish: 'loginForm.finish',
		forgotPassword: 'loginForm.forgotPassword',
		iAcceptTerms: 'loginForm.iAcceptTerms',
		termsOfService: 'loginForm.termsOfService'
	},
	loginSetWorkInfo: {
		title: 'loginSetWorkInfo.title',
		personalSubtitle: 'loginSetWorkInfo.personalSubtitle',
		workSubtitle: 'loginSetWorkInfo.workSubtitle',
		error: 'loginSetWorkInfo.error',
		hint: 'loginSetWorkInfo.hint'
	},
	creditCardForm: {
		cardNumber: 'creditCardForm.cardNumber',
		expirationDate: 'creditCardForm.expirationDate',
		CVV: 'creditCardForm.CVV',
		addressLineOne: 'creditCardForm.addressLineOne',
		addressLineTwo: 'creditCardForm.addressLineTwo',
		state: 'creditCardForm.state',
		zip: 'creditCardForm.zip',
		recurrentBillingInfoText: 'creditCardForm.recurrentBillingInfoText',
		redirectionInfoMessage: 'creditCardForm.redirectionInfoMessage'
	},
	forgotPasswordForm: {
		title: 'forgotPasswordForm.title',
		username: 'forgotPasswordForm.username',
		reset: 'forgotPasswordForm.reset'
	},
	newPasswordForm: {
		title: 'newPasswordForm.title',
		phoneNumber: 'newPasswordForm.phoneNumber',
		phoneNumberLabel: 'newPasswordForm.phoneNumberLabel',
		phoneNumberPlaceholder: 'newPasswordForm.phoneNumberPlaceholder',
		code: 'newPasswordForm.code',
		codePlaceholder: 'newPasswordForm.codePlaceholder',
		newPassword: 'newPasswordForm.newPassword',
		password: 'newPasswordForm.password',
		confirmPassword: 'newPasswordForm.confirmPassword',
		update: 'newPasswordForm.update'
	},
	codeForm: {
		title: 'codeForm.title',
		failedToLoadAccount: 'codeForm.failedToLoadAccount',
		validation: 'codeForm.validation',
		label: 'codeForm.label',
		placeholder: 'codeForm.placeholder'
	},
	logout: {
		backgroundImageAlt: 'logout.backgroundImageAlt',
		logoAlt: 'logout.logoAlt',
		logoutTitle: 'logout.logoutTitle',
		logoutText: 'logout.logoutText',
		loginButton: 'logout.loginButton',
		visitOurWebsite: 'logout.visitOurWebsite',
		errorMessage: 'logout.errorMessage'
	},
	JADBioLogin: {
		login: 'JADBioLogin.login',
		title: 'JADBioLogin.title',
		email: 'JADBioLogin.email',
		password: 'JADBioLogin.password',
		emailPlaceholder: 'JADBioLogin.emailPlaceholder',
		passwordPlaceholder: 'JADBioLogin.passwordPlaceholder',
		logInText: 'JADBioLogin.logInText',
		logInButton: 'JADBioLogin.logInButton'
	},
	selectMultiple: {
		search: 'selectMultiple.search',
		checkbox: 'selectMultiple.checkbox',
		moreResults: 'selectMultiple.moreResults'
	},
	project: {
		readMore: 'project.readMore',
		entries: 'project.entries'
	},
	projectOptions: {
		edit: 'projectOptions.edit',
		copyProject: 'projectOptions.copyProject',
		view: 'projectOptions.view',
		collaborators: 'projectOptions.collaborators',
		analysis: 'projectOptions.analysis',
		dataset: 'projectOptions.dataset',
		editVariable: 'projectOptions.editVariable',
		viewVariable: 'projectOptions.viewVariable',
		importDataset: 'projectOptions.importDataset',
		importVariableSet: 'projectOptions.importVariableSet',
		viewDashboard: 'projectOptions.viewDashboard',
		viewPatients: 'projectOptions.viewPatients',
		leaveProject: 'projectOptions.leaveProject',
		deleteProject: 'projectOptions.deleteProject',
		convert: 'projectOptions.convert'
	},
	promOptions: {
		edit: 'promOptions.edit',
		copyProject: 'promOptions.copyProject',
		view: 'promOptions.view',
		analysis: 'promOptions.analysis',
		dataset: 'promOptions.dataset',
		collaborators: 'promOptions.collaborators',
		leaveProject: 'promOptions.leaveProject',
		deleteProject: 'promOptions.deleteProject',
		convert: 'promOptions.convert'
	},
	displayers: {
		ongoing: 'displayers.ongoing',
		onHold: 'displayers.onHold',
		ended: 'displayers.ended',
		between: 'displayers.between',
		equals: 'displayers.equals',
		atADateAndTime: 'displayers.atADateAndTime',
		atADate: 'displayers.atADate',
		greaterThan: 'displayers.greaterThan',
		after: 'displayers.after',
		lessThan: 'displayers.lessThan',
		before: 'displayers.before'
	},
	notFoundPage: {
		title: 'notFoundPage.title',
		message: 'notFoundPage.message'
	},
	errorPage: {
		title: 'errorPage.title',
		message: 'errorPage.message'
	},
	noAccessMessage: {
		message: 'noAccessMessage.message'
	},
	inputPlaceholder: {
		typeHere: 'inputPlaceholder.typeHere',
		pleaseSelect: 'inputPlaceholder.pleaseSelect'
	},
	projects: {
		createAndImport: {
			errors: {
				couldNotGenerateVariableLabels:
					'projects.createAndImport.errors.couldNotGenerateVariableLabels'
			},
			title: {
				create: 'projects.createAndImport.title.create',
				import: 'projects.createAndImport.title.import',
				importVariableSet: 'projects.createAndImport.title.importVariableSet',
				importDataVariableSet: 'projects.createAndImport.title.importDataVariableSet',
				dataToEntries: 'projects.createAndImport.title.dataToEntries',
				entriesToDataset: 'projects.createAndImport.title.entriesToDataset',
				replaceAll: 'projects.createAndImport.title.replaceAll',
				dataToVariableSet: 'projects.createAndImport.title.dataToVariableSet',
				entriesToVariableSet: 'projects.createAndImport.title.entriesToVariableSet',
				replaceAllVariableSet: 'projects.createAndImport.title.replaceAllVariableSet',
				importNow: 'projects.createAndImport.title.importNow',
				finalStep: 'projects.createAndImport.title.finalStep'
			},
			showUnmatched: 'projects.createAndImport.showUnmatched',
			unmatched: 'projects.createAndImport.unmatched',
			noUnmatchedFields: 'projects.createAndImport.noUnmatchedFields',
			subtitle: {
				create: 'projects.createAndImport.subtitle.create',
				import: 'projects.createAndImport.subtitle.import',
				dataToEntries: 'projects.createAndImport.subtitle.dataToEntries',
				entriesToDataset: 'projects.createAndImport.subtitle.entriesToDataset',
				replaceAll: 'projects.createAndImport.subtitle.replaceAll',
				dataToVariableSet: 'projects.createAndImport.subtitle.dataToVariableSet',
				entriesToVariableSet: 'projects.createAndImport.subtitle.entriesToVariableSet',
				replaceAllVariableSet: 'projects.createAndImport.subtitle.replaceAllVariableSet',
				importNow: 'projects.createAndImport.subtitle.importNow',
				finalStep: 'projects.createAndImport.subtitle.finalStep',
				loadingFile: 'projects.createAndImport.subtitle.loadingFile'
			},
			importOptions: {
				manuallyDefine: 'projects.createAndImport.importOptions.manuallyDefine',
				importNow: 'projects.createAndImport.importOptions.importNow',
				defineLater: 'projects.createAndImport.importOptions.defineLater',
				moreDataToEntries: 'projects.createAndImport.importOptions.moreDataToEntries',
				moreEntriesToDataset: 'projects.createAndImport.importOptions.moreEntriesToDataset',
				replaceAll: 'projects.createAndImport.importOptions.replaceAll',
				moreDataToVariableSet:
					'projects.createAndImport.importOptions.moreDataToVariableSet',
				moreEntriesToVariableSet:
					'projects.createAndImport.importOptions.moreEntriesToVariableSet',
				replaceAllVariableSet:
					'projects.createAndImport.importOptions.replaceAllVariableSet'
			},
			template: {
				modal: {
					cardTitle: 'projects.createAndImport.template.modal.cardTitle',
					description: 'projects.createAndImport.template.modal.description',
					title: 'projects.createAndImport.template.modal.title',
					selectPlaceholder: 'projects.createAndImport.template.modal.selectPlaceholder',
					selectName: 'projects.createAndImport.template.modal.selectName'
				}
			},
			generics: {
				previewVariables: {
					missingVariableMapping:
						'projects.createAndImport.generics.previewVariables.missingVariableMapping',
					createOrChooseVariable:
						'projects.createAndImport.generics.previewVariables.createOrChooseVariable',
					missingDateFormat:
						'projects.createAndImport.generics.previewVariables.missingDateFormat',
					missingDateAndTimeFormat:
						'projects.createAndImport.generics.previewVariables.missingDateAndTimeFormat',
					missingTimeZone:
						'projects.createAndImport.generics.previewVariables.missingTimeZone',
					missingCustomDateFormat:
						'projects.createAndImport.generics.previewVariables.missingCustomDateFormat',
					invalidCustomDateFormat:
						'projects.createAndImport.generics.previewVariables.invalidCustomDateFormat',
					onlyVariablesWithErrors:
						'projects.createAndImport.generics.previewVariables.onlyVariablesWithErrors',
					foundUnresolved:
						'projects.createAndImport.generics.previewVariables.foundUnresolved',
					forbiddenSemiColon:
						'projects.createAndImport.generics.previewVariables.forbiddenSemiColon',
					labelRequired:
						'projects.createAndImport.generics.previewVariables.labelRequired',
					noDuplicates: 'projects.createAndImport.generics.previewVariables.noDuplicates',
					noDuplicateMapping:
						'projects.createAndImport.generics.previewVariables.noDuplicateMapping',
					floatToIntWarning:
						'projects.createAndImport.generics.previewVariables.floatToIntWarning',
					timeZonePlaceholder:
						'projects.createAndImport.generics.previewVariables.timeZonePlaceholder',
					title: 'projects.createAndImport.generics.previewVariables.title',
					dataLabel: 'projects.createAndImport.generics.previewVariables.dataLabel',
					dataType: 'projects.createAndImport.generics.previewVariables.dataType',
					variableNameHere:
						'projects.createAndImport.generics.previewVariables.variableNameHere',
					newVariableLabel:
						'projects.createAndImport.generics.previewVariables.newVariableLabel',
					precisionLossWarning:
						'projects.createAndImport.generics.previewVariables.precisionLossWarning',
					missingDurationSource:
						'projects.createAndImport.generics.previewVariables.missingDurationSource',
					missingDurationFormat:
						'projects.createAndImport.generics.previewVariables.missingDurationFormat',
					sourceUnitPlaceholder:
						'projects.createAndImport.generics.previewVariables.sourceUnitPlaceholder',
					durationFormatPlaceholder:
						'projects.createAndImport.generics.previewVariables.durationFormatPlaceholder',
					importVariableSet: {
						mainFileColumn:
							'projects.createAndImport.generics.previewVariables.importVariableSet.mainFileColumn',
						mainVariableToMatch:
							'projects.createAndImport.generics.previewVariables.importVariableSet.mainVariableToMatch',
						newVariableLabel:
							'projects.createAndImport.generics.previewVariables.importVariableSet.newVariableLabel'
					}
				},
				dataToEntries: {
					fileColumn: 'projects.createAndImport.generics.dataToEntries.fileColumn',
					variableToMatch:
						'projects.createAndImport.generics.dataToEntries.variableToMatch',
					importVariableSet: {
						fileColumn:
							'projects.createAndImport.generics.dataToEntries.importVariableSet.fileColumn',
						variableToMatch:
							'projects.createAndImport.generics.dataToEntries.importVariableSet.variableToMatch'
					}
				},
				entriesToDataset: {
					columnTitle: 'projects.createAndImport.generics.entriesToDataset.columnTitle',
					dropdownsTitle:
						'projects.createAndImport.generics.entriesToDataset.dropdownsTitle',
					createLabel: 'projects.createAndImport.generics.entriesToDataset.createLabel',
					dropdownTooltip:
						'projects.createAndImport.generics.entriesToDataset.dropdownTooltip'
				},
				previousMappingModal: {
					title: 'projects.createAndImport.generics.previousMappingModal.title',
					description:
						'projects.createAndImport.generics.previousMappingModal.description'
				}
			},
			replaceAllWarning: 'projects.createAndImport.replaceAllWarning',
			replaceAllWarningVariableSet: 'projects.createAndImport.replaceAllWarningVariableSet',
			dropzoneLabel: 'projects.createAndImport.dropzoneLabel'
		},
		projects: 'projects.projects',
		createFirstProject: 'projects.createFirstProject',
		createNew: 'projects.createNew',
		createNewProm: 'projects.createNewProm',
		view: 'projects.view',
		allProjects: 'projects.allProjects',
		ongoing: 'projects.ongoing',
		onHold: 'projects.onHold',
		ended: 'projects.ended',
		deleted: 'projects.deleted',
		sort: 'projects.sort',
		sortByNumber: 'projects.sortByNumber',
		sortByName: 'projects.sortByName',
		sortByDate: 'projects.sortByDate',
		allProjectsHeader: 'projects.allProjectsHeader',
		myProjectsHeader: 'projects.myProjectsHeader',
		sharedWithMeHeader: 'projects.sharedWithMeHeader',
		tableView: {
			title: 'projects.tableView.title',
			collaborators: 'projects.tableView.collaborators',
			endDate: 'projects.tableView.endDate',
			status: 'projects.tableView.status',
			entries: 'projects.tableView.entries',
			number: 'projects.tableView.number'
		},
		convert: {
			convertToSurvey: 'projects.convert.convertToSurvey',
			convertToProject: 'projects.convert.convertToProject',
			distributionLabel: 'projects.convert.distributionLabel'
		},
		confirmDeleteProjectModal: {
			type: 'projects.confirmDeleteProjectModal.type',
			project: 'projects.confirmDeleteProjectModal.project',
			prom: 'projects.confirmDeleteProjectModal.prom',
			toContinue: 'projects.confirmDeleteProjectModal.toContinue'
		}
	},
	collaborators: {
		errors: {
			noCollaborators: 'collaborators.errors.noCollaborators',
			notEligibleLicence: 'collaborators.errors.notEligibleLicence',
			detailsMissing: 'collaborators.errors.detailsMissing',
			emailInputError: 'collaborators.errors.emailInputError'
		},
		notifications: {
			addUsers: {
				project: 'collaborators.notifications.addUsers.project',
				prom: 'collaborators.notifications.addUsers.prom'
			},
			removeUser: {
				project: 'collaborators.notifications.removeUser.project',
				prom: 'collaborators.notifications.removeUser.prom'
			},
			existingUsers: {
				project: 'collaborators.notifications.existingUsers.project',
				prom: 'collaborators.notifications.existingUsers.prom'
			}
		},
		list: {
			header: {
				inputPlaceholder: {
					project: 'collaborators.list.header.inputPlaceholder.project',
					prom: 'collaborators.list.header.inputPlaceholder.prom'
				},
				import: 'collaborators.list.header.import',
				addCollaborators: 'collaborators.list.header.addCollaborators'
			},
			title: 'collaborators.list.title',
			columns: {
				name: 'collaborators.list.columns.name',
				organization: 'collaborators.list.columns.organization',
				department: 'collaborators.list.columns.department'
			}
		},
		details: {
			title: 'collaborators.details.title',
			permissions: 'collaborators.details.permissions',
			remove: {
				project: 'collaborators.details.remove.project',
				prom: 'collaborators.details.remove.prom',
				modal: {
					message: 'collaborators.details.remove.modal.message',
					description: {
						user: 'collaborators.details.remove.modal.description.user',
						willLose: 'collaborators.details.remove.modal.description.willLose'
					}
				}
			}
		},
		add: {
			title: {
				addStep: 'collaborators.add.title.addStep',
				confirmStep: 'collaborators.add.title.confirmStep',
				permissionsStep: 'collaborators.add.title.permissionsStep'
			},
			input: {
				label: {
					project: 'collaborators.add.input.label.project',
					prom: 'collaborators.add.input.label.prom'
				},
				placeholder: 'collaborators.add.input.placeholder'
			},
			import: 'collaborators.add.import',
			notice: 'collaborators.add.notice',
			termsOfUse: 'collaborators.add.termsOfUse',
			addNumberOfUsers: 'collaborators.add.addNumberOfUsers'
		},
		collaboratorsPage: {
			noResults: 'collaborators.collaboratorsPage.noResults',
			collaborator: 'collaborators.collaboratorsPage.collaborator',
			collaborators: 'collaborators.collaboratorsPage.collaborators',
			group: 'collaborators.collaboratorsPage.group',
			groups: 'collaborators.collaboratorsPage.groups',
			and: 'collaborators.collaboratorsPage.and',
			showing: 'collaborators.collaboratorsPage.showing',
			plural: 'collaborators.collaboratorsPage.plural'
		},
		collaboratorsInvitePage: {
			error: 'collaborators.collaboratorsInvitePage.error',
			message: 'collaborators.collaboratorsInvitePage.message'
		},
		collaboratorPermissions: {
			general: 'collaborators.collaboratorPermissions.general',
			statuses: 'collaborators.collaboratorPermissions.statuses',
			userInterface: 'collaborators.collaboratorPermissions.userInterface',
			onlyScreen: 'collaborators.collaboratorPermissions.onlyScreen',
			and: 'collaborators.collaboratorPermissions.and'
		},
		generalPermissions: {
			infoMessage: 'collaborators.generalPermissions.infoMessage'
		},
		organisationCard: {
			collaborator: 'collaborators.organisationCard.collaborator',
			plural: 'collaborators.organisationCard.plural'
		},
		collaboratorsHeader: {
			new: 'collaborators.collaboratorsHeader.new',
			addCollaborators: 'collaborators.collaboratorsHeader.addCollaborators',
			newGroup: 'collaborators.collaboratorsHeader.newGroup',
			existingGroup: 'collaborators.collaboratorsHeader.existingGroup',
			clearFilters: 'collaborators.collaboratorsHeader.clearFilters',
			selected: 'collaborators.collaboratorsHeader.selected',
			moveTo: 'collaborators.collaboratorsHeader.moveTo',
			viewTable: 'collaborators.collaboratorsHeader.viewTable',
			viewCards: 'collaborators.collaboratorsHeader.viewCards'
		},
		addToOrganisationModal: {
			createNew: 'collaborators.addToOrganisationModal.createNew',
			createNewGroup: 'collaborators.addToOrganisationModal.createNewGroup',
			addTo: 'collaborators.addToOrganisationModal.addTo',
			addExistingGroup: 'collaborators.addToOrganisationModal.addExistingGroup',
			groupNameLabel: 'collaborators.addToOrganisationModal.groupNameLabel',
			groupNamePlaceholder: 'collaborators.addToOrganisationModal.groupNamePlaceholder',
			error: 'collaborators.addToOrganisationModal.error'
		},
		collaboratorModal: {
			information: 'collaborators.collaboratorModal.information',
			permissions: 'collaborators.collaboratorModal.permissions',
			groups: 'collaborators.collaboratorModal.groups',
			collaboratorDetails: 'collaborators.collaboratorModal.collaboratorDetails',
			role: 'collaborators.collaboratorModal.role',
			selectRole: 'collaborators.collaboratorModal.selectRole'
		},
		deleteBulkCollaboratorDataModal: {
			delete: 'collaborators.deleteBulkCollaboratorDataModal.delete',
			messageStart: 'collaborators.deleteBulkCollaboratorDataModal.messageStart',
			messageEnd: 'collaborators.deleteBulkCollaboratorDataModal.messageEnd',
			warningMessage: 'collaborators.deleteBulkCollaboratorDataModal.warningMessage',
			and: 'collaborators.deleteBulkCollaboratorDataModal.and',
			note: 'collaborators.deleteBulkCollaboratorDataModal.note'
		},
		deleteOrganizationModal: {
			delete: 'collaborators.deleteOrganizationModal.delete',
			messageStart: 'collaborators.deleteOrganizationModal.messageStart',
			messageEnd: 'collaborators.deleteOrganizationModal.messageEnd.',
			warningMessage: 'collaborators.deleteOrganizationModal.warningMessage.'
		},
		organizationModal: {
			groupDetails: 'collaborators.organizationModal.groupDetails',
			editGroup: 'collaborators.organizationModal.editGroup',
			collaboratorList: 'collaborators.organizationModal.collaboratorList',
			createNewGroup: 'collaborators.organizationModal.createNewGroup',
			groupName: 'collaborators.organizationModal.groupName',
			enterNameOfGroup: 'collaborators.organizationModal.enterNameOfGroup',
			nameAlreadyExists: 'collaborators.organizationModal.nameAlreadyExists',
			addCollaborators: 'collaborators.organizationModal.addCollaborators',
			enterNameOrEmail: 'collaborators.organizationModal.enterNameOrEmail',
			deleteGroup: 'collaborators.organizationModal.deleteGroup',
			noCollaborators: 'collaborators.organizationModal.noCollaborators',
			addGroup: 'collaborators.organizationModal.addGroup',
			groupsList: 'collaborators.organizationModal.groupsList'
		},
		removeFromOrganizationModal: {
			plural: 'collaborators.removeFromOrganizationModal.plural',
			remove: 'collaborators.removeFromOrganizationModal.remove',
			collaborator: 'collaborators.removeFromOrganizationModal.collaborator',
			multilineStart: 'collaborators.removeFromOrganizationModal.multilineStart',
			from: 'collaborators.removeFromOrganizationModal.from',
			group: 'collaborators.removeFromOrganizationModal.group',
			message: 'collaborators.removeFromOrganizationModal.message',
			permissions: 'collaborators.removeFromOrganizationModal.permissions'
		},
		modulePermissions: {
			projectDesignModule: 'collaborators.modulePermissions.projectDesignModule',
			projectDesignModuleDescription:
				'collaborators.modulePermissions.projectDesignModuleDescription',
			collaboratorModule: 'collaborators.modulePermissions.collaboratorModule',
			collaboratorModuleDescription:
				'collaborators.modulePermissions.collaboratorModuleDescription',
			infoMessage: 'collaborators.modulePermissions.infoMessage.'
		},
		collaboratorsTable: {
			name: 'collaborators.collaboratorsTable.name',
			collaborators: 'collaborators.collaboratorsTable.collaborators',
			role: 'collaborators.collaboratorsTable.role',
			emailAddress: 'collaborators.collaboratorsTable.emailAddress',
			workplace: 'collaborators.collaboratorsTable.workplace',
			organization: 'collaborators.collaboratorsTable.organization',
			subOrganization: 'collaborators.collaboratorsTable.subOrganization',
			title: 'collaborators.collaboratorsTable.title',
			groups: 'collaborators.collaboratorsTable.groups',
			editGroup: 'collaborators.collaboratorsTable.editGroup',
			deleteGroup: 'collaborators.collaboratorsTable.deleteGroup',
			editCollaborator: 'collaborators.collaboratorsTable.editCollaborator',
			removeCollaborator: 'collaborators.collaboratorsTable.removeCollaborator',
			department: 'collaborators.collaboratorsTable.department'
		}
	},
	projectSearch: {
		placeholder: 'projectSearch.placeholder'
	},
	promsList: {
		proms: 'promsList.proms',
		allPromsHeader: 'promsList.allPromsHeader',
		myPromsHeader: 'promsList.myPromsHeader',
		createFirstProm: 'promsList.createFirstProm',
		noPromsToShow: 'promsList.noPromsToShow'
	},
	promsSearch: {
		placeholder: 'promsSearch.placeholder'
	},
	projectStatus: {
		ongoing: 'projectStatus.ongoing',
		onhold: 'projectStatus.onhold',
		ended: 'projectStatus.ended',
		deleted: 'projectStatus.deleted'
	},
	projectList: {
		noProjectsToShow: 'projectList.noProjectsToShow'
	},
	helpPage: {
		TOCTitle: 'helpPage.TOCTitle',
		headerPlaceholder: 'helpPage.headerPlaceholder',
		topicNotFound: 'helpPage.topicNotFound',
		noTopics: 'helpPage.noTopics',
		noResults: 'helpPage.noResults'
	},
	shareModal: {
		projectSharedWithNewUsers: 'shareModal.projectSharedWithNewUsers',
		projectUnsharedForUsers: 'shareModal.projectUnsharedForUsers'
	},
	shareAddUsers: {
		title: 'shareAddUsers.title',
		addUsers: 'shareAddUsers.addUsers',
		addPeopleToThisProject: 'shareAddUsers.addPeopleToThisProject',
		useEnterToAddEmail: 'shareAddUsers.useEnterToAddEmail',
		role: 'shareAddUsers.role',
		manageUsersAccess: 'shareAddUsers.manageUsersAccess',
		editUserPermissions: 'shareAddUsers.editUserPermissions'
	},
	sharingSettings: {
		title: 'sharingSettings.title',
		whoHasAccess: 'sharingSettings.whoHasAccess',
		notSharedProject: 'sharingSettings.notSharedProject',
		projectNotSharedWithAnyone: 'sharingSettings.projectNotSharedWithAnyone',
		shareWithNewUsers: 'sharingSettings.shareWithNewUsers',
		shareIsOwner: 'sharingSettings.shareIsOwner'
	},
	shareUser: {
		avatarAlt: 'shareUser.avatarAlt',
		isOwner: 'shareUser.isOwner'
	},
	sharingPermissions: {
		title: 'sharingPermissions.title',
		subtitle: 'sharingPermissions.subtitle',
		headerViewData: 'sharingPermissions.headerViewData',
		headerEditData: 'sharingPermissions.headerEditData',
		headerAnalyse: 'sharingPermissions.headerAnalyse',
		headerDeleteData: 'sharingPermissions.headerDeleteData',
		bodyOwnData: 'sharingPermissions.bodyOwnData',
		bodyCenterData: 'sharingPermissions.bodyCenterData',
		bodyAllData: 'sharingPermissions.bodyAllData',
		allowExportData: 'sharingPermissions.allowExportData',
		allowVariablesEditing: 'sharingPermissions.allowVariablesEditing',
		allowProjectEditing: 'sharingPermissions.allowProjectEditing',
		allowAccessPersonalData: 'sharingPermissions.allowAccessPersonalData',
		statusHeader: 'sharingPermissions.statusHeader',
		statusDescription: 'sharingPermissions.statusDescription',
		statusTableColumns: {
			view: 'sharingPermissions.statusTableColumns.view',
			edit: 'sharingPermissions.statusTableColumns.edit',
			setStatus: 'sharingPermissions.statusTableColumns.setStatus'
		}
	},
	sharingConfirmNewMembers: {
		title: 'sharingConfirmNewMembers.title',
		subtitle: 'sharingConfirmNewMembers.subtitle',
		add: 'sharingConfirmNewMembers.add',
		termsOfUse: 'sharingConfirmNewMembers.termsOfUse',
		inviteUserToLedidPlatform: 'sharingConfirmNewMembers.inviteUserToLedidPlatform',
		inviteUser: 'sharingConfirmNewMembers.inviteUser',
		userInvitedNotification: 'sharingConfirmNewMembers.userInvitedNotification',
		inviteNewUserParagraph1: 'sharingConfirmNewMembers.inviteNewUserParagraph1',
		inviteNewUserDot1: 'sharingConfirmNewMembers.inviteNewUserDot1',
		inviteNewUserDot2: 'sharingConfirmNewMembers.inviteNewUserDot2',
		inviteNewUserDot3: 'sharingConfirmNewMembers.inviteNewUserDot3',
		inviteNewUserParagraph2: 'sharingConfirmNewMembers.inviteNewUserParagraph2',
		confirmToProject: 'sharingConfirmNewMembers.confirmToProject',
		usersNotAllowedDueToDomainConstraintViolation: {
			theFollowingEmailAddressIsNotConnectedToYourOrganization:
				'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.theFollowingEmailAddressIsNotConnectedToYourOrganization',
			forCollaboratorsWithinHSO:
				'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.forCollaboratorsWithinHSO',
			thePersonYouInvited:
				'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.thePersonYouInvited',
			forExternalCollaboratos:
				'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.forExternalCollaboratos',
			theFollowingEmailAddressesAreNotConnectedToYourOrganization:
				'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.theFollowingEmailAddressesAreNotConnectedToYourOrganization',
			ifYouWantToAddThisUserInYourRegisterContact:
				'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.ifYouWantToAddThisUserInYourRegisterContact',
			ifYouWantToAddTheseUsersInYourRegisterContact:
				'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.ifYouWantToAddTheseUsersInYourRegisterContact',
			or: 'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.or',
			andProvideTheFollowingInformation:
				'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.andProvideTheFollowingInformation',
			nameOfTheUser:
				'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.nameOfTheUser',
			title: 'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.title',
			institution:
				'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.institution',
			department:
				'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.department',
			roleInTheProject:
				'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.roleInTheProject',
			email: 'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.email',
			nameOfTheProjectInLedidi:
				'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.nameOfTheProjectInLedidi',
			projectNumber:
				'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.projectNumber',
			weWillReturnWithMoreInformation:
				'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.weWillReturnWithMoreInformation',
			ifUsersYouWantToInviteDoesBelongToYourOrganizationAnyways:
				'sharingConfirmNewMembers.usersNotAllowedDueToDomainConstraintViolation.ifUsersYouWantToInviteDoesBelongToYourOrganizationAnyways'
		}
	},
	projectTabs: {
		analysis: 'projectTabs.analysis',
		forms: 'projectTabs.forms',
		dataset: 'projectTabs.dataset',
		variables: 'projectTabs.variables',
		documents: 'projectTabs.documents',
		dashboard: 'projectTabs.dashboard',
		patients: 'projectTabs.patients',
		collaborators: 'projectTabs.collaborators',
		templates: 'projectTabs.templates',
		dependencies: 'projectTabs.dependencies',
		statuses: 'projectTabs.statuses',
		roles: 'projectTabs.roles'
	},
	icon: {
		ledidiLogoAlt: 'icon.ledidiLogoAlt',
		ledidiLogoColoredAlt: 'icon.ledidiLogoColoredAlt',
		addAlt: 'icon.addAlt',
		arrowDownAlt: 'icon.arrowDownAlt',
		arrowLeftAlt: 'icon.arrowLeftAlt',
		calendarAlt: 'icon.calendarAlt',
		clockAlt: 'icon.clockAlt',
		chevronArrowCornerAlt: 'icon.chevronArrowCornerAlt',
		chevronDownAlt: 'icon.chevronDownAlt',
		chevronLeftAlt: 'icon.chevronLeftAlt',
		chevronLeftDoubleAlt: 'icon.chevronLeftDoubleAlt',
		chevronRightAlt: 'icon.chevronRightAlt',
		chevronRightDoubleAlt: 'icon.chevronRightDoubleAlt',
		chevronUpAlt: 'icon.chevronUpAlt',
		clearAlt: 'icon.clearAlt',
		closeAlt: 'icon.closeAlt',
		deleteAlt: 'icon.deleteAlt',
		mergeAlt: 'icon.mergeAlt',
		importAlt: 'icon.importAlt',
		editAlt: 'icon.editAlt',
		fileAlt: 'icon.fileAlt',
		filePlusAlt: 'icon.filePlusAlt',
		fileTextAlt: 'icon.fileTextAlt',
		filterActiveAlt: 'icon.filterActiveAlt',
		filterAlt: 'icon.filterAlt',
		folderAlt: 'icon.folderAlt',
		formCheckboxSelectedAlt: 'icon.formCheckboxSelectedAlt',
		formCheckboxUnselectedAlt: 'icon.formCheckboxUnselectedAlt',
		formRadioSelectedAlt: 'icon.formRadioSelectedAlt',
		formRadioUnselectedAlt: 'icon.formRadioUnselectedAlt',
		helpAlt: 'icon.helpAlt',
		informationAlt: 'icon.informationAlt',
		externalLinkAlt: 'icon.externalLinkAlt',
		menuProjectsAlt: 'icon.menuProjectsAlt',
		menuPromsAlt: 'icon.menuPromsAlt',
		minusAlt: 'icon.minusAlt',
		moreAlt: 'icon.moreAlt',
		minimizeTable: 'icon.minimizeTable',
		maximizeTable: 'icon.maximizeTable',
		move: 'icon.move',
		notificationsAlt: 'icon.notificationsAlt',
		previewOffAlt: 'icon.previewOffAlt',
		previewOnAlt: 'icon.previewOnAlt',
		printAlt: 'icon.printAlt',
		refreshAlt: 'icon.refreshAlt',
		repeatAlt: 'icon.repeatAlt',
		searchAlt: 'icon.searchAlt',
		settingsAlt: 'icon.settingsAlt',
		userAlt: 'icon.userAlt',
		viewGridAlt: 'icon.viewGridAlt',
		viewListAlt: 'icon.viewListAlt',
		viewTabelAlt: 'icon.viewTabelAlt',
		viewGroupedAlt: 'icon.viewGroupedAlt',
		pieChartAlt: 'icon.pieChartAlt',
		stackedViewChartAlt: 'icon.stackedViewChartAlt',
		sunburstViewChartAlt: 'icon.sunburstViewChartAlt',
		boxPlotViewAlt: 'icon.boxPlotViewAlt',
		scatterViewAlt: 'icon.scatterAlt',
		workplaceAlt: 'icon.workplaceAlt',
		zoomOutAlt: 'icon.zoomOutAlt',

		emptyStatesCollaboratorsAlt: 'emptyStatesCollaboratorsAlt',
		emptyStatesNoAnalysisAlt: 'emptyStatesNoAnalysisAlt',
		emptyStatesNoDocumentsAlt: 'emptyStatesNoDocumentsAlt',
		emptyStatesNoVariablesAlt: 'emptyStatesNoVariablesAlt'
	},
	iconTooltip: {
		ledidiLogo: 'iconTooltip.ledidiLogo',
		add: 'iconTooltip.add',
		calendar: 'iconTooltip.calendar',
		clear: 'iconTooltip.clear',
		close: 'iconTooltip.close',
		copyToClipboard: 'iconTooltip.copyToClipboard',
		delete: 'iconTooltip.delete',
		merge: 'iconTooltip.merge',
		import: 'iconTooltip.import',
		edit: 'iconTooltip.edit',
		filePlus: 'iconTooltip.filePlus',
		filter: 'iconTooltip.filter',
		help: 'iconTooltip.help',
		info: 'iconTooltip.info',
		externalLink: 'iconTooltip.externalLink',
		menuProjects: 'iconTooltip.menuProjects',
		menuProms: 'iconTooltip.menuProms',
		more: 'iconTooltip.more',
		minimizeTable: 'icon.minimizeTable',
		maximizeTable: 'icon.maximizeTable',
		notifications: 'iconTooltip.notifications',
		previewOff: 'iconTooltip.previewOff',
		previewOn: 'iconTooltip.previewOn',
		print: 'iconTooltip.print',
		refresh: 'iconTooltip.refresh',
		search: 'iconTooltip.search',
		settings: 'iconTooltip.settings',
		zoomOut: 'iconTooltip.zoomOut',
		gridView: 'iconTooltip.gridView',
		tableView: 'iconTooltip.tableView'
	},
	tags: {
		you: 'tags.you',
		owner: 'tags.owner',
		pending: 'tags.pending'
	},
	user: {
		userAvatarAlt: 'user.userAvatarAlt'
	},
	addons: {
		errorLoadingAddons: 'addons.errorLoadingAddons',
		activateAddonFirst: 'addons.activateAddonFirst',
		options: {
			jadBio: 'addons.options.jadBio',
			jadBioInfo: 'addons.options.jadBioInfo'
		}
	},
	notifications: {
		drawerTitle: 'notifications.drawerTitle',
		today: 'notifications.today',
		yesterday: 'notifications.yesterday',
		noNewNotifications: 'notifications.noNewNotifications',
		older: 'notifications.older',
		markAllAsRead: 'notifications.markAllAsRead',
		onlyShowUnread: 'notifications.onlyShowUnread',
		notificationSettings: 'notifications.notificationSettings',
		noNotificationsToShow: 'notifications.noNotificationsToShow',
		cardButtons: {
			remove: 'notifications.cardButtons.remove',
			turnOff: 'notifications.cardButtons.turnOff'
		},
		timeUnit: {
			secondsAgo: 'notifications.timeUnit.secondsAgo',
			minuteAgo: 'notifications.timeUnit.minuteAgo',
			minutesAgo: 'notifications.timeUnit.minutesAgo',
			hourAgo: 'notifications.timeUnit.hourAgo',
			hoursAgo: 'notifications.timeUnit.hoursAgo',
			dayAgo: 'notifications.timeUnit.dayAgo',
			daysAgo: 'notifications.timeUnit.daysAgo',
			weekAgo: 'notifications.timeUnit.weekAgo',
			weeksAgo: 'notifications.timeUnit.weeksAgo',
			monthAgo: 'notifications.timeUnit.monthAgo',
			monthsAgo: 'notifications.timeUnit.monthsAgo'
		},
		message: {
			subscriptionPlanCreation: 'notifications.message.subscriptionPlanCreation',
			subscriptionPlanChange: 'notifications.message.subscriptionPlanChange',
			subscriptionPlanUpdate: 'notifications.message.subscriptionPlanUpdate',
			subscriptionPlanUpdateBatch: 'notifications.message.subscriptionPlanUpdateBatch',
			subscriptionExpiration: 'notifications.message.subscriptionExpiration',
			userSubscriptionInvitation: 'notifications.message.userSubscriptionInvitation',
			ownerSubscriptionInvitation: 'notifications.message.ownerSubscriptionInvitation',
			userSubscriptionInvitationAccepted:
				'notifications.message.userSubscriptionInvitationAccepted',
			ownerSubscriptionInvitationAccepted:
				'notifications.message.ownerSubscriptionInvitationAccepted',
			userSubscriptionInvitationDeclined:
				'notifications.message.userSubscriptionInvitationDeclined',
			ownerSubscriptionInvitationDeclined:
				'notifications.message.ownerSubscriptionInvitationDeclined',
			subscriptionInvitationRequestAcceptedByUser:
				'notifications.message.subscriptionInvitationRequestAcceptedByUser',
			subscriptionInvitationRequestAcceptedByOwner:
				'notifications.message.subscriptionInvitationRequestAcceptedByOwner',
			subscriptionInvitationRequestDeclinedByUser:
				'notifications.message.subscriptionInvitationRequestDeclinedByUser',
			subscriptionInvitationRequestDeclinedByOwner:
				'notifications.message.subscriptionInvitationRequestDeclinedByOwner',
			userSubscriptionInvitationCancelled:
				'notifications.message.userSubscriptionInvitationCancelled',
			ownerSubscriptionInvitationCancelled:
				'notifications.message.ownerSubscriptionInvitationCancelled',
			subscriptionPaymentFailed: 'notifications.message.subscriptionPaymentFailed',
			subscriptionUserRemoval: 'notifications.message.subscriptionUserRemoval',
			subscriptionUserRemovalByDowngrade:
				'notifications.message.subscriptionUserRemovalByDowngrade',
			projectEntryAdd: 'notifications.message.projectEntryAdd',
			projectEntryAddBatch: 'notifications.message.projectEntryAddBatch',
			projectOwnershipTransferred: 'notifications.message.projectOwnershipTransferred',
			projectOwnershipTransferredMulti:
				'notifications.message.projectOwnershipTransferredMulti'
		},
		settings: {
			drawerTitle: 'notifications.settings.drawerTitle',
			project: {
				typeProjectName: 'notifications.settings.project.typeProjectName',
				entries: 'notifications.settings.project.entries',
				entriesCreated: 'notifications.settings.project.entriesCreated'
			}
		}
	},
	accountUM: {
		subscriptionManagementTitle: 'accountUM.subscriptionManagementTitle',
		tabs: {
			subscription: 'accountUM.tabs.subscription',
			accountInformation: 'accountUM.tabs.accountInformation',
			payment: 'accountUM.tabs.payment',
			billingHistory: 'accountUM.tabs.billingHistory',
			addons: 'accountUM.tabs.addons'
		},
		payment: {
			methods: {
				currentMethod: 'accountUM.payment.methods.currentMethod',
				invoiceOption: 'accountUM.payment.methods.invoiceOption',
				invoiceDescription: 'accountUM.payment.methods.invoiceDescription',
				creditCardOption: 'accountUM.payment.methods.creditCardOption',
				creditCardDescription: 'accountUM.payment.methods.creditCardDescription'
			},
			secureConnection: 'accountUM.payment.secureConnection',
			sSLSecureConnection: 'accountUM.payment.sSLSecureConnection',
			creditCards: 'accountUM.payment.creditCards',
			expiringIn: 'accountUM.payment.expiringIn',
			makePrimary: 'accountUM.payment.makePrimary',
			editCardDetails: 'accountUM.payment.editCardDetails',
			removeCard: 'accountUM.payment.removeCard',
			removeLastCardWarning: 'accountUM.payment.removeLastCardWarning',
			removeCardWarningConfirm: 'accountUM.payment.removeCardWarningConfirm',
			primary: 'accountUM.payment.primary',
			addAnotherCard: 'accountUM.payment.addAnotherCard',
			addCard: 'accountUM.payment.addCard',
			addNewCard: 'accountUM.payment.addNewCard',
			addNewCardWarning: 'accountUM.payment.addNewCardWarning',
			addNewCardWarningConfirm: 'accountUM.payment.addNewCardWarningConfirm',
			editCard: 'accountUM.payment.editCard',
			noCardsAdded: 'accountUM.payment.noCardsAdded',
			notifications: {
				madePaymentSourcePrimary:
					'accountUM.payment.notifications.madePaymentSourcePrimary',
				deletedPaymentSource: 'accountUM.payment.notifications.deletedPaymentSource'
			}
		},
		subscription: {
			buyMoreLicences: 'accountUM.subscription.buyMoreLicences',
			buyLicences: 'accountUM.subscription.buyLicences',
			subscriptionLicences: 'accountUM.subscription.subscriptionLicences',
			available: 'accountUM.subscription.available',
			showingLicencesList: 'accountUM.subscription.showingLicencesList',
			addNewUser: 'accountUM.subscription.addNewUser',
			removeUserInvitation: 'accountUM.subscription.removeUserInvitation',
			cancelUserInvitationWarning: 'accountUM.subscription.cancelUserInvitationWarning',
			assignOneLicenceToYourself: 'accountUM.subscription.assignOneLicenceToYourself',
			noSubscription: 'accountUM.subscription.noSubscription',
			noSubscriptionPlan: 'accountUM.subscription.noSubscriptionPlan',
			noLicence: 'accountUM.subscription.noLicence',
			buySubscription: 'accountUM.subscription.buySubscription',
			subscriptionUpdateInfoText: 'accountUM.subscription.subscriptionUpdateInfoText',
			accountCovered: 'accountUM.subscription.accountCovered',
			ledidiCorePlan: 'accountUM.subscription.ledidiCorePlan',
			ledidiFreePlan: 'accountUM.subscription.ledidiFreePlan',
			licencesInTotal: 'accountUM.subscription.licencesInTotal',
			licenceInTotal: 'accountUM.subscription.licenceInTotal',
			activeLicences: 'accountUM.subscription.activeLicences',
			manageSubscription: 'accountUM.subscription.manageSubscription',
			upgradeSubscription: 'accountUM.subscription.upgradeSubscription',
			licenceManagedBy: 'accountUM.subscription.licenceManagedBy',
			subscriptionNames: {
				ledidiEnterprise: 'accountUM.subscription.subscriptionNames.ledidiEnterprise',
				ledidiCore: 'accountUM.subscription.subscriptionNames.ledidiCore',
				ledidiCollaborator: 'accountUM.subscription.subscriptionNames.ledidiCollaborator',
				ledidiFree: 'accountUM.subscription.subscriptionNames.ledidiFree',
				ledidiFreeTrial: 'accountUM.subscription.subscriptionNames.ledidiFreeTrial'
			},
			searchUser: 'accountUM.subscription.searchUser',
			apiKey: 'accountUM.subscription.apiKey'
		},
		addSubscriptionUser: {
			addModalTitle: 'accountUM.addSubscriptionUser.addModalTitle',
			transferUserTitle: 'accountUM.addSubscriptionUser.transferUserTitle',
			transferUserMessage: 'accountUM.addSubscriptionUser.transferUserMessage',
			userAlreadyExistsTitle: 'accountUM.addSubscriptionUser.userAlreadyExistsTitle',
			userAlreadyExistsMessage: 'accountUM.addSubscriptionUser.userAlreadyExistsMessage',
			userAlreadyExistsIsOwnerTitle:
				'accountUM.addSubscriptionUser.userAlreadyExistsIsOwnerTitle',
			userAlreadyExistsIsOwnerMessage:
				'accountUM.addSubscriptionUser.userAlreadyExistsIsOwnerMessage',
			userAlreadyExistsIsPendingTitle:
				'accountUM.addSubscriptionUser.userAlreadyExistsIsPendingTitle',
			userAlreadyExistsIsPendingMessage:
				'accountUM.addSubscriptionUser.userAlreadyExistsIsPendingMessage',
			confirmationAddedTitle: 'accountUM.addSubscriptionUser.confirmationAddedTitle',
			confirmationAddedMessage: 'accountUM.addSubscriptionUser.confirmationAddedMessage',
			confirmationInvitedTitle: 'accountUM.addSubscriptionUser.confirmationInvitedTitle',
			confirmationInvitedMessage: 'accountUM.addSubscriptionUser.confirmationInvitedMessage',
			alreadyInTransferTitle: 'accountUM.addSubscriptionUser.alreadyInTransferTitle',
			alreadyInTransferMessage: 'accountUM.addSubscriptionUser.alreadyInTransferMessage',
			alreadyOwnerTitle: 'accountUM.addSubscriptionUser.alreadyOwnerTitle',
			alreadyOwnerMessage: 'accountUM.addSubscriptionUser.alreadyOwnerMessage',
			userIsChangingPlanTitle: 'accountUM.addSubscriptionUser.userIsChangingPlanTitle',
			userIsChangingPlanMessage: 'accountUM.addSubscriptionUser.userIsChangingPlanMessage',
			userIsInEnterpriseSubscriptionMessage:
				'accountUM.addSubscriptionUser.userIsInEnterpriseSubscriptionMessage',
			userIsInEnterpriseSubscriptionTitle:
				'accountUM.addSubscriptionUser.userIsInEnterpriseSubscriptionTitle'
		},
		editSubscriptionUser: {
			editModalTitle: 'accountUM.editSubscriptionUser.editModalTitle',
			tabInformation: 'accountUM.editSubscriptionUser.tabInformation',
			tabLicence: 'accountUM.editSubscriptionUser.tabLicence',
			tabRoles: 'accountUM.editSubscriptionUser.tabRoles',
			removeUserLicence: 'accountUM.editSubscriptionUser.removeUserLicence',
			personalDetailsSubheader: 'accountUM.editSubscriptionUser.personalDetailsSubheader',
			workDetailsSubheader: 'accountUM.editSubscriptionUser.workDetailsSubheader',
			passwordSubheader: 'accountUM.editSubscriptionUser.passwordSubheader',
			resetPasswordText: 'accountUM.editSubscriptionUser.resetPasswordText',
			proceedConfirmation: 'accountUM.editSubscriptionUser.proceedConfirmation'
		},
		subscriptionUsersDropdown: {
			editUser: 'accountUM.subscriptionUsersDropdown.editUser',
			resetPassword: 'accountUM.subscriptionUsersDropdown.resetPassword',
			changeRole: 'accountUM.subscriptionUsersDropdown.changeRole',
			deactivateUser: 'accountUM.subscriptionUsersDropdown.deactivateUser',
			reactivateUser: 'accountUM.subscriptionUsersDropdown.reactivateUser',
			removeLicence: 'accountUM.subscriptionUsersDropdown.removeLicence'
		},
		cancelSubscription: {
			cancelSusbcription: 'accountUM.cancelSubscription.cancelSubscription',
			cancelFreeTrial: 'accountUM.cancelSubscription.cancelFreeTrial'
		},
		changePlan: {
			changePlan: 'accountUM.changePlan.changePlan',
			choosePlan: 'accountUM.changePlan.choosePlan',
			orderSummary: 'accountUM.changePlan.orderSummary',
			selectPayment: 'accountUM.changePlan.selectPayment',
			selectCreditCard: 'accountUM.changePlan.selectCreditCard',
			billingInformation: 'accountUM.changePlan.billingInformation',
			addNewCreditCard: 'accountUM.payment.addNewCard',
			updateSuccess: 'accountUM.changePlan.updateSuccess',
			planDetails: 'accountUM.changePlan.planDetails',
			downgradeWarning: 'accountUM.changePlan.downgradeWarning',
			downgradeConfirmation: 'accountUM.changePlan.downgradeConfirmation',
			payMonthly: 'accountUM.changePlan.payMonthly',
			calculatedYearlyInfo: 'accountUM.changePlan.calculatedYearlyInfo',
			calculatedMonthlyInfo: 'accountUM.changePlan.calculatedMonthlyInfo',
			free: 'accountUM.changePlan.free',
			days30: 'accountUM.changePlan.days30',
			ledidiFreeCaption: 'accountUM.changePlan.ledidiFreeCaption',
			ledidiCollaboratorCaption: 'accountUM.changePlan.ledidiCollaboratorCaption',
			ledidiCoreCaption: 'accountUM.changePlan.ledidiCoreCaption',
			ledidiCoreMonthly: 'accountUM.changePlan.ledidiCoreMonthly',
			ledidiCoreYearly: 'accountUM.changePlan.ledidiCoreYearly',
			ledidiFreeTrialPlanAdvantage: 'accountUM.changePlan.ledidiFreeTrialPlanAdvantage',
			ledidiCollaboratorPlanAdvantage: 'accountUM.changePlan.ledidiCollaboratorPlanAdvantage',
			ledidiCorePlanAdvantageOneLicence:
				'accountUM.changePlan.ledidiCorePlanAdvantageOneLicence',
			licences: 'accountUM.changePlan.licences',
			perUserPerMonth: 'accountUM.changePlan.perUserPerMonth',
			perUserPerYear: 'accountUM.changePlan.perUserPerYear',
			yearly: 'accountUM.changePlan.yearly',
			monthly: 'accountUM.changePlan.monthly',
			creditBalance: 'accountUM.changePlan.creditBalance',
			creditBalanceCaption: 'accountUM.changePlan.creditBalanceCaption',
			totalDueToday: 'accountUM.changePlan.totalDueToday',
			billedMonthly: 'accountUM.changePlan.billedMonthly',
			billedYearly: 'accountUM.changePlan.billedYearly',
			thankYouForSubscription: 'accountUM.changePlan.thankYouForSubscription',
			tillChangesCompleteInfo: 'accountUM.changePlan.tillChangesCompleteInfo',
			fromPaidToFreeChangeCaption: 'accountUM.changePlan.fromPaidToFreeChangeCaption',
			downgradeHead: 'accountUM.changePlan.downgradeHead',
			downgradeInfo1: 'accountUM.changePlan.downgradeInfo1',
			downgradeInfo2: 'accountUM.changePlan.downgradeInfo2',
			downgradeInfo3: 'accountUM.changePlan.downgradeInfo3',
			downgradeInfo4: 'accountUM.changePlan.downgradeInfo4',
			actionCannotBeUndone: 'accountUM.changePlan.actionCannotBeUndone',
			typingExtraCheckText: 'accountUM.changePlan.typingExtraCheckText',
			downgradeText: 'accountUM.changePlan.downgradeText',
			subscriptionIsUpdating: 'accountUM.changePlan.subscriptionIsUpdating',
			paymentMethod: 'accountUM.changePlan.paymentMethod',
			selectedPlanBrakedown: 'accountUM.changePlan.selectedPlanBrakedown',
			ledidiCorePlanOneYear: 'accountUM.changePlan.ledidiCorePlanOneYear',
			ledidiCorePlanOneMonth: 'accountUM.changePlan.ledidiCorePlanOneMonth',
			vAT: 'accountUM.changePlan.vAT',
			totalAmountDue: 'accountUM.changePlan.totalAmountDue',
			selectPaymentMethod: {
				creditCard: 'accountUM.changePlan.selectPaymentMethod.creditCard',
				payNow: 'accountUM.changePlan.selectPaymentMethod.payNow',
				invoice: 'accountUM.changePlan.selectPaymentMethod.invoice',
				payByInvoice: 'accountUM.changePlan.selectPaymentMethod.payByInvoice',
				invoiceInfo: 'accountUM.changePlan.selectPaymentMethod.invoiceInfo'
			},
			invoiceAlertModal: {
				title: 'accountUM.changePlan.invoiceAlertModal.title',
				warningText: 'accountUM.changePlan.invoiceAlertModal.warningText',
				warningTextMoreCards: 'accountUM.changePlan.invoiceAlertModal.warningTextMoreCards',
				confirmText: 'accountUM.changePlan.invoiceAlertModal.confirmText'
			}
		},
		billing: {
			nextPaymentDate: 'accountUM.billing.nextPaymentDate',
			nextInvoiceDate: 'accountUM.billing.nextInvoiceDate',
			dueDate: 'accountUM.billing.dueDate',
			cardEndingIn: 'accountUM.billing.cardEndingIn',
			periodUnit: {
				month: 'accountUM.billing.periodUnit.month',
				year: 'accountUM.billing.periodUnit.year'
			},
			paymentStatus: {
				paid: 'accountUM.billing.paymentStatus.paid',
				notPaid: 'accountUM.billing.paymentStatus.notPaid',
				posted: 'accountUM.billing.paymentStatus.posted',
				paymentDue: 'accountUM.billing.paymentStatus.paymentDue',
				voided: 'accountUM.billing.paymentStatus.voided',
				pending: 'accountUM.billing.paymentStatus.pending'
			},
			date: 'accountUM.billing.date',
			plan: 'accountUM.billing.plan',
			orderNumber: 'accountUM.billing.orderNumber',
			amount: 'accountUM.billing.amount',
			status: 'accountUM.billing.status',
			action: 'accountUM.billing.action',
			updatePayment: 'accountUM.billing.updatePayment',
			downloadInvoice: 'accountUM.billing.downloadInvoice',
			paymentDueDateExceeded: 'accountUM.billing.paymentDueDateExceeded',
			paymentFailedInfo: 'accountUM.billing.paymentFailedInfo',
			billedTo: 'accountUM.billing.billedTo',
			noBillingAddress: 'accountUM.billing.noBillingAddress',
			billingHistory: 'accountUM.billing.billingHistory',
			modalBillingTitle: 'accountUM.billing.modalBillingTitle',
			sameAsPaymentInfo: 'accountUM.billing.sameAsPaymentInfo',
			useCompanyDetails: 'accountUM.billing.useCompanyDetails',
			updatePrimaryPaymentAddress: 'accountUM.billing.updatePrimaryPaymentAddress'
		},

		pendingInvitation: {
			modalTitle: 'accountUM.pendingInvitation.modalTitle',
			ownerPendingInvitationTitle: 'accountUM.pendingInvitation.ownerPendingInvitationTitle',
			ownerPendingInvitationMessage:
				'accountUM.pendingInvitation.ownerPendingInvitationMessage',
			userPendingInvitationTitle: 'accountUM.pendingInvitation.userPendingInvitationTitle',
			userPendingInvitationMessage:
				'accountUM.pendingInvitation.userPendingInvitationMessage',
			userWithNoOwnerPendingInvitationMessage:
				'accountUM.pendingInvitation.userWithNoOwnerPendingInvitationMessage'
		},
		userDetails: {
			name: 'accountUM.userDetails.name',
			firstName: 'accountUM.userDetails.firstName',
			lastName: 'accountUM.userDetails.lastName',
			email: 'accountUM.userDetails.email',
			status: 'accountUM.userDetails.status',
			emailAddress: 'accountUM.userDetails.emailAddress',
			newUserEmailAddress: 'accountUM.userDetails.newUserEmailAddress',
			phoneNumber: 'accountUM.userDetails.phoneNumber',
			city: 'accountUM.userDetails.city',
			country: 'accountUM.userDetails.country',
			physicalAddress: 'accountUM.userDetails.physicalAddress',
			countryOfResidence: 'accountUM.userDetails.countryOfResidence',
			organization: 'accountUM.userDetails.organization',
			suborganization: 'accountUM.userDetails.suborganization',
			position: 'accountUM.userDetails.position',
			department: 'accountUM.userDetails.department',
			licenceModelSelect: 'accountUM.userDetails.licenceModelSelect',
			licenceModel: 'accountUM.userDetails.licenceModel',
			workplace: 'accountUM.userDetails.workplace',
			state: 'accountUM.userDetails.state',
			zip: 'accountUM.userDetails.zip',
			addressLine1: 'accountUM.userDetails.addressLine1',
			addressLine2: 'accountUM.userDetails.addressLine2',
			company: 'accountUM.userDetails.company',
			vatNumber: 'accountUM.userDetails.vatNumber',
			chooseRole: 'accountUM.userDetails.chooseRole',
			role: 'accountUM.userDetails.role'
		},
		validation: {
			firstNameError: 'accountUM.validation.firstNameError',
			lastNameError: 'accountUM.validation.lastNameError',
			emailAddressError: 'accountUM.validation.emailAddressError',
			emailAddressErrorValid: 'accountUM.validation.emailAddressErrorValid',
			organizationError: 'accountUM.validation.organizationError',
			phoneNumberError: 'accountUM.validation.phoneNumberError',
			phoneNumberErrorValid: 'accountUM.validation.phoneNumberErrorValid',
			cityError: 'accountUM.validation.cityError',
			countryError: 'accountUM.validation.countryError',
			departmentError: 'accountUM.validation.departmentError',
			addressError: 'accountUM.validation.addressError',
			stateError: 'accountUM.validation.stateError',
			zipError: 'accountUM.validation.zipError',
			companyError: 'accountUM.validation.companyError',
			vatError: 'accountUM.validation.vatError'
		},
		userDrawer: {
			avatar: {
				alt: 'accountUM.userDrawer.avatar.alt'
			},
			headers: {
				profile: 'accountUM.userDrawer.headers.profile',
				projectDesign: 'accountUM.userDrawer.headers.projectDesign',
				accountInformation: 'accountUM.userDrawer.headers.accountInformation',
				workInformation: 'accountUM.userDrawer.headers.workInformation',
				personalSettings: 'accountUM.userDrawer.headers.personalSettings',
				security: 'accountUM.userDrawer.headers.security',
				version: 'accountUM.userDrawer.headers.version'
			},
			views: {
				subscriptionManagement: 'accountUM.userDrawer.views.subscriptionManagement',
				accountInformation: 'accountUM.userDrawer.views.accountInformation',
				workInformation: 'accountUM.userDrawer.views.workInformation',
				personalSettings: 'accountUM.userDrawer.views.personalSettings',
				security: 'accountUM.userDrawer.views.security',
				help: 'accountUM.userDrawer.views.help',
				projects: 'accountUM.userDrawer.views.projects',
				proms: 'accountUM.userDrawer.views.proms',
				rolesTemplate: 'accountUM.userDrawer.views.rolesTemplate',
				roleTemplates: 'accountUM.userDrawer.views.roleTemplates',
				userAdmin: 'accountUM.userDrawer.views.userAdmin'
			}
		},
		licenceModels: {
			full: 'accountUM.licenceModels.full',
			collaborator: 'accountUM.licenceModels.collaborator',
			oneOwnedOneShared: 'accountUM.licenceModels.oneOwnedOneShared',
			trial: 'accountUM.licenceModels.trial'
		},
		licenceModelsDescriptions: {
			full: 'accountUM.licenceModelsDescriptions.full',
			collaborator: 'accountUM.licenceModelsDescriptions.collaborator'
		},
		userRoles: {
			organizationAdmin: 'accountUM.userRoles.organizationAdmin',
			superAdmin: 'accountUM.userRoles.superAdmin',
			admin: 'accountUM.userRoles.admin',
			user: 'accountUM.userRoles.user',
			superAdminDescription: 'accountUM.userRoles.superAdminDescription',
			adminDescription: 'accountUM.userRoles.adminDescription',
			userDescription: 'accountUM.userRoles.userDescription'
		},
		userStatus: {
			Active: 'accountUM.userStatus.Active',
			Inactive: 'accountUM.userStatus.Inactive'
		}
	},
	editAccount: {
		userProfileUpdated: 'editAccount.userProfileUpdated',
		userAvatarAlt: 'editAccount.userAvatarAlt',
		changeImage: 'editAccount.changeImage',
		updateInformation: 'editAccount.updateInformation'
	},
	workInformation: {
		title: 'workInformation.title',
		saveButton: 'workInformation.saveButton',
		updatedNotification: 'workInformation.updatedNotification',
		changingOrganizationWillCancelPermissions:
			'workInformation.changingOrganizationWillCancelPermissions'
	},
	personalSettings: {
		language: {
			label: 'personalSettings.language.label',
			preferredLanguage: 'personalSettings.language.preferredLanguage',
			english: 'personalSettings.language.english',
			norwegian: 'personalSettings.language.norwegian',
			dutch: 'personalSettings.language.dutch'
		},
		dateTime: {
			label: 'personalSettings.dateTime.label',
			component: {
				label: 'personalSettings.dateTime.component.label',
				hint: 'personalSettings.dateTime.component.hint'
			}
		},
		submitButton: 'personalSettings.submitButton',
		updatedNotification: 'personalSettings.updatedNotification'
	},
	security: {
		securityHeader: 'security.securityHeader',
		twoFactorAuth: 'security.twoFactorAuth',
		codePlaceholder: 'security.codePlaceholder',
		otpInstructions: 'security.otpInstructions',
		infoText: 'security.infoText',
		helpText: 'security.helpText',
		helpComponent: {
			whatIsMfa: {
				title: 'security.helpComponent.whatIsMfa.title',
				text: 'security.helpComponent.whatIsMfa.text'
			},
			stepOne: {
				title: 'security.helpComponent.stepOne.title',
				subtitle: 'security.helpComponent.stepOne.subtitle',
				text: 'security.helpComponent.stepOne.text',
				textSmall: 'security.helpComponent.stepOne.textSmall'
			},
			stepTwo: {
				title: 'security.helpComponent.stepTwo.title',
				subtitle: 'security.helpComponent.stepTwo.subtitle',
				text: 'security.helpComponent.stepTwo.text',
				textSmall: 'security.helpComponent.stepTwo.textSmall'
			},
			stepThree: {
				title: 'security.helpComponent.stepThree.title',
				subtitle: 'security.helpComponent.stepThree.subtitle',
				text: 'security.helpComponent.stepThree.text'
			}
		}
	},
	readMore: {
		description: 'readMore.description'
	},
	infoToolTip: {
		copiedToClipBoard: 'infoToolTip.copiedToClipBoard'
	},
	createProject: {
		headerTitle: 'createProject.headerTitle',
		projectTitleError: 'createProject.projectTitleError',
		projectCreated: 'createProject.projectCreated',
		projectTitle: 'createProject.projectTitle',
		projectNumber: 'createProject.projectNumber',
		projectEndDate: 'createProject.projectEndDate',
		status: 'createProject.status',
		ongoing: 'createProject.ongoing',
		onHold: 'createProject.onHold',
		ended: 'createProject.ended',
		projectDescription: 'createProject.projectDescription',
		mandatory: 'createProject.mandatory'
	},

	copyProject: {
		copyProject: 'copyProject.copyProject',
		copyProjectButton: 'copyProject.copyProjectButton',
		newProjectTitle: 'copyProject.newProjectTitle',
		newProjectDescription: 'copyProject.newProjectDescription',
		newProjectNumber: 'copyProject.newProjectNumber',
		newProjectEndDate: 'copyProject.newProjectEndDate',
		include: 'copyProject.include',
		selectElement: 'copyProject.selectElement',
		changeOwner: {
			projectOwner: 'copyProject.changeOwner.projectOwner',
			changeOwner: 'copyProject.changeOwner.changeOwner',
			changeOwnerModalTitle: 'copyProject.changeOwner.changeOwnerModalTitle',
			addOwnerToProject: 'copyProject.changeOwner.addOwnerToProject',
			typeOneMail: 'copyProject.changeOwner.typeOneMail',
			confirmToProject: 'copyProject.changeOwner.confirmToProject',
			confirmAndInvite: 'copyProject.changeOwner.confirmAndInvite',
			noUserFoundError: 'copyProject.changeOwner.noUserFoundError',
			sameOwnerError: 'copyProject.changeOwner.sameOwnerError',
			changeProjectDetailsRestriction:
				'copyProject.changeOwner.changeProjectDetailsRestriction'
		},
		copyOptions: {
			variables: 'copyProject.copyOptions.variables',
			groups: 'copyProject.copyOptions.groups',
			forms: 'copyProject.copyOptions.forms',
			dependencies: 'copyProject.copyOptions.dependencies',
			statuses: 'copyProject.copyOptions.statuses',
			statusPermissions: 'copyProject.copyOptions.statusPermissions',
			series: 'copyProject.copyOptions.series',
			data: 'copyProject.copyOptions.data',
			projectRoles: 'copyProject.copyOptions.projectRoles'
		}
	},
	createProm: {
		headerTitle: 'createProm.headerTitle',
		promTitleError: 'createProm.promTitleError',
		promCreated: 'createProm.promCreated',
		promTitle: 'createProm.promTitle',
		promNumber: 'createProm.promNumber',
		status: 'createProm.status',
		ongoing: 'createProm.ongoing',
		onHold: 'createProm.onHold',
		ended: 'createProm.ended',
		promDescription: 'createProm.promDescription',
		mandatory: 'createProm.mandatory',
		selectDistributionType: 'createProm.selectDistributionType',
		distributionType: 'createProm.distributionType',
		distributionAutomatic: 'createProm.distributionAutomatic',
		distributionManual: 'createProm.distributionManual'
	},
	promCreatedOptions: {
		distributionPushSetup: 'promCreatedOptions.distributionPushSetup',
		finishSetupLater: 'promCreatedOptions.finishSetupLater',
		definePromForm: 'promCreatedOptions.definePromForm',
		title: 'promCreatedOptions.title'
	},
	importDropzone: {
		stopConversionOrUnmount: 'importDropzone.stopConversionOrUnmount',
		dropFilesUploadReplace: 'importDropzone.dropFilesUploadReplace',
		dropFilesUpload: 'importDropzone.dropFilesUpload',
		selectedFile: 'importDropzone.selectedFile',
		message: 'importDropzone.message'
	},
	dragAndDropFile: {
		uploadFile: 'dragAndDropFile.uploadFile',
		abortedFileReading: 'dragAndDropFile.abortedFileReading',
		failedFileReading: 'dragAndDropFile.failedFileReading',
		errorTooLarge: 'dragAndDropFile.errorTooLarge',
		errorNotSupportedType: 'dragAndDropFile.errorNotSupportedType',
		tooManyFiles: 'dragAndDropFile.tooManyFiles',
		fileUploadSuccessful: 'dragAndDropFile.fileUploadSuccessful',
		modalTitle: 'dragAndDropFile.modalTitle'
	},
	variableLabels: {
		category: 'variableLabels.category',
		categoryMultiple: 'variableLabels.categoryMultiple',
		integer: 'variableLabels.integer',
		float: 'variableLabels.float',
		date: 'variableLabels.date',
		// We have to keep `datetime` lowercased to match `variable.type` value
		datetime: 'variableLabels.dateTime',
		string: 'variableLabels.string',
		file: 'variableLabels.file',
		status: 'variableLabels.status',
		userDefinedUnique: 'variableLabels.userDefinedUnique',
		timeDuration: 'variableLabels.timeDuration',
		allTypes: 'variableLabels.allTypes'
	},
	variableDependencies: {
		categoryNotSelected: 'variableDependencies.categoryNotSelected',
		noDependenciesText: 'variableDependencies.noDependenciesText',
		noDependencies: 'variableDependencies.noDependencies',
		createCategoryVariables: 'variableDependencies.createCategoryVariables'
	},
	dateLabels: {
		years: 'dateLabels.years',
		months: 'dateLabels.months',
		weeks: 'dateLabels.weeks',
		days: 'dateLabels.days',
		hours: 'dateLabels.hours',
		minutes: 'dateLabels.minutes',
		seconds: 'dateLabels.seconds'
	},
	timeDurationPlaceholder: {
		full: {
			weeks: 'timeDurationPlaceholder.full.weeks',
			days: 'timeDurationPlaceholder.full.days',
			hours: 'timeDurationPlaceholder.full.hours',
			minutes: 'timeDurationPlaceholder.full.minutes',
			seconds: 'timeDurationPlaceholder.full.seconds',
			milliseconds: 'timeDurationPlaceholder.full.milliseconds',
			microseconds: 'timeDurationPlaceholder.full.microseconds'
		},
		short: {
			weeks: 'timeDurationPlaceholder.short.weeks',
			days: 'timeDurationPlaceholder.short.days',
			hours: 'timeDurationPlaceholder.short.hours',
			minutes: 'timeDurationPlaceholder.short.minutes',
			seconds: 'timeDurationPlaceholder.short.seconds',
			milliseconds: 'timeDurationPlaceholder.short.milliseconds',
			microseconds: 'timeDurationPlaceholder.short.microseconds'
		},
		prefix: {
			weeks: 'timeDurationPlaceholder.prefix.weeks',
			days: 'timeDurationPlaceholder.prefix.days',
			hours: 'timeDurationPlaceholder.prefix.hours',
			minutes: 'timeDurationPlaceholder.prefix.minutes',
			seconds: 'timeDurationPlaceholder.prefix.seconds',
			milliseconds: 'timeDurationPlaceholder.prefix.milliseconds',
			microseconds: 'timeDurationPlaceholder.prefix.microseconds'
		}
	},
	variableEntryLabels: {
		entry: 'variableEntryLabels.entry',
		calculated: 'variableEntryLabels.calculated',
		status: 'variableEntryLabels.status'
	},
	import: {
		stepInfo: 'import.stepInfo',
		dataset: {
			title: 'import.dataset.title'
		},
		previewData: {
			title: 'import.previewData.title'
		},
		importReview: {
			title: 'import.importReview.title',
			infoMessage: 'import.importReview.infoMessage',
			cell: 'import.importReview.cell',
			cells: 'import.importReview.cells',
			column: 'import.importReview.column',
			columns: 'import.importReview.columns',
			previousVariable: 'import.importReview.previousVariable',
			nextVariable: 'import.importReview.nextVariable',
			numberOfErrors: 'import.importReview.numberOfErrors',
			error: 'import.importReview.error',
			errors: 'import.importReview.errors',
			tableHeaders: {
				couldNotParseValue: 'import.importReview.tableHeaders.couldNotParseValue',
				duplicateValuesForUniqueVariable:
					'import.importReview.tableHeaders.duplicateValuesForUniqueVariable',
				missingValueForUniqueVariable:
					'import.importReview.tableHeaders.missingValueForUniqueVariable',
				notAllowedFixedCategoryValue:
					'import.importReview.tableHeaders.notAllowedFixedCategoryValue',
				notAllowedToSetValueForUniqueVariable:
					'import.importReview.tableHeaders.notAllowedToSetValueForUniqueVariable',
				row: 'import.importReview.tableHeaders.row',
				celCoordinates: 'import.importReview.tableHeaders.celCoordinates',
				unrecognizedValue: 'import.importReview.tableHeaders.unrecognizedValue',
				value: 'import.importReview.tableHeaders.value',
				duplicateValue: 'import.importReview.tableHeaders.duplicateValue',
				nonExistentDatetime: 'import.importReview.tableHeaders.nonExistentDatetime',
				datetimeExistsTwice: 'import.importReview.tableHeaders.datetimeExistsTwice',
				duplicateMatchingEntry: 'import.importReview.tableHeaders.duplicateMatchingEntry',
				noMatchingEntry: 'import.importReview.tableHeaders.noMatchingEntry',
				duplicateMatchingValue: 'import.importReview.tableHeaders.duplicateMatchingValue'
			},
			fixOption: {
				title: 'import.importReview.fixOption.title',
				columnName: 'import.importReview.fixOption.columnName',
				variableType: 'import.importReview.fixOption.variableType'
			},
			buttons: {
				continueWithMapping: 'import.importReview.buttons.continueWithMapping',
				reuploadWithChanges: 'import.importReview.buttons.reuploadWithChanges'
			}
		}
	},
	importDataset: {
		importDataset: 'importDataset.importDataset',
		importErrors: {
			row: 'importDataset.importErrors.row',
			errorDescriptionColumn: 'importDataset.importErrors.errorDescriptionColumn',
			errorValueTemplate: 'importDataset.importErrors.errorValueTemplate',
			errorDescription: 'importDataset.importErrors.errorDescription',
			fileNotSupported: 'importDataset.importErrors.fileNotSupported'
		}
	},
	importVariableSet: {
		chooseVariableSet: 'importVariableSet.chooseVariableSet',
		newVariableSet: 'importVariableSet.newVariableSet',
		variableSetName: 'importVariableSet.variableSetName',
		existingVariableSet: 'importVariableSet.existingVariableSet',
		selectExistingVariableSet: 'importVariableSet.selectExistingVariableSet',
		editVariableSetDrawer: {
			importVariableSetTitle:
				'importVariableSet.editVariableSetDrawer.importVariableSetTitle',
			importVariableSetDescription:
				'importVariableSet.editVariableSetDrawer.importVariableSetDescription',
			importVariableSetButton:
				'importVariableSet.editVariableSetDrawer.importVariableSetButton'
		}
	},
	editProject: {
		modalTitle: 'editProject.modalTitle',
		title: 'editProject.title',
		description: 'editProject.description',
		number: 'editProject.number',
		endDate: 'editProject.endDate',
		status: {
			label: 'editProject.status.label',
			values: {
				ongoing: 'editProject.status.values.ongoing',
				onHold: 'editProject.status.values.onHold',
				ended: 'editProject.status.values.ended'
			}
		},
		delete: 'editProject.delete'
	},
	editProm: {
		modalTitle: 'editProm.modalTitle',
		title: 'editProm.title',
		description: 'editProm.description',
		number: 'editProm.number',
		endDate: 'editProm.endDate',
		status: {
			label: 'editProm.status.label',
			values: {
				ongoing: 'editProm.status.values.ongoing',
				onHold: 'editProm.status.values.onHold',
				ended: 'editProm.status.values.ended'
			}
		},
		delete: 'editProm.delete'
	},
	leaveProject: {
		leaveProject: 'leaveProject.leaveProject',
		warningText: 'leaveProject.warningText',
		warningQuestion: 'leaveProject.warningQuestion',
		projectLeftSuccessfully: 'leaveProject.projectLeftSuccessfully'
	},
	variables: {
		labelRequired: 'variables.labelRequired',
		forbiddenSemiColon: 'variables.forbiddenSemiColon',
		labelErrorInVariables: 'variables.labelErrorInVariables',
		nameRequired: 'variables.nameRequired',
		fixedValuesRequired: 'variables.fixedValuesRequired',
		duplicateGroupName: 'variables.duplicateGroupName',
		groupNameRequired: 'variables.groupNameRequired',
		variablesSavedNotification: 'variables.variablesSavedNotification',
		notDragged: 'variables.notDragged',
		noMoveDetected: 'variables.noMoveDetected',
		checkErrors: 'variables.checkErrors',
		variableNameError: 'variables.variableNameError',
		groupNameError: 'variables.groupNameError',
		variablesForGroup: 'variables.variablesForGroup',
		variableStandalone: 'variables.variableStandalone',
		expandGroups: 'variables.expandGroups',
		collapseGroups: 'variables.collapseGroups',
		changeTypeNotAllowed: 'variables.changeTypeNotAllowed',
		variablesUpdatingTitle: 'variables.variablesUpdatingTitle',
		variablesUpdating: 'variables.variablesUpdating',
		header: {
			clearFilters: {
				message: 'variables.header.clearFilters.message',
				button: 'variables.header.clearFilters.button'
			}
		},
		typeToTimeDurationWarning: 'variables.typeToTimeDurationWarning',
		confirmTypeChangeModal: {
			type: 'variables.confirmTypeChangeModal.type',
			phrase: 'variables.confirmTypeChangeModal.phrase',
			toContinue: 'variables.confirmTypeChangeModal.toContinue'
		},
		minTimeUnitWarning: 'variables.minTimeUnitWarning',
		dataTypeConversion: 'variables.dataTypeConversion',
		sourceUnit: 'variables.sourceUnit',
		timeFormat: 'variables.timeFormat',
		systemGeneratedVariables: {
			enteredbyuserwithname: 'variables.systemGeneratedVariables.enteredbyuserwithname',
			ownedbyuserwithname: 'variables.systemGeneratedVariables.ownedbyuserwithname',
			userProjectOrgId: 'variables.systemGeneratedVariables.userProjectOrgId',
			creationdate: 'variables.systemGeneratedVariables.creationdate',
			lastmodifieddate: 'variables.systemGeneratedVariables.lastmodifieddate',
			datasetentryid: 'variables.systemGeneratedVariables.datasetentryid',
			enteredbyuser: 'variables.systemGeneratedVariables.enteredbyuser',
			ownedbyuser: 'variables.systemGeneratedVariables.ownedbyuser'
		},
		forbiddenTypeInSeries: 'variables.forbiddenTypeInSeries',
		move: {
			title: 'variables.move.title',
			newGroupLabel: 'variables.move.newGroupLabel',
			newGroupPlaceholder: 'variables.move.newGroupPlaceholder',
			option: 'variables.move.option',
			position: 'variables.move.position',
			createNewSeries: 'variables.move.createNewSeries',
			createNewGroup: 'variables.move.createNewGroup',
			createNewGroupPlaceholder: 'variables.move.createNewGroupPlaceholder',
			createNewSeriesPlaceholder: 'variables.move.createNewSeriesPlaceholder',
			noSeries: 'variables.move.noSeries',
			noGroups: 'variables.move.noGroups',
			newSeriesPlaceholder: 'variables.move.newSeriesPlaceholder',
			newSeriesLabel: 'variables.move.newSeriesLabel',
			error: 'variables.move.error',
			groupWithinSeries: 'variables.move.groupWithinSeries',
			selectDataLabel: 'variables.move.selectDataLabel',
			selectDataPlaceholder: 'variables.move.selectDataPlaceholder',
			emptyGroup: 'variables.move.emptyGroup',
			emptyGroupAndForbiddenType: 'variables.move.emptyGroupAndForbiddenType',
			someElementsNotMoved: 'variables.move.someElementsNotMoved',
			mainLevel: 'variables.move.mainLevel',
			series: 'variables.move.series',
			group: 'variables.move.group'
		},
		timeKeys: {
			years: 'variables.timeKeys.years',
			months: 'variables.timeKeys.months',
			weeks: 'variables.timeKeys.weeks',
			days: 'variables.timeKeys.days',
			hours: 'variables.timeKeys.hours',
			minutes: 'variables.timeKeys.minutes',
			seconds: 'variables.timeKeys.seconds',
			milliseconds: 'variables.timeKeys.milliseconds',
			microseconds: 'variables.timeKeys.microseconds'
		}
	},
	statuses: {
		pageTitle: 'statuses.pageTitle',
		addNew: {
			status: 'statuses.addNew.status',
			fromCategoryVariable: 'statuses.addNew.fromCategoryVariable'
		},
		searchStatus: 'statuses.searchStatus',
		statusName: 'statuses.statusName',
		statusDescription: 'statuses.statusDescription',
		dueTimeAmount: 'statuses.dueTimeAmount',
		dueTimeUnit: 'statuses.dueTimeUnit',
		statusModal: {
			comment: 'statuses.statusModal.comment',
			addTitle: 'statuses.statusModal.addTitle',
			updateTitle: 'statuses.statusModal.updateTitle',
			deleteStatus: 'statuses.statusModal.deleteStatus',
			labelError: 'statuses.statusModal.labelError',
			uniqueLabelError: 'statuses.statusModal.uniqueLabelError',
			dueTimeUnitError: 'statuses.statusModal.dueTimeUnitError',
			dueTimeAmountError: 'statuses.statusModal.dueTimeAmountError',
			deleteConfimation: 'statuses.statusModal.deleteConfimation',
			isDefault: 'statuses.statusModal.isDefault',
			updateExistingEntries: {
				label: 'statuses.statusModal.updateExistingEntries.label',
				description: 'statuses.statusModal.updateExistingEntries.description'
			}
		},
		statusTable: {
			name: 'statuses.statusTable.name',
			description: 'statuses.statusTable.description',
			dueTime: 'statuses.statusTable.dueTime',
			isDefault: 'statuses.statusTable.isDefault',
			color: 'statuses.statusTable.color'
		},
		statusEntryComponent: {
			status: 'statuses.statusEntryComponent.status',
			statusDueTime: 'statuses.statusEntryComponent.statusDueTime',
			statusTime: 'statuses.statusEntryComponent.statusTime'
		},
		emptyStatuses: {
			noStatuses: 'statuses.emptyStatuses.noStatuses',
			noStatusesDescription: 'statuses.emptyStatuses.noStatusesDescription',
			createNew: {
				status: 'statuses.emptyStatuses.createNew.status',
				fromCategoryVariable: 'statuses.emptyStatuses.createNew.fromCategoryVariable'
			}
		},
		createFromCategoriesModal: {
			title: 'statuses.createFromCategoriesModal.title',
			fields: {
				categoryVariable: {
					label: 'statuses.createFromCategoriesModal.fields.categoryVariable.label',
					placeholder:
						'statuses.createFromCategoriesModal.fields.categoryVariable.placeholder'
				},
				categoryValues: {
					label: 'statuses.createFromCategoriesModal.fields.categoryValues.label',
					title: 'statuses.createFromCategoriesModal.fields.categoryValues.title'
				},
				previewSelected: {
					label: 'statuses.createFromCategoriesModal.fields.previewSelected.label',
					hint: 'statuses.createFromCategoriesModal.fields.previewSelected.hint'
				}
			},
			errors: {
				statusAlreadyExists: 'statuses.createFromCategoriesModal.errors.statusAlreadyExists'
			}
		}
	},
	formDesigner: {
		form: 'formDesigner.form',
		formModules: 'formDesigner.formModules',
		formElements: 'formDesigner.formElements',
		formTitle: 'formDesigner.formTitle',
		formTitlePlaceholder: 'formDesigner.formTitlePlaceholder',
		formSubtitlePlaceholder: 'formDesigner.formSubtitlePlaceholder',
		formTextPlaceholder: 'formDesigner.formTextPlaceholder',
		formVariablePlaceholder: 'formDesigner.formVariablePlaceholder',
		formGroupPlaceholder: 'formDesigner.formGroupPlaceholder',
		formSetPlaceholder: 'formDesigner.formSetPlaceholder',
		makeFormActive: 'formDesigner.makeFormActive',
		variables: 'formDesigner.variables',
		promVariables: 'formDesigner.promVariables',
		dropZoneMessage: 'formDesigner.dropZoneMessage',
		update: 'formDesigner.update',
		searchVariable: 'formDesigner.searchVariable',
		noVariableFound: 'formDesigner.noVariableFound',
		noVariablesInList: 'formDesigner.noVariablesInList',
		activateFormTooltip: 'formDesigner.activateFormTooltip',
		createNewForm: 'formDesigner.createNewForm',
		createForm: 'formDesigner.createForm',
		createFormBasedOnSeries: 'formDesigner.createFormBasedOnSeries',
		updateForm: 'formDesigner.updateForm',
		noForms: 'formDesigner.noForms',
		usedIn: 'formDesigner.usedIn',
		collapsed: 'formDesigner.collapsed',
		createFirstForm: 'formDesigner.createFirstForm',
		renameYourForm: 'formDesigner.renameYourForm',
		nameYourForm: 'formDesigner.nameYourForm',
		formNamePlaceholder: 'formDesigner.formNamePlaceholder',
		splitToSeparateRows: 'formDesigner.splitToSeparateRows',
		active: 'formDesigner.active',
		inactive: 'formDesigner.inactive',
		activateForm: 'formDesigner.activateForm',
		deActivateForm: 'formDesigner.deActivateForm',
		mainSection: 'formDesigner.mainSection',
		searchForms: 'formDesigner.searchForms',
		editing: 'formDesigner.editing',
		noExistingForm: 'formDesigner.noExistingForm',
		showOptions: {
			all: 'formDesigner.showOptions.all',
			main: 'formDesigner.showOptions.main',
			series: 'formDesigner.showOptions.series'
		},
		variableOptionsDrawer: {
			title: 'formDesigner.variableOptionsDrawer.title',
			variableLabel: 'formDesigner.variableOptionsDrawer.variableLabel',
			formVariableLabel: 'formDesigner.variableOptionsDrawer.formVariableLabel',
			fieldType: 'formDesigner.variableOptionsDrawer.fieldType',
			displayAs: 'formDesigner.variableOptionsDrawer.displayAs',
			scaleInterval: 'formDesigner.variableOptionsDrawer.scaleInterval',
			displaySelectedValue: 'formDesigner.variableOptionsDrawer.displaySelectedValue',
			mapValuesWithMoods: 'formDesigner.variableOptionsDrawer.mapValuesWithMoods',
			minimumValue: 'formDesigner.variableOptionsDrawer.minimumValue',
			maximumValue: 'formDesigner.variableOptionsDrawer.maximumValue',
			value: 'formDesigner.variableOptionsDrawer.value',
			infoMessage: 'formDesigner.variableOptionsDrawer.infoMessage'
		},
		showValues: 'formDesigner.showValues',
		showLabels: 'formDesigner.showLabels'
	},
	deleteForm: {
		deleteForm: 'deleteForm.deleteForm',
		warningText: 'deleteForm.warningText',
		warningQuestion: 'deleteForm.warningQuestion',
		formDeletedSuccessfully: 'deleteForm.formDeletedSuccessfully'
	},
	templatesHeader: {
		myTemplates: 'templatesHeader.myTemplates',
		otherTemplates: 'templatesHeader.otherTemplates'
	},
	templates: {
		deleteTemplatePrompt: 'templates.deleteTemplatePrompt',
		deleteTemplateHint: 'templates.deleteTemplateHint',
		deleteTemplate: 'templates.deleteTemplate',
		templates: 'templates.templates',
		variablesAndGroups: 'templates.variablesAndGroups',
		dropZoneMessage: 'templates.dropZoneMessage',
		emptyTemplateDropZoneMessage: 'templates.emptyTemplateDropZoneMessage',
		noTemplatesMessage: 'templates.noTemplatesMessage',
		noTemplatesToDisplay: 'templates.noTemplatesToDisplay',
		createYourFirstTemplate: 'templates.createYourFirstTemplate',
		makeATemplateFromAll: 'templates.makeATemplateFromAll',
		createNewTemplate: 'templates.createNewTemplate',
		createNewTemplateRole: 'templates.createNewTemplateRole',
		shareWithUsers: 'templates.shareWithUsers',
		shareWithProjects: 'templates.shareWithProjects',
		globally: 'templates.globally',
		notGlobally: 'templates.notGlobally',
		projects: 'templates.projects',
		specificUsers: 'templates.specificUsers',
		templateNameError: 'templates.templateNameError',
		dropNotAllowed: 'templates.dropNotAllowed',
		dropzone: 'templates.dropzone',
		description: 'templates.description',
		noDescription: 'templates.noDescription',
		descriptionPlaceholder: 'templates.descriptionPlaceholder',
		searchTemplate: 'templates.searchTemplate',
		public: 'templates.public',
		sharedWithMe: 'templates.sharedWithMe',
		sharedWithProject: 'templates.sharedWithProject',
		emptyTemplateFilter: 'templates.emptyTemplateFilter',
		save: 'templates.save',
		share: 'templates.share',
		templateDeleted: 'templates.templateDeleted',
		usersList: 'templates.usersList',
		projectsList: 'templates.projectsList',
		templateShareSettingsUpdated: 'templates.templateShareSettingsUpdated',
		templateDataImported: 'templates.templateDataImported',
		importTemplateTitle: 'templates.importTemplateTitle',
		primaryWarningText: 'templates.primaryWarningText',
		importTemplateConfirmationQuestion: 'templates.importTemplateConfirmationQuestion',
		sharedGloballyTooltipMessage: 'templates.sharedGloballyTooltipMessage',
		unsupportedErrorMessage: 'templates.unsupportedErrorMessage',
		templateSaved: 'templates.templateSaved',
		templateUpdated: 'templates.templateUpdated',
		searchFor: 'templates.searchFor',
		selectOrSearch: 'templates.selectOrSearch'
	},
	addVariable: {
		createVariable: 'addVariable.createVariable',
		addVariableLabel: 'addVariable.addVariableLabel',
		addVariablesText: 'addVariable.addVariablesText',
		title: 'addVariable.title',
		compositeVariable: {
			title: 'addVariable.compositeVariable.title',
			info: 'addVariable.compositeVariable.info',
			validate: 'addVariable.compositeVariable.validate',
			calcSummaryTitle: 'addVariable.compositeVariable.calcSummaryTitle',
			calcSummaryAdd: 'addVariable.compositeVariable.calcSummaryAdd',

			addRule: 'addVariable.compositeVariable.addRule',

			aliasTitle: 'addVariable.compositeVariable.aliasTitle',
			editorTitle: 'addVariable.compositeVariable.editorTitle',
			invalidFormula: 'addVariable.compositeVariable.invalidFormula',
			validFormula: 'addVariable.compositeVariable.validFormula',
			formulaPlaceholder: 'addVariable.compositeVariable.formulaPlaceholder',
			alias: 'addVariable.compositeVariable.alias',
			addMapping: 'addVariable.compositeVariable.addMapping',
			variables: 'addVariable.compositeVariable.variables',
			infoMessage: 'addVariable.compositeVariable.infoMessage',
			aliasPlaceholder: 'addVariable.compositeVariable.aliasPlaceholder',
			aliasNotAllowed: 'addVariable.compositeVariable.aliasNotAllowed',
			aliasDuplicate: 'addVariable.compositeVariable.aliasDuplicate',
			variableAllreadyMapped: 'addVariable.compositeVariable.variableAllreadyMapped',
			chooseVariable: 'addVariable.compositeVariable.chooseVariable',
			aliasNumberError: 'addVariable.compositeVariable.aliasNumberError'
		}
	},
	editVariable: {
		title: 'editVariable.title'
	},
	variableFields: {
		variableName: 'variableFields.variableName',
		variableLabel: 'variableFields.variableLabel',
		subType: 'variableFields.subType',
		dataType: 'variableFields.dataType',
		entryType: 'variableFields.entryType',
		fixedValues: 'variableFields.fixedValues',
		fixedValuesInfo: 'variableFields.fixedValuesInfo',
		optimizeForManyValues: {
			label: 'variableFields.optimizeForManyValues.label',
			description: 'variableFields.optimizeForManyValues.description',
			warning: 'variableFields.optimizeForManyValues.warning'
		},
		obligatory: 'variableFields.obligatory',
		personalData: 'variableFields.personalData',
		validationRange: 'variableFields.validationRange',
		minValidationLabel: 'variableFields.minValidationLabel',
		maxValidationLabel: 'variableFields.maxValidationLabel',
		obligatoryInfo: 'variableFields.obligatoryInfo',
		obligatoryChecked: 'variableFields.obligatoryChecked',
		personalDataInfo: 'variableFields.personalDataInfo',
		fixedValuesInputPlaceholder: 'variableFields.fixedValuesInputPlaceholder',
		uniquenessConfig: {
			manual: {
				label: 'variableFields.uniquenessConfig.manual.label',
				description: 'variableFields.uniquenessConfig.manual.description'
			},
			sequence: {
				label: 'variableFields.uniquenessConfig.sequence.label',
				description: 'variableFields.uniquenessConfig.sequence.description'
			},
			uuid: {
				label: 'variableFields.uniquenessConfig.uuid.label',
				description: 'variableFields.uniquenessConfig.uuid.description'
			}
		},
		description: 'variableFields.description',
		noDescription: 'variableFields.noDescription',
		calculated: 'variableFields.calculated',
		calculationFormula: {
			conditions: {
				if: 'variableFields.calculationFormula.conditions.if',
				thenValueIs: 'variableFields.calculationFormula.conditions.thenValueIs',
				and: 'variableFields.calculationFormula.conditions.and'
			},
			operations: {
				addedTo: 'variableFields.calculationFormula.operations.addedTo',
				differenceAs: 'variableFields.calculationFormula.operations.differenceAs'
			},
			conditionLabels: {
				greaterThan: 'variableFields.calculationFormula.conditionLabels.greaterThan',
				greaterThanOrEqual:
					'variableFields.calculationFormula.conditionLabels.greaterThanOrEqual',
				equals: 'variableFields.calculationFormula.conditionLabels.equals',
				lessThan: 'variableFields.calculationFormula.conditionLabels.lessThan',
				lessThanOrEqual: 'variableFields.calculationFormula.conditionLabels.lessThanOrEqual'
			}
		}
	},
	promptToSave: {
		title: 'promptToSave.title',
		unsavedChanges: 'promptToSave.unsavedChanges'
	},
	promptToDelete: {
		title: 'promptToDelete.title',
		description: {
			default: 'promptToDelete.description.default',
			project: 'promptToDelete.description.project',
			prom: 'promptToDelete.description.prom',
			variable: 'promptToDelete.description.variable',
			entry: 'promptToDelete.description.entry',
			patient: 'promptToDelete.description.patient',
			user: 'promptToDelete.description.user',
			specificUser: 'promptToDelete.description.specificUser',
			userLicence: 'promptToDelete.description.userLicence',
			undoneWarning: 'promptToDelete.description.undoneWarning',
			group: 'promptToDelete.description.group'
		}
	},
	promptToWaitingTimes: {
		title: 'promptToWaitingTimes.title',
		description: 'promptToWaitingTimes.description'
	},

	promptToOverwrite: {
		title: 'promptToOverwrite.title'
	},

	promptComposite: {
		title: 'promptComposite.title',
		description: 'promptComposite.description'
	},
	variableList: {
		noVariables: 'variableList.noVariables',
		addVariable: 'variableList.addVariable',
		noVariablesEditingRights: 'variableList.noVariablesEditingRights',
		variables: 'variableList.variables'
	},
	draggableVariable: {
		removeFromGroup: 'draggableVariable.removeFromGroup',
		addToGroup: 'draggableVariable.addToGroup',
		moveToGroup: 'draggableVariable.moveToGroup'
	},
	variableGroups: {
		noGroups: 'variableGroups.noGroups',
		noGroupsYet: 'variableGroups.noGroupsYet',
		createAGroup: 'variableGroups.createAGroup',
		variableGroups: 'variableGroups.variableGroups',
		addVariableGroup: 'variableGroups.addVariableGroup'
	},
	varGroup: {
		variable: 'varGroup.variable',
		variables: 'varGroup.variables',
		removeGroup: 'varGroup.removeGroup',
		dragAndDropVariables: 'varGroup.dragAndDropVariables',
		createNewVariable: 'varGroup.createNewVariable',
		importVariables: 'varGroup.importVariables',
		noVariablesInsideGroup: 'varGroup.noVariablesInsideGroup'
	},
	dataset: {
		noVariables: 'dataset.noVariables',
		noEntries: 'dataset.noEntries',
		letsCreate: 'dataset.letsCreate',
		noEntriesDescription: 'dataset.noEntriesDescription',
		historyChanges: 'dataset.historyChanges',
		pageHeader: {
			title: 'dataset.pageHeader.title'
		},
		addEntryForm: {
			viewTip: 'dataset.addEntryForm.viewTip',
			viewOnly: 'dataset.addEntryForm.viewOnly',
			deleteTip: 'dataset.addEntryForm.deleteTip',
			forms: 'dataset.addEntryForm.forms',
			hide: 'dataset.addEntryForm.hide',
			show: 'dataset.addEntryForm.show',
			saveDraft: 'dataset.addEntryForm.saveDraft',
			saveAsDraft: 'dataset.addEntryForm.saveAsDraft',
			saveAndClose: 'dataset.addEntryForm.saveAndClose'
		},
		addEditInputSet: {
			entry: 'dataset.addEditInputSet.entry',
			entries: 'dataset.addEditInputSet.entries',
			pleaseSave: 'dataset.addEditInputSet.pleaseSave',
			loadingData: 'dataset.addEditInputSet.loadingData',
			addNewEntry: 'dataset.addEditInputSet.addNewEntry'
		},
		addEditStatusInputs: {
			noStatusSelected: 'dataset.addEditStatusInputs.noStatusSelected'
		},
		detailsView: {
			datasetEntryId: 'dataset.detailsView.datasetEntryId',
			owner: 'dataset.detailsView.owner',
			updatedBy: 'dataset.detailsView.updatedBy',
			created: 'dataset.detailsView.created',
			updated: 'dataset.detailsView.updated',
			group: 'dataset.detailsView.group'
		},
		revisionCard: {
			created: 'dataset.revisionCard.created',
			edited: 'dataset.revisionCard.edited',
			entry: 'dataset.revisionCard.entry',
			changed: 'dataset.revisionCard.changed',
			dataEntry: 'dataset.revisionCard.dataEntry',
			renewedStatus: 'dataset.revisionCard.renewedStatus',
			changedStatusFrom: 'dataset.revisionCard.changedStatusFrom',
			to: 'dataset.revisionCard.to',
			removedStatus: 'dataset.revisionCard.removedStatus',
			setStatus: 'dataset.revisionCard.setStatus',
			currentVersion: 'dataset.revisionCard.currentVersion'
		},
		statusView: {
			noStatusSelected: 'dataset.statusView.noStatusSelected'
		},
		entryDrawer: {
			entryDetails: 'dataset.entryDrawer.entryDetails',
			entryHistory: 'dataset.entryDrawer.entryHistory',
			status: 'dataset.entryDrawer.status'
		},
		aggregationRulesTable: {
			allAggregations: 'dataset.aggregationRulesTable.allAggregations',
			aggregationVariable: 'dataset.aggregationRulesTable.aggregationVariable',
			aggregationFcuntion: 'dataset.aggregationRulesTable.aggregationFcuntion',
			result: 'dataset.aggregationRulesTable.result'
		},
		seriesEntryForm: {
			entry: 'dataset.seriesEntryForm.entry',
			backToEntry: 'dataset.seriesEntryForm.backToEntry',
			dataTip: 'dataset.seriesEntryForm.dataTip',
			viewOnly: 'dataset.seriesEntryForm.viewOnly',
			deleteEntry: 'dataset.seriesEntryForm.deleteEntry',
			deleteEntryConfirmationTarget: 'dataset.seriesEntryForm.deleteEntryConfirmationTarget',
			deleteEntryConfirmationLabel: 'dataset.seriesEntryForm.deleteEntryConfirmationLabel',
			hide: 'dataset.seriesEntryForm.hide',
			show: 'dataset.seriesEntryForm.show',
			forms: 'dataset.seriesEntryForm.forms',
			formsDrawer: 'dataset.seriesEntryForm.formsDrawer',
			addNewEntryInSeries: 'dataset.seriesEntryForm.addNewEntryInSeries'
		},
		dependenciesMapChecker: {
			buildInitial: 'dataset.dependenciesMapChecker.buildInitial',
			revisionChanged: 'dataset.dependenciesMapChecker.revisionChanged',
			entryChanged: 'dataset.dependenciesMapChecker.entryChanged',
			subEntryChanged: 'dataset.dependenciesMapChecker.subEntryChanged',
			fieldChangeListener: 'dataset.dependenciesMapChecker.fieldChangeListener',
			entryDraftApplied: 'dataset.dependenciesMapChecker.entryDraftApplied'
		},
		entries: {
			transferOwnership: {
				searchCollaborator: 'dataset.entries.transferOwnership.searchCollaborator',
				pageTitle: 'dataset.entries.transferOwnership.pageTitle',
				selected: 'dataset.entries.transferOwnership.selected',
				successMessage: 'dataset.entries.transferOwnership.successMessage',
				moreMenu: {
					item: 'dataset.entries.transferOwnership.moreMenu.item'
				},
				modal: {
					title: 'dataset.entries.transferOwnership.modal.title',
					fields: {
						subtitle: 'dataset.entries.transferOwnership.modal.fields.subtitle',
						entriesCount: 'dataset.entries.transferOwnership.modal.fields.entriesCount',
						infoMessage: {
							message:
								'dataset.entries.transferOwnership.modal.fields.infoMessage.message',
							link: 'dataset.entries.transferOwnership.modal.fields.infoMessage.link'
						},
						group: 'dataset.entries.transferOwnership.modal.fields.group',
						options: 'dataset.entries.transferOwnership.modal.fields.options',
						newGroup: 'dataset.entries.transferOwnership.modal.fields.newGroup',
						collaborator: 'dataset.entries.transferOwnership.modal.fields.collaborator',
						newOwner: 'dataset.entries.transferOwnership.modal.fields.newOwner',
						transferGroupLevelOwnership:
							'dataset.entries.transferOwnership.modal.fields.transferGroupLevelOwnership',
						groupPart: 'dataset.entries.transferOwnership.modal.fields.groupPart',
						changeGroup: 'dataset.entries.transferOwnership.modal.fields.changeGroup',
						changeGroupAndOwnership:
							'dataset.entries.transferOwnership.modal.fields.changeGroupAndOwnership',
						changeOwnership:
							'dataset.entries.transferOwnership.modal.fields.changeOwnership'
					},
					noGroup: 'dataset.entries.transferOwnership.modal.noGroup',
					confirmationText: 'dataset.entries.transferOwnership.modal.confirmationText'
				},
				cancelModal: {
					title: 'dataset.entries.transferOwnership.cancelModal.title',
					text: 'dataset.entries.transferOwnership.cancelModal.text'
				},
				previewModal: {
					title: 'dataset.entries.transferOwnership.previewModal.title',
					text: 'dataset.entries.transferOwnership.previewModal.text'
				}
			},
			columnSettings: {
				columnSettings: 'dataset.entries.columnSettings.columnSettings',
				search: 'dataset.entries.columnSettings.search',
				checkUncheck: 'dataset.entries.columnSettings.checkUncheck',
				noResults: 'dataset.entries.columnSettings.noResults'
			},
			entriesTable: {
				warningMessage: 'dataset.entries.entriesTable.warningMessage',
				errorRows: 'dataset.entries.entriesTable.errorRows',
				displayOnlyRows: 'dataset.entries.entriesTable.displayOnlyRows',
				errorColumns: 'dataset.entries.entriesTable.errorColumns',
				displayOnlyColumns: 'dataset.entries.entriesTable.displayOnlyColumns'
			}
		},
		modals: {
			datasetEntriesPreviewModal: {
				singular: 'dataset.modals.datasetEntriesPreviewModal.singular',
				plural: 'dataset.modals.datasetEntriesPreviewModal.plural'
			},
			exportEntriesAsCSVModal: {
				message: 'dataset.modals.exportEntriesAsCSVModal.message',
				excludeLineshifts: 'dataset.modals.exportEntriesAsCSVModal.excludeLineshifts',
				excluded: 'dataset.modals.exportEntriesAsCSVModal.excluded',
				included: 'dataset.modals.exportEntriesAsCSVModal.included',
				categoriesLabel: 'dataset.modals.exportEntriesAsCSVModal.categoriesLabel',
				labelsHint: 'dataset.modals.exportEntriesAsCSVModal.labelsHint',
				valuesHint: 'dataset.modals.exportEntriesAsCSVModal.valuesHint'
			},
			viewPermissionModal: {
				title: 'dataset.modals.viewPermissionModal.title',
				label: 'dataset.modals.viewPermissionModal.label',
				message: 'dataset.modals.viewPermissionModal.message'
			},
			assingGroupModal: {
				title: 'dataset.modals.assingGroupModal.title',
				text: 'dataset.modals.assingGroupModal.text',
				placeholder: 'dataset.modals.assingGroupModal.placeholder'
			},
			resolveConflictsModal: {
				title: 'dataset.modals.resolveConflictsModal.title',
				conflicts: 'dataset.modals.resolveConflictsModal.conflicts',
				conflict: 'dataset.modals.resolveConflictsModal.conflict',
				info: 'dataset.modals.resolveConflictsModal.info',
				keep: {
					title: 'dataset.modals.resolveConflictsModal.keep.title',
					infoMessage: 'dataset.modals.resolveConflictsModal.keep.infoMessage'
				},
				take: {
					title: 'dataset.modals.resolveConflictsModal.take.title',
					infoMessage: 'dataset.modals.resolveConflictsModal.take.infoMessage'
				},
				fields: 'dataset.modals.resolveConflictsModal.fields',
				yourVersion: 'dataset.modals.resolveConflictsModal.yourVersion',
				otherVersion: 'dataset.modals.resolveConflictsModal.otherVersion',
				showing: 'dataset.modals.resolveConflictsModal.showing',
				conflictsValues: 'dataset.modals.resolveConflictsModal.conflictsValues'
			}
		}
	},

	seriesTable: {
		last: 'seriesTable.last',
		show: 'seriesTable.show',
		showing: 'seriesTable.showing',
		entry: 'seriesTable.entry',
		entries: 'seriesTable.entries',
		empty: 'seriesTable.empty',
		emptyState: {
			noEntries: 'seriesTable.emptyState.noEntries',
			createFirstEntry: 'seriesTable.emptyState.createFirstEntry'
		},
		seriesHeader: {
			searchEntries: 'seriesTable.seriesHeader.searchEntries',
			createNewEntry: 'seriesTable.seriesHeader.createNewEntry',
			updateEntry: 'seriesTable.seriesHeader.updateEntry',
			gridView: 'seriesTable.seriesHeader.gridView',
			tableView: 'seriesTable.seriesHeader.tableView',
			close: 'seriesTable.seriesHeader.close'
		}
	},
	series: {
		series: 'series.series'
	},

	entriesHeader: {
		searchEntries: 'entriesHeader.searchEntries',
		createNewEntry: 'entriesHeader.createNewEntry',
		searchEntriesTooltip: 'entriesHeader.searchEntriesTooltip'
	},
	entriesTable: {
		noEntries: 'entriesTable.noEntries'
	},
	entriesOptions: {
		clearFilters: 'entriesOptions.clearFilters',
		downloadAsCSV: 'entriesOptions.downloadAsCSV',
		downloadAsExcel: 'entriesOptions.downloadAsExcel',
		expandAllGroups: 'entriesOptions.expandAllGroups',
		collapseAllGroups: 'entriesOptions.collapseAllGroups'
	},
	pagination: {
		show: 'pagination.show',
		outOf: 'pagination.outOf',
		entries: 'pagination.entries',
		changePage: 'pagination.changePage',
		go: 'pagination.go',
		files: 'pagination.files',
		projects: 'pagination.projects'
	},
	entries: {
		empty: 'entries.empty'
	},
	filters: {
		filters: 'filters.filters',
		addFilter: 'filters.addFilter',
		showFilters: 'filters.showFilters'
	},
	filterInputs: {
		fromLowerCase: 'filterInputs.fromLowerCase',
		fromUpperCase: 'filterInputs.fromUpperCase',
		value: 'filterInputs.value',
		toLowerCase: 'filterInputs.toLowerCase',
		toUpperCase: 'filterInputs.toUpperCase'
	},
	addEditForm: {
		deleteEntry: 'addEditForm.deleteEntry',
		deleteEntryConfirmationTarget: 'addEditForm.deleteEntryConfirmationTarget',
		deleteEntryConfirmationLabel: 'addEditForm.deleteEntryConfirmationLabel',
		updateEntry: 'addEditForm.updateEntry',
		addEntry: 'addEditForm.addEntry',
		backToDataset: 'addEditForm.backToDataset',
		showForms: 'addEditForm.showForms',
		saveNew: 'addEditForm.saveNew',
		createEntry: 'addEditForm.createEntry'
	},
	addEditEntry: {
		editEntry: 'addEditEntry.editEntry',
		createEntry: 'addEditEntry.createEntry',
		expandGroups: 'addEditEntry.expandGroups',
		collapseGroups: 'addEditEntry.collapseGroups',
		requiredFields: 'addEditEntry.requiredFields',
		exportPDF: 'addEditEntry.exportPDF',
		cookieMessage: 'addEditEntry.cookieMessage'
	},
	entryRevisionsDrawer: {
		entryHistory: 'entryRevisionsDrawer.entryHistory',
		older: 'entryRevisionsDrawer.older',
		entry: 'entryRevisionsDrawer.entry',
		entries: 'entryRevisionsDrawer.entries',
		today: 'entryRevisionsDrawer.today',
		yesterday: 'entryRevisionsDrawer.yesterday'
	},
	revisionCard: {
		current: 'revisionCard.current',
		latest: 'revisionCard.latest'
	},
	versionChanges: {
		historyDrawerWarning: 'versionChanges.historyDrawerWarning',
		warningText: 'versionChanges.warningText',
		warningQuestion: 'versionChanges.warningQuestion',
		restoreQuestion: 'versionChanges.restoreQuestion',
		restoreWarning: 'versionChanges.restoreWarning',
		restoreVersion: 'versionChanges.restoreVersion'
	},
	unrestoredRevision: {
		warningTitle: 'unrestoredRevision.warningTitle',
		warningText: 'unrestoredRevision.warningText'
	},
	radioGroups: {
		noChoicesAvailable: 'radioGroups.noChoicesAvailable',
		addNewValue: 'radioGroups.addNewValue',
		valueRequired: 'radioGroups.valueRequired',
		noSelection: 'radioGroups.noSelection',
		uniqueValue: 'radioGroups.uniqueValue'
	},
	formRadioGroups: {
		newValue: 'formRadioGroups.newValue'
	},
	tHeader: {
		enteredBy: 'tHeader.enteredBy'
	},
	// We should start grouping the keys a bit or it's gonna
	// become unmaintainable real fast
	analysis: {
		title: 'analysis.title',
		columnView: {
			oneColumn: 'analysis.columnView.oneColumn',
			twoColumns: 'analysis.columnView.twoColumns',
			threeColumns: 'analysis.columnView.threeColumns'
		},
		errors: {
			invalidAnalysis: {
				categoryNumericDateError:
					'analysis.errors.invalidAnalysis.categoryNumericDateError',
				categoryAndNumericError: 'analysis.errors.invalidAnalysis.categoryAndNumericError',
				numericAndDateError: 'analysis.errors.invalidAnalysis.numericAndDateError',
				categoryError: 'analysis.errors.invalidAnalysis.categoryError',
				numericError: 'analysis.errors.invalidAnalysis.numericError',
				inputError: 'analysis.errors.invalidAnalysis.inputError',
				missingDatasetError: 'analysis.errors.invalidAnalysis.missingDatasetError',
				loadingEntries: 'analysis.errors.invalidAnalysis.loadingEntries',
				positiveOutcomeError: 'analysis.errors.invalidAnalysis.positiveOutcomeError',
				selectInputHeader: 'analysis.errors.invalidAnalysis.selectInputHeader',
				selectInputBody: 'analysis.errors.invalidAnalysis.selectInputBody'
			},
			snapshots: {
				invalid: 'analysis.errors.snapshots.invalid',
				saveError: 'analysis.errors.snapshots.saveError'
			},
			statistics: {
				missing: 'analysis.errors.statistics.missing',
				invalidKruskal: 'analysis.errors.statistics.invalidKruskal',
				dfStatStatistic: 'analysis.errors.statistics.dfStatStatistic',
				fStatistic: 'analysis.errors.statistics.fStatistic',
				pStatistic: 'analysis.errors.statistics.pStatistic',
				rStatistic: 'analysis.errors.statistics.rStatistic',
				tStatistic: 'analysis.errors.statistics.tStatistic',
				uStatistic: 'analysis.errors.statistics.uStatistic'
			},
			filters: {
				noFilters: 'analysis.errors.filters.noFilters'
			},
			noAnalyses: 'analysis.errors.noAnalyses',
			serverError: 'analysis.errors.serverError',
			filtersAreNotValid: 'analysis.errors.filtersAreNotValid',
			noResults: 'analysis.errors.noResults',
			noVariables: 'analysis.errors.noVariables',
			tooMuchData: 'analysis.errors.tooMuchData',
			corruptedData: 'analysis.errors.corruptedData'
		},
		actions: {
			add: 'analysis.actions.add',
			choose: 'analysis.actions.choose',
			fullscreen: 'analysis.actions.fullscreen'
		},
		generic: {
			n: 'analysis.generic.n',
			mean: 'analysis.generic.mean',
			sd: 'analysis.generic.sd',
			confidenceLower: 'analysis.generic.confidenceLower',
			confidenceUpper: 'analysis.generic.confidenceUpper',
			sem: 'analysis.generic.sem',
			variance: 'analysis.generic.variance',
			skewness: 'analysis.generic.skewness',
			kurtosis: 'analysis.generic.kurtosis',
			median: 'analysis.generic.median',
			min: 'analysis.generic.min',
			max: 'analysis.generic.max',
			range: 'analysis.generic.range',
			IQRLower: 'analysis.generic.IQRLower',
			IQRUpper: 'analysis.generic.IQRUpper',
			missing: 'analysis.generic.missing',
			shapiro: 'analysis.generic.shapiro',
			chartLegend: 'analysis.generic.chartLegend',
			histogram: 'analysis.generic.histogram',
			showLabels: 'analysis.generic.showLabels',
			showValues: 'analysis.generic.showValues',
			dataModel: 'analysis.generic.dataModel',
			mainLevel: 'analysis.generic.mainLevel',
			series: 'analysis.generic.series',
			singleSeries: 'analysis.generic.singleSeries',
			showChartTicks: 'analysis.generic.showChartTicks',
			errorBars: {
				mean: 'analysis.generic.errorBars.mean',
				meanSD: 'analysis.generic.errorBars.meanSD',
				meanCI: 'analysis.generic.errorBars.meanCI',
				meanRange: 'analysis.generic.errorBars.meanRange',
				median: 'analysis.generic.errorBars.median',
				medianCI: 'analysis.generic.errorBars.medianCI',
				medianRange: 'analysis.generic.errorBars.medianRange',
				medianIQR: 'analysis.generic.errorBars.medianIQR'
			}
		},
		analyses: {
			name: 'analysis.analyses.name',
			deprecated: 'analysis.analyses.deprecated',
			groupedOptions: {
				title: {
					Parameters: 'analysis.analyses.groupedOptions.title.Parameters',
					Formatting: 'analysis.analyses.groupedOptions.title.Formatting',
					ChartType: 'analysis.analyses.groupedOptions.title.ChartType',
					chartOptions: 'analysis.analyses.groupedOptions.title.chartOptions',
					categoryValues: 'analysis.analyses.groupedOptions.title.categoryValues',
					jadBioPerformance: 'analysis.analyses.groupedOptions.title.jadBioPerformance'
				}
			},
			compare: {
				name: 'analysis.analyses.compare.name',
				name_V1: 'analysis.analyses.compare.name_V1',
				name_V2: 'analysis.analyses.compare.name_V2',
				config: {
					explore: 'analysis.analyses.compare.config.explore',
					category: 'analysis.analyses.compare.config.category',
					exploreTwo: 'analysis.analyses.compare.config.exploreTwo',
					categoryTwo: 'analysis.analyses.compare.config.categoryTwo',
					explore_V1: 'analysis.analyses.compare.config.explore_V1',
					category_V1: 'analysis.analyses.compare.config.category_V1',
					exploreTwo_V1: 'analysis.analyses.compare.config.exploreTwo_V1',
					categoryTwo_V1: 'analysis.analyses.compare.config.categoryTwo_V1'
				}
			},
			correlationsV1: {
				name: 'analysis.analyses.correlationsV1.name'
			},
			correlationsV2: {
				name: 'analysis.analyses.correlationsV2.name',
				config: {
					dataModel: 'analysis.analyses.correlationsV2.config.dataModel',
					mainLevel: 'analysis.analyses.correlationsV2.config.mainLevel',
					series: 'analysis.analyses.correlationsV2.config.series',
					singleSeries: 'analysis.analyses.correlationsV2.config.singleSeries',
					x: 'analysis.analyses.correlationsV2.config.x',
					y: 'analysis.analyses.correlationsV2.config.y',
					grouped: 'analysis.analyses.correlationsV2.config.grouped',
					ungrouped: 'analysis.analyses.correlationsV2.config.ungrouped'
				}
			},
			crosstab: {
				name: 'analysis.analyses.crosstab.name',
				nameV2: 'analysis.analyses.crosstab.nameV2',
				view: {
					table: 'analysis.analyses.crosstab.view.table',
					grouped: 'analysis.analyses.crosstab.view.grouped',
					stacked: 'analysis.analyses.crosstab.view.stacked',
					sunburst: 'analysis.analyses.crosstab.view.sunburst'
				},
				table: {
					total: 'analysis.analyses.crosstab.table.total'
				},
				config: {
					rows: 'analysis.analyses.crosstab.config.rows',
					columns: 'analysis.analyses.crosstab.config.columns'
				}
			},
			crosstabV2: {
				name: 'analysis.analyses.crosstab.name',
				view: {
					table: 'analysis.analyses.crosstab.view.table',
					grouped: 'analysis.analyses.crosstab.view.grouped',
					stacked: 'analysis.analyses.crosstab.view.stacked',
					sunburst: 'analysis.analyses.crosstab.view.sunburst'
				},
				table: {
					total: 'analysis.analyses.crosstab.table.total'
				},
				config: {
					rows: 'analysis.analyses.crosstab.config.rows',
					columns: 'analysis.analyses.crosstab.config.columns'
				}
			},
			explore: {
				name: 'analysis.analyses.explore.name',
				nameV2: 'analysis.analyses.explore.nameV2',
				view: {
					chooseAVariable: 'analysis.analyses.explore.view.chooseAVariable'
				},
				table: {
					variable: 'analysis.analyses.explore.table.variable',
					mean: 'analysis.analyses.explore.table.mean',
					median: 'analysis.analyses.explore.table.median',
					n: 'analysis.analyses.explore.table.n',
					missing: 'analysis.analyses.explore.table.missing'
				},
				config: {
					variables: 'analysis.analyses.explore.config.variables',
					selectVariables: 'analysis.analyses.explore.config.selectVariables',
					addVariables: 'analysis.analyses.explore.config.addVariables'
				}
			},
			frequencies: {
				name: 'analysis.analyses.frequencies.name',
				nameV2: 'analysis.analyses.frequencies.nameV2',
				view: {
					table: 'analysis.analyses.frequencies.view.table',
					columns: 'analysis.analyses.frequencies.view.columns',
					pie: 'analysis.analyses.frequencies.view.pie',
					value: 'analysis.analyses.frequencies.view.value',
					frequency: 'analysis.analyses.frequencies.view.frequency'
				},
				table: {
					missing: 'analysis.analyses.frequencies.table.missing',
					category: 'analysis.analyses.frequencies.table.category',
					n: 'analysis.analyses.frequencies.table.n',
					values: 'analysis.analyses.frequencies.table.values'
				},
				bar: {
					missing: 'analysis.analyses.frequencies.bar.missing'
				},
				pie: {
					missing: 'analysis.analyses.frequencies.pie.missing'
				},
				config: {
					categories: 'analysis.analyses.frequencies.config.categories'
				}
			},
			kaplan: {
				name: 'analysis.analyses.kaplan.name',
				nameV2: 'analysis.analyses.kaplan.nameV2',
				config: {
					noGrouping: 'analysis.analyses.kaplan.config.noGrouping',
					timeVariable: 'analysis.analyses.kaplan.config.timeVariable',
					timeRange: 'analysis.analyses.kaplan.config.timeRange',
					endpoint: 'analysis.analyses.kaplan.config.endpoint',
					groups: 'analysis.analyses.kaplan.config.groups',
					patientsAtRisk: 'analysis.analyses.kaplan.config.patientsAtRisk',
					confidenceIntervals: 'analysis.analyses.kaplan.config.confidenceIntervals',
					logRank: 'analysis.analyses.kaplan.config.logRank',
					dataModel: 'analysis.analyses.kaplan.config.dataModel',
					dataType: 'analysis.analyses.kaplan.config.dataType',
					duration: 'analysis.analyses.kaplan.config.duration',
					timeRangeWithEvent: 'analysis.analyses.kaplan.config.timeRangeWithEvent',
					endDateCensoring: 'analysis.analyses.kaplan.config.endDateCensoring',
					calendar: 'analysis.analyses.kaplan.config.calendar',
					timeUnit: 'analysis.analyses.kaplan.config.timeUnit',
					endTimeCensorVariable: 'analysis.analyses.kaplan.config.endTimeCensorVariable'
				},
				yAxis: 'analysis.analyses.kaplan.yAxis'
			},
			plotNumeric: {
				name: 'analysis.analyses.plotNumeric.name',
				nameV2: 'analysis.analyses.plotNumeric.nameV2',
				config: {
					category: 'analysis.analyses.plotNumeric.config.category',
					numeric: 'analysis.analyses.plotNumeric.config.numeric',
					grouping: 'analysis.analyses.plotNumeric.config.grouping',
					noGrouping: 'analysis.analyses.plotNumeric.config.noGrouping',
					plot: 'analysis.analyses.plotNumeric.config.plot',
					align: {
						aligned: 'analysis.analyses.plotNumeric.config.align.aligned',
						scattered: 'analysis.analyses.plotNumeric.config.align.scattered'
					},
					line: {
						noLine: 'analysis.analyses.plotNumeric.config.line.noLine',
						lineAtMean: 'analysis.analyses.plotNumeric.config.line.lineAtMean',
						lineAtMedian: 'analysis.analyses.plotNumeric.config.line.lineAtMedian'
					}
				},
				view: {
					columns: 'analysis.analyses.plotNumeric.view.columns',
					box: 'analysis.analyses.plotNumeric.view.box',
					scatter: 'analysis.analyses.plotNumeric.view.scatter'
				},
				tooltip: {
					columns: {
						mean: 'analysis.analyses.plotNumeric.tooltip.columns.mean',
						median: 'analysis.analyses.plotNumeric.tooltip.columns.median',
						error: 'analysis.analyses.plotNumeric.tooltip.columns.error',
						errorUpper: 'analysis.analyses.plotNumeric.tooltip.columns.errorUpper',
						errorLower: 'analysis.analyses.plotNumeric.tooltip.columns.errorLower'
					},
					boxplot: {
						errorValueMin:
							'analysis.analyses.plotNumeric.tooltip.boxplot.errorValueMin',
						IQRLower: 'analysis.analyses.plotNumeric.tooltip.boxplot.IQRLower',
						median: 'analysis.analyses.plotNumeric.tooltip.boxplot.median',
						IQRUpper: 'analysis.analyses.plotNumeric.tooltip.boxplot.IQRUpper',
						errorValueMax: 'analysis.analyses.plotNumeric.tooltip.boxplot.errorValueMax'
					}
				}
			},
			plotNumericV2: {
				nameV2: 'analysis.analyses.plotNumericV2.nameV2',
				config: {
					groupOne: 'analysis.analyses.plotNumericV2.config.groupOne',
					groupTwo: 'analysis.analyses.plotNumericV2.config.groupTwo',
					numeric: 'analysis.analyses.plotNumericV2.config.numeric',
					noGrouping: 'analysis.analyses.plotNumericV2.config.noGrouping',
					plot: 'analysis.analyses.plotNumericV2.config.plot',
					align: {
						aligned: 'analysis.analyses.plotNumericV2.config.align.aligned',
						scattered: 'analysis.analyses.plotNumericV2.config.align.scattered'
					},
					line: {
						noLine: 'analysis.analyses.plotNumericV2.config.line.noLine',
						lineAtMean: 'analysis.analyses.plotNumericV2.config.line.lineAtMean',
						lineAtMedian: 'analysis.analyses.plotNumericV2.config.line.lineAtMedian'
					}
				},
				view: {
					columns: 'analysis.analyses.plotNumericV2.view.columns',
					box: 'analysis.analyses.plotNumericV2.view.box',
					scatter: 'analysis.analyses.plotNumericV2.view.scatter'
				},
				tooltip: {
					columns: {
						mean: 'analysis.analyses.plotNumericV2.tooltip.columns.mean',
						median: 'analysis.analyses.plotNumericV2.tooltip.columns.median',
						error: 'analysis.analyses.plotNumericV2.tooltip.columns.error',
						errorUpper: 'analysis.analyses.plotNumericV2.tooltip.columns.errorUpper',
						errorLower: 'analysis.analyses.plotNumericV2.tooltip.columns.errorLower'
					},
					boxplot: {
						errorValueMin:
							'analysis.analyses.plotNumericV2.tooltip.boxplot.errorValueMin',
						IQRLower: 'analysis.analyses.plotNumericV2.tooltip.boxplot.IQRLower',
						median: 'analysis.analyses.plotNumericV2.tooltip.boxplot.median',
						IQRUpper: 'analysis.analyses.plotNumericV2.tooltip.boxplot.IQRUpper',
						errorValueMax:
							'analysis.analyses.plotNumericV2.tooltip.boxplot.errorValueMax'
					}
				}
			},
			densityPlot: {
				yAxis: 'analysis.analyses.densityPlot.yAxis',
				name: 'analysis.analyses.densityPlot.name',
				config: {
					noGrouping: 'analysis.analyses.densityPlot.config.noGrouping',
					numeric: 'analysis.analyses.densityPlot.config.numeric',
					grouping: 'analysis.analyses.densityPlot.config.grouping'
				}
			},
			densityPlotV2: {
				yAxis: 'analysis.analyses.densityPlot.yAxis',
				name: 'analysis.analyses.densityPlot.name',
				config: {
					noGrouping: 'analysis.analyses.densityPlot.config.noGrouping',
					numeric: 'analysis.analyses.densityPlot.config.numeric',
					grouping: 'analysis.analyses.densityPlot.config.grouping'
				}
			},
			timeCourse: {
				name: 'analysis.analyses.timeCourse.name',
				name_V2: 'analysis.analyses.timeCourse.name_V2',
				config: {
					variable: 'analysis.analyses.timeCourse.config.variable',
					timeVariable: 'analysis.analyses.timeCourse.config.timeVariable',
					grouping: 'analysis.analyses.timeCourse.config.grouping',
					timeUnit: {
						label: 'analysis.analyses.timeCourse.config.timeUnit.label',
						years: 'analysis.analyses.timeCourse.config.timeUnit.years',
						months: 'analysis.analyses.timeCourse.config.timeUnit.months',
						weeks: 'analysis.analyses.timeCourse.config.timeUnit.weeks',
						days: 'analysis.analyses.timeCourse.config.timeUnit.days',
						hours: 'analysis.analyses.timeCourse.config.timeUnit.hours',
						minutes: 'analysis.analyses.timeCourse.config.timeUnit.minutes',
						seconds: 'analysis.analyses.timeCourse.config.timeUnit.seconds'
					},
					statistic: 'analysis.analyses.timeCourse.config.statistic'
				}
			},
			timeCourseV1: {
				name: 'analysis.analyses.timeCourseV1.name',
				config: {
					variable: 'analysis.analyses.timeCourseV1.config.variable',
					dateVariable: 'analysis.analyses.timeCourseV1.config.dateVariable',
					timeWindow: {
						label: 'analysis.analyses.timeCourseV1.config.timeWindow.label',
						years: 'analysis.analyses.timeCourseV1.config.timeWindow.years',
						months: 'analysis.analyses.timeCourseV1.config.timeWindow.months',
						weeks: 'analysis.analyses.timeCourseV1.config.timeWindow.weeks',
						days: 'analysis.analyses.timeCourseV1.config.timeWindow.days',
						hours: 'analysis.analyses.timeCourseV1.config.timeWindow.hours',
						minutes: 'analysis.analyses.timeCourseV1.config.timeWindow.minutes',
						seconds: 'analysis.analyses.timeCourseV1.config.timeWindow.seconds'
					},
					plot: 'analysis.analyses.timeCourseV1.config.plot'
				}
			},
			comparePaired: {
				name: 'analysis.analyses.comparePaired.name',
				config: {
					numericVariableOne: 'analysis.analyses.comparePaired.config.numericVariableOne',
					numericVariableTwo: 'analysis.analyses.comparePaired.config.numericVariableTwo',
					catVarnameDiffSamples:
						'analysis.analyses.comparePaired.config.catVarnameDiffSamples',
					firstCategoryValue: 'analysis.analyses.comparePaired.config.firstCategoryValue',
					secondCategoryValue:
						'analysis.analyses.comparePaired.config.secondCategoryValue',
					patientIdentifierVarname:
						'analysis.analyses.comparePaired.config.patientIdentifierVarname',
					setName: 'analysis.analyses.comparePaired.config.setName',
					singleEntryDataModel:
						'analysis.analyses.comparePaired.config.singleEntryDataModel',
					twoEntriesDataModel:
						'analysis.analyses.comparePaired.config.twoEntriesDataModel',
					seriesDataModel: 'analysis.analyses.comparePaired.config.seriesDataModel',
					testVariable: 'analysis.analyses.comparePaired.config.testVariable',
					//
					dataModel: 'analysis.analyses.comparePaired.config.dataModel',
					dataType: 'analysis.analyses.comparePaired.config.dataType',
					beforeVariable: 'analysis.analyses.comparePaired.config.beforeVariable',
					afterVariable: 'analysis.analyses.comparePaired.config.afterVariable',
					numericVariable: 'analysis.analyses.comparePaired.config.numericVariable',
					pairIdentifier: 'analysis.analyses.comparePaired.config.pairIdentifier',
					groupVariable: 'analysis.analyses.comparePaired.config.groupVariable',
					singleEntryDataType:
						'analysis.analyses.comparePaired.config.singleEntryDataType',
					twoEntriesDataType: 'analysis.analyses.comparePaired.config.twoEntriesDataType',
					seriesEntryDataType:
						'analysis.analyses.comparePaired.config.seriesEntryDataType'
				}
			},
			numberPlotXY: {
				name: 'analysis.analyses.numberPlotXY.name',
				config: {
					x_value: 'analysis.analyses.numberPlotXY.config.x_value',
					y_value: 'analysis.analyses.numberPlotXY.config.y_value',
					grouping_variable: 'analysis.analyses.numberPlotXY.config.grouping_variable'
				},
				deprecatedAction: 'analysis.analyses.numberPlotXY.deprecatedAction'
			},
			logisticRegression: {
				name: 'analysis.analyses.logisticRegression.name',
				nameV2: 'analysis.analyses.logisticRegression.nameV2',
				config: {
					independentVariable:
						'analysis.analyses.logisticRegression.config.independentVariable',
					groupVariable: 'analysis.analyses.logisticRegression.config.groupVariable',
					dependentVariable:
						'analysis.analyses.logisticRegression.config.dependentVariable',
					positiveEvent: 'analysis.analyses.logisticRegression.config.positiveEvent'
				},
				tableColumns: {
					estimate: 'analysis.analyses.logisticRegression.tableColumns.estimate',
					standardError:
						'analysis.analyses.logisticRegression.tableColumns.standardError',
					zValue: 'analysis.analyses.logisticRegression.tableColumns.zValue',
					pValue: 'analysis.analyses.logisticRegression.tableColumns.pValue',
					lowerCI: 'analysis.analyses.logisticRegression.tableColumns.lowerCI',
					upperCI: 'analysis.analyses.logisticRegression.tableColumns.upperCI'
				}
			},
			logisticRegressionV2: {
				name: 'analysis.analyses.logisticRegressionV2.name',
				config: {
					xVariable: 'analysis.analyses.logisticRegressionV2.config.xVariable',
					groupVariable: 'analysis.analyses.logisticRegressionV2.config.groupVariable',
					yVariable: 'analysis.analyses.logisticRegressionV2.config.yVariable',
					outcomes: 'analysis.analyses.logisticRegressionV2.config.outcomes'
				},
				tableColumns: {
					estimate: 'analysis.analyses.logisticRegressionV2.tableColumns.estimate',
					standardError:
						'analysis.analyses.logisticRegressionV2.tableColumns.standardError',
					zValue: 'analysis.analyses.logisticRegressionV2.tableColumns.zValue',
					pValue: 'analysis.analyses.logisticRegressionV2.tableColumns.pValue',
					lowerCI: 'analysis.analyses.logisticRegressionV2.tableColumns.lowerCI',
					upperCI: 'analysis.analyses.logisticRegressionV2.tableColumns.upperCI'
				}
			},
			JADBio: {
				name: 'analysis.analyses.JADBio.name',
				jadbioMissingUserAddon: 'analysis.analyses.JADBio.jadbioMissingUserAddon',
				jadbioInactiveUserAddon: 'analysis.analyses.JADBio.jadbioInactiveUserAddon',
				jadbioDesabledProjectAddon: 'analysis.analyses.JADBio.jadbioDesabledProjectAddon',
				loggingJADBio: 'analysis.analyses.JADBio.loggingJADBio',
				projectNotFetched: 'analysis.analyses.JADBio.projectNotFetched',
				jadbioDatasetNotInitialized: 'analysis.analyses.JADBio.JADBioDatasetNotInitialized',
				fetchingJADBioProject: 'analysis.analyses.JADBio.fetchingJADBioProject',
				fetchingJADBioProjectError: 'analysis.analyses.JADBio.fetchingJADBioProjectError',
				initiatingJADBioDataset: 'analysis.analyses.JADBio.initiatingJADBioDataset',
				initiatingJADBioDatasetError:
					'analysis.analyses.JADBio.initiatingJADBioDatasetError',
				selectJADBioModel: 'analysis.analyses.JADBio.selectJADBioModel',
				selectJADBioVariable: 'analysis.analyses.JADBio.selectJADBioVariable',
				selectJADBioVariables: 'analysis.analyses.JADBio.selectJADBioVariables',
				jadbioIsCalculating: 'analysis.analyses.JADBio.jadbioIsCalculating',
				jadbioAnalysisError: 'analysis.analyses.JADBio.jadbioAnalysisError',
				jadbioMulticategoryLimitation:
					'analysis.analyses.JADBio.jadbioMulticategoryLimitation',
				displayAs: 'analysis.analyses.JADBio.displayAs',
				featureSelection: 'analysis.analyses.JADBio.featureSelection',
				unableToExtract: 'analysis.analyses.JADBio.unableToExtract',
				analysisTabs: {
					analysisResultOverview:
						'analysis.analyses.JADBio.analysisTabs.analysisResultOverview',
					performanceReduction:
						'analysis.analyses.JADBio.analysisTabs.performanceReduction',
					performanceProbabilities:
						'analysis.analyses.JADBio.analysisTabs.performanceProbabilities',
					featureImportance: 'analysis.analyses.JADBio.analysisTabs.featureImportance'
				},
				labelsNames: {
					feature: 'analysis.analyses.JADBio.labelsNames.feature',
					predictivePerformancePercentageDrop:
						'analysis.analyses.JADBio.labelsNames.predictivePerformancePercentageDrop',
					UMAP: 'analysis.analyses.JADBio.labelsNames.UMAP',
					predictivePerformancePercentageIncrease:
						'analysis.analyses.JADBio.labelsNames.predictivePerformancePercentageIncrease'
				},
				models: {
					model: 'analysis.analyses.JADBio.models.model',
					featureSelection: 'analysis.analyses.JADBio.models.featureSelection',
					preprocessing: 'analysis.analyses.JADBio.models.preprocessing',
					performance: 'analysis.analyses.JADBio.models.performance',
					signatures: 'analysis.analyses.JADBio.models.signatures'
				},
				descriptions: {
					UMAP: 'analysis.analyses.JADBio.descriptions.UMAP',
					PCA: 'analysis.analyses.JADBio.descriptions.PCA',
					dimensionalityReduction:
						'analysis.analyses.JADBio.descriptions.dimensionalityReduction',
					progressiveFeature: 'analysis.analyses.JADBio.descriptions.progressiveFeature',
					progressiveFeatureInclusion:
						'analysis.analyses.JADBio.descriptions.progressiveFeatureInclusion',
					probabilities: 'analysis.analyses.JADBio.descriptions.probabilities'
				},
				progressiveFeature: {
					categoryOne: 'analysis.analyses.JADBio.progressiveFeature.categoryOne',
					categoryTwo: 'analysis.analyses.JADBio.progressiveFeature.categoryTwo',
					categoryThree: 'analysis.analyses.JADBio.progressiveFeature.categoryThree'
				},
				config: {
					model: {
						classification: 'analysis.analyses.JADBio.config.model.classification',
						survivalAnalysis: 'analysis.analyses.JADBio.config.model.survivalAnalysis',
						regression: 'analysis.analyses.JADBio.config.model.regression'
					},
					densityPlot: 'analysis.analyses.JADBio.config.densityPlot',
					boxPlot: 'analysis.analyses.JADBio.config.boxPlot',
					featureImportance: 'analysis.analyses.JADBio.config.featureImportance',
					progressiveFeatureInclusion:
						'analysis.analyses.JADBio.config.progressiveFeatureInclusion',
					JADBioModel: 'analysis.analyses.JADBio.config.JADBioModel',
					chooseJADBioModel: 'analysis.analyses.JADBio.config.chooseJADBioModel',
					chooseJADBioVariable: 'analysis.analyses.JADBio.config.chooseJADBioVariable',
					classificationVariable:
						'analysis.analyses.JADBio.config.classificationVariable',
					eventVariable: 'analysis.analyses.JADBio.config.eventVariable',
					timeToEventVariable: 'analysis.analyses.JADBio.config.timeToEventVariable',
					regressionVariable: 'analysis.analyses.JADBio.config.regressionVariable'
				},
				warningModal: {
					title: 'analysis.analyses.JADBio.warningModal.title',
					description: 'analysis.analyses.JADBio.warningModal.description'
				}
			},
			error: {
				noData: {
					header: 'analysis.analyses.error.noData.header',
					body: 'analysis.analyses.error.noData.body'
				},
				groupLimitExceeded: {
					header: 'analysis.analyses.error.groupLimitExceeded.header',
					body: 'analysis.analyses.error.groupLimitExceeded.body'
				},
				computationError: 'analysis.analyses.error.computationError',
				incompatibleNumberOfGroups: 'analysis.analyses.error.incompatibleNumberOfGroups',
				genericError: 'analysis.analyses.error.genericError'
			}
		},
		filters: {
			add: 'analysis.filters.add',
			choose: 'analysis.filters.choose',
			totalPopulation: 'analysis.filters.totalPopulation'
		},
		statistics: {
			fisher: {
				name: 'analysis.statistics.fisher.name',
				description: 'analysis.statistics.fisher.description'
			},
			chiSquare: {
				name: 'analysis.statistics.chiSquare.name',
				description: 'analysis.statistics.chiSquare.description'
			},
			shapiro: {
				name: 'analysis.statistics.shapiro.name',
				description: 'analysis.statistics.shapiro.description'
			},
			mannWhitney: {
				name: 'analysis.statistics.mannWhitney.name',
				description: 'analysis.statistics.mannWhitney.description'
			},
			independent: {
				name: 'analysis.statistics.independent.name',
				description: 'analysis.statistics.independent.description'
			},
			pearson: {
				name: 'analysis.statistics.pearson.name',
				description: 'analysis.statistics.pearson.description'
			},
			spearman: {
				name: 'analysis.statistics.spearman.name',
				description: 'analysis.statistics.spearman.description'
			},
			linearRegression: {
				name: 'analysis.statistics.linearRegression.name',
				description: 'analysis.statistics.linearRegression.description'
			},
			oneWayAnova: {
				name: 'analysis.statistics.oneWayAnova.name',
				description: 'analysis.statistics.oneWayAnova.description'
			},
			twoWayAnova: {
				name: 'analysis.statistics.twoWayAnova.name',
				description: 'analysis.statistics.twoWayAnova.description'
			},
			oneWayManova: {
				name: 'analysis.statistics.oneWayManova.name',
				description: 'analysis.statistics.oneWayManova.description'
			},
			twoWayManova: {
				name: 'analysis.statistics.twoWayManova.name',
				description: 'analysis.statistics.twoWayManova.description'
			},
			logRank: {
				name: 'analysis.statistics.logRank.name',
				description: 'analysis.statistics.logRank.description'
			},
			tukey: {
				name: 'analysis.statistics.tukey.name',
				description: 'analysis.statistics.tukey.description',
				tableColumns: {
					comparison: 'analysis.statistics.tukey.tableColumns.comparison',
					groupOne: 'analysis.statistics.tukey.tableColumns.groupOne',
					groupTwo: 'analysis.statistics.tukey.tableColumns.groupTwo',
					statistic: 'analysis.statistics.tukey.tableColumns.statistic',
					pValue: 'analysis.statistics.tukey.tableColumns.pValue',
					ciLower: 'analysis.statistics.tukey.tableColumns.ciLower',
					ciUpper: 'analysis.statistics.tukey.tableColumns.ciUpper'
				}
			},
			pairedTTest: {
				name: 'analysis.statistics.pairedTTest.name',
				description: 'analysis.statistics.pairedTTest.description'
			},
			pairedWilcoxon: {
				name: 'analysis.statistics.pairedWilcoxon.name',
				description: 'analysis.statistics.pairedWilcoxon.description'
			},
			kruskal: {
				name: 'analysis.statistics.kruskal.name',
				description: 'analysis.statistics.kruskal.description'
			},
			mcNemar: {
				name: 'analysis.statistics.mcNemar.name',
				description: 'analysis.statistics.mcNemar.description'
			}
		},
		snapshots: {
			choose: 'analysis.snapshots.choose',
			delete: 'analysis.snapshots.delete',
			create: {
				nameRequired: 'analysis.snapshots.create.nameRequired',
				title: 'analysis.snapshots.create.title',
				placeholder: 'analysis.snapshots.create.placeholder'
			}
		}
	},
	proms: {
		title: 'proms.title'
	},
	promsPatientsModal: {
		title: {
			add: 'promsPatientsModal.title.add',
			update: 'promsPatientsModal.title.update'
		},
		dataUpdate: {
			patientAdded: 'promsPatientsModal.dataUpdate.patientAdded',
			patientUpdated: 'promsPatientsModal.dataUpdate.patientUpdated',
			distributionChanged: 'promsPatientsModal.dataUpdate.distributionChanged',
			patientDeleted: 'promsPatientsModal.dataUpdate.patientDeleted',
			deletePatient: 'promsPatientsModal.dataUpdate.deletePatient'
		},
		labels: {
			patientId: 'promsPatientsModal.labels.patientId',
			firstName: 'promsPatientsModal.labels.firstName',
			lastName: 'promsPatientsModal.labels.lastName',
			emailAddress: 'promsPatientsModal.labels.emailAddress',
			countryCode: 'promsPatientsModal.labels.countryCode',
			phoneNumber: 'promsPatientsModal.labels.phoneNumber',
			distributionStartDate: 'promsPatientsModal.labels.distributionStartDate',
			time: 'promsPatientsModal.labels.time'
		},
		notice: {
			note: 'promsPatientsModal.notice.note',
			message: 'promsPatientsModal.notice.message'
		},
		errors: {
			firstNameError: 'promsPatientsModal.errors.firstNameError',
			lastNameError: 'promsPatientsModal.errors.lastNameError',
			emailAddressError: 'promsPatientsModal.errors.emailAddressError',
			emailAddressErrorValid: 'promsPatientsModal.errors.emailAddressErrorValid',
			phoneNumberError: 'promsPatientsModal.errors.phoneNumberError',
			phoneNumberErrorValid: 'promsPatientsModal.errors.phoneNumberErrorValid',
			phoneCountryCodeError: 'promsPatientsModal.errors.phoneCountryCodeError',
			distributionDateError: 'promsPatientsModal.errors.distributionDateError',
			distributionTimeError: 'promsPatientsModal.errors.distributionTimeError'
		}
	},
	promsPatientsHeader: {
		inputPlaceholder: 'promsPatientsHeader.inputPlaceholder',
		addPatientButton: 'promsPatientsHeader.addPatientButton'
	},
	promsDashboard: {
		overallResponseRate: 'promsDashboard.overallResponseRate',
		responseRates: 'promsDashboard.responseRates',
		activity: 'promsDashboard.activity',
		noActivityToDisplay: 'promsDashboard.noActivityToDisplay',
		startCreatingFirstDistribution: 'promsDashboard.startCreatingFirstDistribution',
		startCreatingFirstRecipient: 'promsDashboard.startCreatingFirstRecipient',
		startCreatingFirstVariable: 'promsDashboard.startCreatingFirstVariable',
		patientsPage: 'promsDashboard.patientsPage',
		variablesPage: 'promsDashboard.variablesPage'
	},
	promsPatients: {
		distributionList: {
			senderDescriptionInfo: 'promsPatients.distributionList.senderDescriptionInfo',
			senderDescription: 'promsPatients.distributionList.senderDescription',
			senderDescriptionHint: 'promsPatients.distributionList.senderDescriptionHint',
			title: 'promsPatients.distributionList.title',
			note: 'promsPatients.distributionList.note',
			info: 'promsPatients.distributionList.info',
			warning: 'promsPatients.distributionList.warning',
			addNewDistribution: 'promsPatients.distributionList.addNewDistribution',
			createNewFormVariable: 'promsPatients.distributionList.createNewFormVariable',
			newDistribution: 'promsPatients.distributionList.newDistribution',
			distributionNumberLabel: 'promsPatients.distributionList.distributionNumberLabel',
			distribution: 'promsPatients.distributionList.distribution',
			autoGeneratedForm: 'promsPatients.distributionList.autoGeneratedForm',
			firstDistributionInfo: 'promsPatients.distributionList.firstDistributionInfo',
			saveDistributions: 'promsPatients.distributionList.saveDistributions',
			unitOfTime: {
				hours: 'promsPatients.distributionList.unitOfTime.hours',
				days: 'promsPatients.distributionList.unitOfTime.days',
				weeks: 'promsPatients.distributionList.unitOfTime.weeks',
				months: 'promsPatients.distributionList.unitOfTime.months'
			},
			unit: 'promsPatients.distributionList.unit',
			globalStartDate: 'promsPatients.distributionList.globalStartDate',
			globalStartTime: 'promsPatients.distributionList.globalStartTime',
			selectAForm: 'promsPatients.distributionList.selectAForm',
			default: 'promsPatients.distributionList.default',
			notifications: {
				distributionListUpdated:
					'promsPatients.distributionList.notifications.distributionListUpdated'
			}
		},
		patientList: {
			title: 'promsPatients.patientList.title',
			noPatientsToDisplay: 'promsPatients.patientList.noPatientsToDisplay'
		},
		distributionTable: {
			shouldBeSentIn: 'promsPatients.distributionTable.shouldBeSentIn',
			shouldBeSentNow: 'promsPatients.distributionTable.shouldBeSentNow',
			shouldHaveBeenSent: 'promsPatients.distributionTable.shouldHaveBeenSent',
			nr: 'promsPatients.distributionTable.nr',
			timeToSend: 'promsPatients.distributionTable.timeToSend',
			assignedForm: 'promsPatients.distributionTable.assignedForm',
			link: 'promsPatients.distributionTable.link',
			status: 'promsPatients.distributionTable.status',
			statistics: 'promsPatients.distributionTable.statistics',
			copyLink: 'promsPatients.distributionTable.copyLink',
			timeline: 'promsPatients.distributionTable.timeline'
		}
	},
	responseRateChart: {
		totalFormsSent: 'responseRateChart.totalFormsSent',
		totalFormsPartiallyFilled: 'responseRateChart.totalFormsPartiallyFilled',
		totalFormsFilled: 'responseRateChart.totalFormsFilled',
		totalPromPatients: 'responseRateChart.totalPromPatients',
		totalPromCollaborators: 'responseRateChart.totalPromCollaborators'
	},
	patientCard: {
		disableDistribution: 'patientCard.disableDistribution',
		startDate: 'patientCard.startDate',
		editPatient: 'patientCard.editPatient',
		nextDistribution: 'patientCard.nextDistribution'
	},
	lastDaysChart: {
		totalSent: 'lastDaysChart.totalSent',
		partiallyFilled: 'lastDaysChart.partiallyFilled',
		filled: 'lastDaysChart.filled'
	},
	promPatient: {
		form: {
			title: 'promPatient.form.title'
		},
		formUpdated: 'promPatient.formUpdated',
		thankYouMessage: 'promPatient.thankYouMessage',
		entryExpired: 'promPatient.entryExpired',
		invalidURL: 'promPatient.invalidURL',
		closeWindowNotice: 'promPatient.closeWindowNotice'
	},
	admin: {
		users: {
			notifications: {
				createdUser: 'admin.users.notifications.createdUser',
				removedUser: 'admin.users.notifications.removedUser',
				updatedUser: 'admin.users.notifications.updatedUser',
				resetPassword: 'admin.users.notifications.resetPassword',
				resetMFA: 'admin.users.notifications.resetMFA'
			},
			errors: {
				createdUser: 'admin.users.errors.createdUser',
				removedUser: 'admin.users.errors.removedUser',
				updatedUser: 'admin.users.errors.updatedUser',
				resetPassword: 'admin.users.errors.resetPassword',
				resetUserMFA: 'admin.users.errors.resetUserMFA',
				accessForbidden: 'admin.users.errors.accessForbidden'
			},
			list: {
				header: {
					inputPlaceholder: 'admin.users.list.header.inputPlaceholder',
					createUser: 'admin.users.list.header.createUser',
					exportAsXLSX: 'admin.users.list.header.exportAsXLSX'
				},
				title: 'admin.users.list.title',
				columns: {
					name: 'admin.users.list.columns.name',
					username: 'admin.users.list.columns.username',
					email: 'admin.users.list.columns.email',
					organization: 'admin.users.list.columns.organization',
					department: 'admin.users.list.columns.department',
					createdDate: 'admin.users.list.columns.createdDate',
					status: 'admin.users.list.columns.status',
					licence: 'admin.users.list.columns.licence',
					temporaryPasswordValidityDays:
						'admin.users.list.columns.temporaryPasswordValidityDays',
					forceChangePassword: 'admin.users.list.columns.forceChangePassword',
					phoneNumberVerified: 'admin.users.list.columns.phoneNumberVerified',
					mfaEnabled: 'admin.users.list.columns.mfaEnabled'
				}
			},
			details: {
				title: 'admin.users.details.title',
				resetPassword: 'admin.users.details.resetPassword',
				resetPasswordParagraph: 'admin.users.details.resetPasswordParagraph',
				resetMFA: 'admin.users.details.resetMFA',
				resetMFAParagraph: 'admin.users.details.resetMFAParagraph',
				removeUser: 'admin.users.details.removeUser',
				form: {
					fields: {
						userId: 'admin.users.details.form.fields.userId',
						licence: 'admin.users.details.form.fields.licence',
						createdDate: 'admin.users.details.form.fields.createdDate',
						status: 'admin.users.details.form.fields.status',
						personalInfo: 'admin.users.details.form.fields.personalInfo',
						workInfo: 'admin.users.details.form.fields.workInfo',
						system: 'admin.users.details.form.fields.system',
						language: 'admin.users.details.form.fields.language',
						dateTimeFormat: 'admin.users.details.form.fields.dateTimeFormat',
						activeStatus: 'admin.users.details.form.fields.activeStatus',
						userCredentials: 'admin.users.details.form.fields.userCredentials',
						subscriptionId: 'admin.users.details.form.fields.subscriptionId'
					}
				}
			},
			create: {
				title: 'admin.users.create.title'
			}
		},
		enterprise: {
			title: 'admin.enterprise.title',
			organizationsList: {
				enterpriseHeader: 'admin.enterprise.organizationsList.enterpriseHeader',
				domainsHeader: 'admin.enterprise.organizationsList.domainsHeader',
				noOrganizationSelected: 'admin.enterprise.organizationsList.noOrganizationSelected',
				noDomainsDefined: 'admin.enterprise.organizationsList.noDomainsDefined',
				domainAllowed: 'admin.enterprise.organizationsList.domainAllowed',
				domainBlocked: 'admin.enterprise.organizationsList.domainBlocked',
				addDomains: 'admin.enterprise.organizationsList.addDomains',
				removeSelected: 'admin.enterprise.organizationsList.removeSelected'
			},
			addDomainsModal: {
				title: 'admin.enterprise.addDomainsModal.title',
				caption: 'admin.enterprise.addDomainsModal.caption',
				inputPlaceholder: 'admin.enterprise.addDomainsModal.inputPlaceholder'
			},
			errors: {
				api: {
					couldntGetOrganizations: 'admin.enterprise.errors.api.couldntGetOrganizations',
					couldntGetDomainsForOrganization:
						'admin.enterprise.errors.api.couldntGetDomainsForOrganization',
					couldntAddDomains: 'admin.enterprise.errors.api.couldntAddDomains',
					couldntRemoveDomains: 'admin.enterprise.errors.api.couldntRemoveDomains',
					transferProjectOwnership:
						'admin.enterprise.errors.api.transferProjectOwnership',
					activeEnterpriseUsers: 'admin.enterprise.errors.api.activeEnterpriseUsers'
				}
			},
			api: {
				domainsAddedSuccessfully: 'admin.enterprise.api.domainsAddedSuccessfully',
				domainsRemovedSuccessfully: 'admin.enterprise.api.domainsRemovedSuccessfully'
			}
		},
		adminRoles: {
			modal: {
				title: {
					update: 'admin.adminRoles.modal.title.update'
				}
			}
		}
	},
	enterpriseAdmin: {
		header: {
			title: 'enterpriseAdmin.header.title',
			menuTabs: {
				dashboard: 'enterpriseAdmin.header.menuTabs.dashboard',
				users: 'enterpriseAdmin.header.menuTabs.users',
				projects: 'enterpriseAdmin.header.menuTabs.projects',
				subscription: 'enterpriseAdmin.header.menuTabs.subscription',
				roles: 'enterpriseAdmin.header.menuTabs.roles',
				settings: 'enterpriseAdmin.header.menuTabs.settings'
			}
		},
		subscription: {
			title: 'enterpriseAdmin.subscription.title',
			month: 'enterpriseAdmin.subscription.month',
			year: 'enterpriseAdmin.subscription.year'
		},
		rolesModules: {
			createFirstRole: 'enterpriseAdmin.rolesModules.createFirstRole',
			userManagement: {
				title: 'enterpriseAdmin.rolesModules.userManagement.title',
				addUser: 'enterpriseAdmin.rolesModules.userManagement.addUser',
				editUser: 'enterpriseAdmin.rolesModules.userManagement.editUser',
				deactivateUser: 'enterpriseAdmin.rolesModules.userManagement.deactivateUser',
				reactivateUser: 'enterpriseAdmin.rolesModules.userManagement.reactivateUser',
				resetPassword: 'enterpriseAdmin.rolesModules.userManagement.resetPassword'
			},
			userActivity: {
				title: 'enterpriseAdmin.rolesModules.userActivity.title',
				viewProject: 'enterpriseAdmin.rolesModules.userActivity.viewProject',
				transferOwnership: 'enterpriseAdmin.rolesModules.userActivity.transferOwnership',
				privateProjects: 'enterpriseAdmin.rolesModules.userActivity.privateProjects'
			},
			enterpriseAdminRoles: {
				title: 'enterpriseAdmin.rolesModules.enterpriseAdminRoles.title',
				createEnterpriseRoles:
					'enterpriseAdmin.rolesModules.enterpriseAdminRoles.createEnterpriseRoles',
				viewFeaturePermission:
					'enterpriseAdmin.rolesModules.enterpriseAdminRoles.viewFeaturePermission'
			},
			enterpriseRolesModal: {
				generalPermissions:
					'enterpriseAdmin.rolesModules.enterpriseRolesModal.generalPermissions',
				adminPermissions:
					'enterpriseAdmin.rolesModules.enterpriseRolesModal.adminPermissions'
			},
			api: {
				roleCreatedSuccesfully: 'enterpriseAdmin.rolesModules.api.roleCreatedSuccesfully',
				roleUpdatedSuccesfully: 'enterpriseAdmin.rolesModules.api.roleUpdatedSuccesfully',
				roleDeletedSuccesfully: 'enterpriseAdmin.rolesModules.api.roleDeletedSuccesfully',
				couldntCreateRole: 'enterpriseAdmin.rolesModules.api.couldntCreateRole',
				couldntGetRoles: 'enterpriseAdmin.rolesModules.api.couldntGetRoles',
				couldntUpdateRole: 'enterpriseAdmin.rolesModules.api.couldntUpdateRole',
				couldntDeleteRole: 'enterpriseAdmin.rolesModules.api.couldntDeleteRole'
			},
			allData: 'enterpriseAdmin.rolesModules.allData',
			contactSupport: 'enterpriseAdmin.rolesModules.contactSupport',
			contactSupportAlt: 'enterpriseAdmin.rolesModules.contactSupportAlt',
			licenceInfo: 'enterpriseAdmin.rolesModules.licenceInfo',
			licencesRemaining: 'enterpriseAdmin.rolesModules.licencesRemaining',
			licencesInPlan: 'enterpriseAdmin.rolesModules.licencesInPlan',
			jadBioIncluded: 'enterpriseAdmin.rolesModules.jadBioIncluded'
		},
		projects: {
			transferOwnership: 'enterpriseAdmin.projects.transferOwnership',
			viewCollaborators: 'enterpriseAdmin.projects.viewCollaborators',
			selected: 'enterpriseAdmin.projects.selected',
			projects: 'enterpriseAdmin.projects.projects',
			ownerName: 'enterpriseAdmin.projects.ownerName',
			collaborators: 'enterpriseAdmin.projects.collaborators',
			organization: 'enterpriseAdmin.projects.organization',
			status: 'enterpriseAdmin.projects.status',
			transferModal: {
				title: 'enterpriseAdmin.projects.transferModal.title',
				confirmationText: 'enterpriseAdmin.projects.transferModal.confirmationText',
				titleTransferSuccesful:
					'enterpriseAdmin.projects.transferModal.titleTransferSuccesful',
				projectUnderNewOwnership:
					'enterpriseAdmin.projects.transferModal.projectUnderNewOwnership',
				currentOwner: 'enterpriseAdmin.projects.transferModal.currentOwner',
				newOwner: 'enterpriseAdmin.projects.transferModal.newOwner'
			}
		},
		deactivateUser: {
			title: {
				deactivateUser: 'enterpriseAdmin.deactivateUser.title.deactivateUser',
				transferProjectOwnership:
					'enterpriseAdmin.deactivateUser.title.transferProjectOwnership',
				projectOwnershipPreview:
					'enterpriseAdmin.deactivateUser.title.projectOwnershipPreview',
				transferComplete: 'enterpriseAdmin.deactivateUser.title.transferComplete',
				userDeactivated: 'enterpriseAdmin.deactivateUser.title.userDeactivated'
			},
			modalQuestion: 'enterpriseAdmin.deactivateUser.modalQuestion',
			modalCaption: 'enterpriseAdmin.deactivateUser.modalCaption',
			licenceDeactivationCaptions: {
				unableToSignInCaption:
					'enterpriseAdmin.deactivateUser.licenceDeactivationCaptions.unableToSignInCaption',
				licenceIncreaseCaption:
					'enterpriseAdmin.deactivateUser.licenceDeactivationCaptions.licenceIncreaseCaption',
				dataRetainedCaption:
					'enterpriseAdmin.deactivateUser.licenceDeactivationCaptions.dataRetainedCaption',
				userReactivationCaption:
					'enterpriseAdmin.deactivateUser.licenceDeactivationCaptions.userReactivationCaption'
			},
			transferProjectOwnership: {
				transferInstructions:
					'enterpriseAdmin.deactivateUser.transferProjectOwnership.transferInstructions',
				projectsSelected:
					'enterpriseAdmin.deactivateUser.transferProjectOwnership.projectsSelected',
				userWillBeDeactivatedWithoutTransferringProjects:
					'enterpriseAdmin.deactivateUser.transferProjectOwnership.userWillBeDeactivatedWithoutTransferringProjects'
			},
			userDeactivated: {
				projectsTransferredNumber:
					'enterpriseAdmin.deactivateUser.userDeactivated.projectsTransferredNumber',
				userStillOwner: 'enterpriseAdmin.deactivateUser.userDeactivated.userStillOwner',
				userSuccessfullyDeactivated:
					'enterpriseAdmin.deactivateUser.userDeactivated.userSuccessfullyDeactivated',
				userWithProjectsSuccessfullyDeactivated:
					'enterpriseAdmin.deactivateUser.userDeactivated.userWithProjectsSuccessfullyDeactivated',
				projectTransferSuccesful:
					'enterpriseAdmin.deactivateUser.userDeactivated.projectTransferSuccesful',
				userLicenceDeactivated:
					'enterpriseAdmin.deactivateUser.userDeactivated.userLicenceDeactivated',
				cannotDeactivateUser:
					'enterpriseAdmin.deactivateUser.userDeactivated.cannotDeactivateUser',
				dontHaveTransferProjectPermission:
					'enterpriseAdmin.deactivateUser.userDeactivated.dontHaveTransferProjectPermission'
			},
			api: {
				userDeactivatedSuccessfully:
					'enterpriseAdmin.deactivateUser.api.userDeactivatedSuccessfully'
			},
			errors: {
				couldntDeactivateUser:
					'enterpriseAdmin.deactivateUser.errors.couldntDeactivateUser',
				couldntGetOwnedProjects:
					'enterpriseAdmin.deactivateUser.errors.couldntGetOwnedProjects'
			}
		},
		reactivateUser: {
			title: 'enterpriseAdmin.reactivateUser.title',
			modalQuestion: 'enterpriseAdmin.reactivateUser.modalQuestion',
			api: {
				userReactivatedSuccessfully:
					'enterpriseAdmin.reactivateUser.api.userReactivatedSuccessfully'
			},
			errors: {
				couldntReactivateUser: 'enterpriseAdmin.reactivateUser.errors.couldntReactivateUser'
			}
		}
	},
	documents: {
		search: 'documents.search',
		buttons: {
			addNew: 'documents.buttons.addNew',
			yes: 'documents.buttons.yes',
			no: 'documents.buttons.no',
			add: 'documents.buttons.add'
		},
		view: {
			noPreview: 'documents.view.noPreview',
			loading: 'documents.view.loading',
			error: 'documents.view.error'
		},
		renameModal: {
			title: 'documents.renameModal.title',
			inputLabel: 'documents.renameModal.inputLabel'
		},
		deleteMessage: 'documents.deleteMessage',
		renameMessage: 'documents.renameMessage',
		list: {
			title: 'documents.list.title',
			confirm: 'documents.list.confirm',
			noFilesTitle: 'documents.list.noFilesTitle',
			noFilesSubTitle: 'documents.list.noFilesSubTitle',
			remove: 'documents.list.remove',
			columns: {
				name: 'documents.list.columns.name',
				owner: 'documents.list.columns.owner',
				date: 'documents.list.columns.date',
				fileSize: 'documents.list.columns.fileSize',
				fileType: 'documents.list.columns.fileType'
			}
		}
	},
	requiredFields: 'requiredFields',
	personalDataWarning: 'personalDataWarning',
	hickupsWarningMessage: 'hickupsWarningMessage',
	hickupsWarningAdditionalInfo: 'hickupsWarningAdditionalInfo',
	hickupsWarningEmail: 'hickupsWarningEmail',
	tableLists: {
		showing: 'tableLists.showing',
		showingNumberAndItems: 'tableLists.showingNumberAndItems',
		users: 'tableLists.users',
		projects: 'tableLists.projects',
		payments: 'tableLists.payments',
		customiseTable: 'tableLists.customiseTable',
		noFilterSeachResults: 'tableLists.noFilterSeachResults',
		filterErrorToValue: 'tableLists.filterErrorToValue',
		filterErrorDateRequired: 'tableLists.filterErrorDateRequired',
		filterErrorNumberRequired: 'tableLists.filterErrorNumberRequired',
		search: 'tableLists.search'
	},
	timeToUpgradeModal: {
		header: 'timeToUpgradeModal.header',
		licenceLimitationHeader: 'timeToUpgradeModal.licenceLimitationHeader',
		title: 'timeToUpgradeModal.title',
		description: 'timeToUpgradeModal.description',
		descriptionCollaborator: 'timeToUpgradeModal.descriptionCollaborator',
		seePlans: 'timeToUpgradeModal.seePlans'
	},
	roles: {
		header: {
			searchInput: {
				placeholder: 'roles.header.searchInput.placeholder'
			},
			createButton: {
				title: 'roles.header.createButton.title'
			},
			menuTabs: {
				ownTemplates: 'roles.header.menuTabs.ownTemplates',
				sharedTemplates: 'roles.header.menuTabs.sharedTemplates'
			},
			roleTemplates: 'roles.header.roleTemplates'
		},
		noRoles: {
			title: 'roles.noRoles.title',
			description: 'roles.noRoles.description',
			templateDescription: 'roles.noRoles.templateDescription'
		},
		modals: {
			roleModal: {
				title: {
					create: 'roles.modals.roleModal.title.create',
					update: 'roles.modals.roleModal.title.update',
					user: 'roles.modals.roleModal.title.user'
				},
				fields: {
					type: 'roles.modals.roleModal.fields.type',
					name: 'roles.modals.roleModal.fields.name',
					description: 'roles.modals.roleModal.fields.description'
				},
				inputPlaceholders: {
					create: 'roles.modals.roleModal.inputPlaceholders.create',
					custom: 'roles.modals.roleModal.inputPlaceholders.custom'
				},
				defaultRoles: {
					admin: 'roles.modals.roleModal.defaultRoles.admin',
					editor: 'roles.modals.roleModal.defaultRoles.editor',
					viewer: 'roles.modals.roleModal.defaultRoles.viewer',
					custom: 'roles.modals.roleModal.defaultRoles.custom'
				},
				errors: {
					nameUnique: 'roles.modals.roleModal.errors.nameUnique',
					nameRequired: 'roles.modals.roleModal.errors.nameRequired'
				},
				assignRole: {
					title: 'roles.modals.roleModal.assignRole.title',
					checkbox: {
						title: 'roles.modals.roleModal.assignRole.checkbox.title',
						description: 'roles.modals.roleModal.assignRole.checkbox.description'
					},
					options: {
						organizations: {
							all: 'roles.modals.roleModal.assignRole.options.organizations.all',
							specific:
								'roles.modals.roleModal.assignRole.options.organizations.specific',
							inputPlaceholder:
								'roles.modals.roleModal.assignRole.options.organizations.inputPlaceholder'
						},
						collaborators: {
							all: 'roles.modals.roleModal.assignRole.options.collaborators.all',
							specific:
								'roles.modals.roleModal.assignRole.options.collaborators.specific',
							inputPlaceholder:
								'roles.modals.roleModal.assignRole.options.collaborators.inputPlaceholder'
						}
					}
				},
				tabs: {
					general: {
						title: 'roles.modals.roleModal.tabs.general.title'
					},
					statuses: {
						title: 'roles.modals.roleModal.tabs.statuses.title'
					},
					modules: {
						title: 'roles.modals.roleModal.tabs.modules.title'
					}
				}
			}
		}
	},
	exportWizard: {
		exportType: {
			seriesAsCSV: 'exportWizard.exportType.seriesAsCSV',
			seriesAsExcel: 'exportWizard.exportType.seriesAsExcel'
		},
		mainView: {
			series: {
				title: 'exportWizard.mainView.series.title',
				inputs: {
					tagInput: {
						label: 'exportWizard.mainView.series.inputs.tagInput.label',
						placeholder: 'exportWizard.mainView.series.inputs.tagInput.placeholder'
					}
				},
				options: {
					title: 'exportWizard.mainView.series.options.title',
					importable: {
						label: 'exportWizard.mainView.series.options.importable.label',
						description: 'exportWizard.mainView.series.options.importable.description'
					},
					justData: {
						label: 'exportWizard.mainView.series.options.justData.label',
						description: 'exportWizard.mainView.series.options.justData.description'
					},
					allData: {
						label: 'exportWizard.mainView.series.options.allData.label',
						description: 'exportWizard.mainView.series.options.allData.description'
					}
				},
				format: {
					title: 'exportWizard.mainView.series.format.title',
					csv: 'exportWizard.mainView.series.format.csv',
					xlsx: 'exportWizard.mainView.series.format.xlsx'
				},
				errors: {
					inputs: {
						required: 'exportWizard.mainView.series.inputs.required'
					}
				},
				warning: {
					noData: 'exportWizard.mainView.series.warning.noData'
				}
			}
		},
		secondaryView: {
			series: {
				title: 'exportWizard.secondaryView.series.title',
				listLabel: 'exportWizard.secondaryView.series.listLabel',
				tooltipDescription: 'exportWizard.secondaryView.series.tooltipDescription'
			}
		}
	},
	projectsPage: {
		addButton: {
			newProject: 'projectsPage.addButton.newProject',
			newSurvey: 'projectsPage.addButton.newSurvey'
		},
		dataToEntries: {
			columnName: 'projectsPage.dataToEntries.columnName',
			variableName: 'projectsPage.dataToEntries.variableName',
			selectDateFormat: 'projectsPage.dataToEntries.selectDateFormat',
			customDateFormat: 'projectsPage.dataToEntries.customDateFormat',
			selectDateAndTime: 'projectsPage.dataToEntries.selectDateAndTime'
		},
		patient: {
			distributionList: {
				label: 'projectsPage.patient.distributionList.label',
				description: 'projectsPage.patient.distributionList.description'
			},
			pinDelivery: 'projectsPage.patient.pinDelivery',
			title: 'projectsPage.patient.title'
		}
	},
	rolesPage: {
		createTemplateFromRoleModal: {
			emptyTemplateNameError: 'rolesPage.createTemplateFromRoleModal.emptyTemplateNameError',
			uniqueTemplateRoleError:
				'rolesPage.createTemplateFromRoleModal.uniqueTemplateRoleError',
			title: 'rolesPage.createTemplateFromRoleModal.title',
			caption: 'rolesPage.createTemplateFromRoleModal.caption',
			label: 'rolesPage.createTemplateFromRoleModal.label'
		},
		deleteRoleModal: {
			message: 'rolesPage.deleteRoleModal.message'
		},
		newRole: 'rolesPage.newRole',
		createTemplate: 'rolesPage.createTemplate',
		roleModal: {
			title: 'rolesPage.roleModal.title',
			selectRoleLabel: 'rolesPage.roleModal.selectRoleLabel',
			roleToAllGroups: 'rolesPage.roleModal.roleToAllGroups',
			roleToSpecificGroups: 'rolesPage.roleModal.roleToSpecificGroups',
			groupNamePlaceholder: 'rolesPage.roleModal.groupNamePlaceholder',
			roleToAllCollaborators: 'rolesPage.roleModal.roleToAllCollaborators',
			roleToSpecificCollaborators: 'rolesPage.roleModal.roleToSpecificCollaborators',
			collaboratorNamePlaceholder: 'rolesPage.roleModal.collaboratorNamePlaceholder',
			permissionsMessage: 'rolesPage.roleModal.permissionsMessage',
			modulesPermissions: {
				collaborators: 'rolesPage.roleModal.modulesPermissions.collaborators',
				collaboratorsLabel: 'rolesPage.roleModal.modulesPermissions.collaboratorsLabel',
				projectLabel: 'rolesPage.roleModal.modulesPermissions.projectLabel',
				projectDescription: 'rolesPage.roleModal.modulesPermissions.projectDescription'
			}
		},
		shareRoleTemplateModal: {
			alreadySharedWith: 'rolesPage.shareRoleTemplateModal.alreadySharedWith',
			errorUserNotFound: 'rolesPage.shareRoleTemplateModal.errorUserNotFound',
			invalidEmail: 'rolesPage.shareRoleTemplateModal.invalidEmail',
			shareWithUsers: 'rolesPage.shareRoleTemplateModal.shareWithUsers',
			shareWithAllUsers: 'SrolesPage.shareRoleTemplateModal.shareWithAllUsers',
			userList: 'rolesPage.shareRoleTemplateModal.userList',
			projectList: 'rolesPage.shareRoleTemplateModal.projectList',
			enterEmail: 'rolesPage.shareRoleTemplateModal.enterEmail',
			selectProjects: 'rolesPage.shareRoleTemplateModal.selectProjects',
			shareAccrossProjects: 'rolesPage.shareRoleTemplateModal.shareAccrossProjects'
		},
		customRole: 'rolesPage.customRole',
		title: 'rolesPage.title',
		templateRolesPageHeader: {
			myTemplates: 'rolesPage.templateRolesPageHeader.myTemplates',
			sharedWithMe: 'rolesPage.templateRolesPageHeader.sharedWithMe',
			title: 'rolesPage.templateRolesPageHeader.title'
		}
	},
	variablesPage: {
		warningWithFilters: {
			message: 'variablesPage.warningWithFilters.message',
			label: 'variablesPage.warningWithFilters.label',
			description: 'variablesPage.warningWithFilters.description'
		},
		moveVariablesModal: {
			beginning: 'variablesPage.moveVariablesModal.beginning',
			end: 'variablesPage.moveVariablesModal.end',
			before: 'variablesPage.moveVariablesModal.before',
			after: 'variablesPage.moveVariablesModal.after',
			atPosition: 'variablesPage.moveVariablesModal.atPosition',
			destination: 'variablesPage.moveVariablesModal.destination',
			positionInList: 'variablesPage.moveVariablesModal.positionInList'
		},
		variablesMapping: {
			mainLevel: 'variablesPage.variablesMapping.mainLevel',
			title: 'variablesPage.variablesMapping.title',
			contextSelect: {
				label: 'variablesPage.variablesMapping.contextSelect.label',
				hint: 'variablesPage.variablesMapping.contextSelect.hint'
			},
			initialise: 'variablesPage.variablesMapping.initialise',
			enableCustomization: {
				label: 'variablesPage.variablesMapping.enableCustomization.label',
				description: 'variablesPage.variablesMapping.enableCustomization.description'
			},
			customMapping: 'variablesPage.variablesMapping.customMapping',
			infoMessage: 'variablesPage.variablesMapping.infoMessage',
			name: 'variablesPage.variablesMapping.name',
			api: 'variablesPage.variablesMapping.api',
			customApi: 'variablesPage.variablesMapping.customApi',
			errors: {
				couldntChangeVariableName:
					'variablesPage.variablesMapping.errors.couldntChangeVariableName'
			}
		},
		noVariables: {
			title: 'variablesPage.noVariables.title',
			create: 'variablesPage.noVariables.create',
			import: 'variablesPage.noVariables.import'
		},
		createVariableGroupModal: {
			title: 'variablesPage.createVariableGroupModal.title',
			placeholder: 'variablesPage.createVariableGroupModal.placeholder'
		},
		updateVariableGroupModal: {
			label: 'variablesPage.updateVariableGroupModal.label',
			placeholder: 'variablesPage.updateVariableGroupModal.placeholder'
		},
		deleteVariableGroupModal: {
			message: 'variablesPage.deleteVariableGroupModal.message'
		},
		deleteVariableModal: {
			message: 'variablesPage.deleteVariableModal.message'
		},
		createVariableSetModal: {
			title: 'variablesPage.createVariableSetModal.title',
			label: 'variablesPage.createVariableSetModal.label',
			placeholder: 'variablesPage.createVariableSetModal.placeholder'
		},
		deleteVariableSetModal: {
			message: 'variablesPage.deleteVariableSetModal.message'
		},
		deleteVariablesSelectedTableRowsModal: {
			calculationRulesMessage:
				'variablesPage.deleteVariablesSelectedTableRowsModal.calculationRulesMessage',
			beforeVariable: 'variablesPage.deleteVariablesSelectedTableRowsModal.beforeVariable',
			afterVariable: 'variablesPage.deleteVariablesSelectedTableRowsModal.afterVariable'
		},
		variableModal: {
			generalDetails: 'variablesPage.variableModal.generalDetails',
			calculations: 'variablesPage.variableModal.calculations',
			deleteVariable: 'variablesPage.variableModal.deleteVariable',
			patchDependenciesModal: {
				title: 'variablesPage.variableModal.patchDependenciesModal.title',
				message: 'variablesPage.variableModal.patchDependenciesModal.message'
			},
			typeChangeModal: {
				title: 'variablesPage.variableModal.typeChangeModal.title',
				message: 'variablesPage.variableModal.typeChangeModal.message'
			}
		},
		variablesTable: {
			number: 'variablesPage.variablesTable.number',
			title: 'variablesPage.variablesTable.title',
			variableType: 'variablesPage.variablesTable.variableType',
			dataType: 'variablesPage.variablesTable.dataType',
			description: 'variablesPage.variablesTable.description'
		},
		createGroupOrSeries: 'variablesPage.createGroupOrSeries',
		addToGroup: 'variablesPage.addToGroup',
		addToSeries: 'variablesPage.addToSeries',
		addVariableFields: {
			firstErrorBeforeVariable: 'variablesPage.addVariableFields.firstErrorBeforeVariable',
			firstErrorAfterVariable: 'variablesPage.addVariableFields.firstErrorAfterVariable',
			secondErrorBeforeVariable: 'variablesPage.addVariableFields.secondErrorBeforeVariable',
			secondErrorAfterVariable: 'variablesPage.addVariableFields.secondErrorAfterVariable',
			uniqueOptions: 'variablesPage.addVariableFields.uniqueOptions',
			infoMessage: 'variablesPage.addVariableFields.infoMessage',
			description: 'variablesPage.addVariableFields.description'
		},
		importCategoryValuesModal: {
			title: 'variablesPage.importCategoryValuesModal.title',
			selectTemplate: 'variablesPage.importCategoryValuesModal.selectTemplate',
			selectCategoryVariable:
				'variablesPage.importCategoryValuesModal.selectCategoryVariable',
			selectCategoryValues: 'variablesPage.importCategoryValuesModal.selectCategoryValues',
			inputLabel: 'variablesPage.importCategoryValuesModal.inputLabel',
			hint: 'variablesPage.importCategoryValuesModal.hint'
		},
		mergeCategoryValuesModal: {
			beforeVariable: 'variablesPage.mergeCategoryValuesModal.beforeVariable',
			afterVariable: 'variablesPage.mergeCategoryValuesModal.afterVariable'
		},
		deleteCategoryValuesModal: {
			beforeVariable: 'variablesPage.deleteCategoryValuesModal.beforeVariable',
			afterVariable: 'variablesPage.deleteCategoryValuesModal.afterVariable',
			delete: 'variablesPage.deleteCategoryValuesModal.delete',
			removeDatasetValues: 'variablesPage.deleteCategoryValuesModal.removeDatasetValues',
			datasetValues: 'variablesPage.deleteCategoryValuesModal.datasetValues',
			deleteWarning: 'variablesPage.deleteCategoryValuesModal.deleteWarning'
		},
		categoryValue: 'variablesPage.categoryValue',
		selectCategory: 'variablesPage.selectCategory',
		categoryValuesModal: {
			message: 'variablesPage.categoryValuesModal.message',
			secondMessage: 'variablesPage.categoryValuesModal.secondMessage',
			informationModal: {
				title: 'variablesPage.categoryValuesModal.informationModal.title',
				description: 'variablesPage.categoryValuesModal.informationModal.description',
				button: 'variablesPage.categoryValuesModal.informationModal.button'
			}
		},
		compositeVariable: {
			noVariables: 'variablesPage.compositeVariable.noVariables',
			newRule: 'variablesPage.compositeVariable.newRule',
			dateCalculation: 'variablesPage.compositeVariable.dateCalculation'
		},
		createVariableGroupOrSetModal: {
			message: 'variablesPage.createVariableGroupOrSetModal.message',
			seriesPlaceholder: 'variablesPage.createVariableGroupOrSetModal.seriesPlaceholder',
			groupLabel: 'variablesPage.createVariableGroupOrSetModal.groupLabel'
		},
		variableCard: {
			beforeVariable: 'variablesPage.variableCard.beforeVariable'
		},
		variableSetConfirmDragAndDropActionModal: {
			beforeVariable: 'variablesPage.variableSetConfirmDragAndDropActionModal.beforeVariable',
			between: 'variablesPage.variableSetConfirmDragAndDropActionModal.between',
			afterVariable: 'variablesPage.variableSetConfirmDragAndDropActionModal.afterVariable'
		},
		confirmMoveVariablesToSetModal: {
			moveInSeries: 'variablesPage.confirmMoveVariablesToSetModal.moveInSeries',
			moveInMainLevel: 'variablesPage.confirmMoveVariablesToSetModal.moveInMainLevel'
		},
		errorVariableToGroup: 'variablesPage.errorVariableToGroup',
		deleteGroup: 'variablesPage.deleteGroup',
		editGroup: 'variablesPage.editGroup',
		newVariable: 'variablesPage.newVariable',
		newVariableGroup: 'variablesPage.newVariableGroup',
		newVariableSet: 'variablesPage.newVariableSet',
		editSeries: 'variablesPage.editSeries',
		deleteSeries: 'variablesPage.deleteSeries',
		errorVariableToSeries: 'variablesPage.errorVariableToSeries',
		createGroup: 'variablesPage.createGroup',
		aggregationVariable: 'variablesPage.aggregationVariable',
		aggregationFunction: 'variablesPage.aggregationFunction',
		nameOfSeries: 'variablesPage.nameOfSeries',
		emptySeriesLabelError: 'variablesPage.emptySeriesLabelError',
		addAggregationRule: 'variablesPage.addAggregationRule',
		createNewGroup: 'variablesPage.createNewGroup',
		createNewSeries: 'variablesPage.createNewSeries',
		sortVariables: {
			default: 'variablesPage.sortVariables.default',
			alphabetically: 'variablesPage.sortVariables.alphabetically'
		},
		aggregationRuleType: {
			average: 'variablesPage.aggregationRuleType.average',
			sum: 'variablesPage.aggregationRuleType.sum',
			median: 'variablesPage.aggregationRuleType.median',
			min: 'variablesPage.aggregationRuleType.min',
			max: 'variablesPage.aggregationRuleType.max',
			earliest: 'variablesPage.aggregationRuleType.earliest',
			latest: 'variablesPage.aggregationRuleType.latest',
			mean: 'variablesPage.aggregationRuleType.mean'
		},
		precision: {
			label: 'variablesPage.precision.label',
			placeholder: 'variablesPage.precision.placeholder',
			error: 'variablesPage.precision.error'
		}
	},
	terms: {
		of: 'terms.of',
		all: 'terms.all',
		default: 'terms.default',
		customised: 'terms.customised',
		or: 'terms.or',
		errorlabelUnique: 'terms.errorlabelUnique',
		warning: 'terms.warning',
		deleteWarningMessage: 'terms.deleteWarningMessage',
		variables: 'terms.variables',
		groups: 'terms.groups',
		series: 'terms.series',
		groupsToUpperCase: 'terms.groupsToUpperCase',
		seriesToUpperCase: 'terms.seriesToUpperCase',
		forms: 'terms.forms',
		number: 'terms.number',
		dependencies: 'terms.dependencies',
		statuses: 'terms.statuses',
		statusPermissions: 'terms.statusPermissions',
		projectRoles: 'terms.projectRoles',
		data: 'terms.data',
		show: 'terms.show',
		sort: 'terms.sort',
		create: 'terms.create',
		edit: 'terms.edit',
		value: 'terms.value',
		description: 'terms.description',
		labelUnique: 'terms.labelUnique',
		label: 'terms.label',
		uniqueValue: 'terms.uniqueValue',
		valueName: 'terms.valueName',
		selected: 'terms.selected',
		values: 'terms.values',
		selectTargetValue: 'terms.selectTargetValue',
		mergeInto: 'terms.mergeInto',
		delete: 'terms.delete',
		position: 'terms.position',
		manage: 'terms.manage',
		configure: 'terms.configure',
		fixedValues: 'terms.fixedValues',
		chooseLayout: 'terms.chooseLayout',
		confirmation: 'terms.confirmation',
		move: 'terms.move',
		valueUpperCase: 'terms.valueUpperCase',
		add: 'terms.add',
		plus: 'terms.plus',
		minus: 'terms.minus',
		addedTo: 'terms.addedTo',
		differenceAs: 'terms.differenceAs',
		valuesIs: 'terms.valuesIs',
		input: 'terms.input',
		vas: 'terms.vas',
		numberInput: 'terms.numberInput',
		dateInput: 'terms.dateInput',
		dateVariable: 'terms.dateVariable',
		numberVariable: 'terms.numberVariable',
		variable: 'terms.variable',
		variableLowerCase: 'terms.variableLowerCase',
		operation: 'terms.operation',
		durationOperation: 'terms.durationOperation',
		insertValue: 'terms.insertValue',
		condition: 'terms.condition',
		update: 'terms.update',
		identifier: 'terms.identifier',
		variablesToUpperCase: 'terms.variablesToUpperCase',
		new: 'terms.new',
		tableView: 'terms.tableView',
		gridView: 'terms.gridView',
		moreLowerCase: 'terms.moreLowerCase',
		merge: 'terms.merge',
		if: 'terms.if',
		andLowerCase: 'terms.andLowerCase',
		yes: 'terms.yes',
		no: 'terms.no',
		color: 'terms.color',
		createdDate: 'terms.createdDate',
		types: 'terms.types',
		download: 'terms.ownload',
		unnamed: 'terms.unnamed',
		search: 'terms.search',
		noResults: 'terms.noResults',
		remove: 'terms.remove',
		changesSaved: 'terms.changesSaved',
		searchFor: 'terms.searchFor',
		menu: 'terms.menu',
		clearFilters: 'terms.clearFilters',
		view: 'terms.view',
		showLess: 'terms.showLess',
		showMore: 'terms.showMore',
		initial: 'terms.initial',
		distributionLowerCase: 'terms.distributionLowerCase',
		uniqueEmail: 'terms.uniqueEmail',
		project: 'terms.project',
		survey: 'terms.survey',
		empty: 'terms.empty',
		subtitle: 'terms.subtitle',
		text: 'terms.text',
		separator: 'terms.separator',
		table: 'terms.table',
		select: 'terms.select',
		count: 'terms.count'
	},
	documentsPage: {
		page: 'documentsPage.page',
		dateFilter: {
			fromError: 'documentsPage.dateFilter.fromError',
			toError: 'documentsPage.dateFilter.toError',
			requiredDate: 'documentsPage.dateFilter.requiredDate'
		}
	},
	statusesPage: {
		deleteStatusModal: {
			title: 'statusesPage.deleteStatusModal.title'
		},
		statusesPageHeader: {
			placeholder: 'statusesPage.statusesPageHeader.placeholder'
		}
	},
	dependencies: {
		dependenciesPage: {
			headerTitle: 'dependencies.dependenciesPage.headerTitle',
			enableRules: 'dependencies.dependenciesPage.enableRules',
			expandRules: 'dependencies.dependenciesPage.expandRules',
			collapseRules: 'dependencies.dependenciesPage.collapseRules'
		},
		builder: {
			dependenciesLabel: 'dependencies.builder.dependenciesLabel',
			tableView: {
				noDependencies: 'dependencies.builder.tableView.noDependencies',
				addRule: 'dependencies.builder.tableView.addRule',
				supplier: 'dependencies.builder.tableView.supplier',
				dependencyType: 'dependencies.builder.tableView.dependencyType',
				operator: 'dependencies.builder.tableView.operator',
				value: 'dependencies.builder.tableView.value',
				dependantVariables: 'dependencies.builder.tableView.dependantVariables',
				result: 'dependencies.builder.tableView.result',
				ruleDescription: 'dependencies.builder.tableView.ruleDescription',
				ruleDescriptionPlaceholder:
					'dependencies.builder.tableView.ruleDescriptionPlaceholder',

				filter: {
					all: 'dependencies.builder.tableView.filter.all',
					supplier: 'dependencies.builder.tableView.filter.supplier',
					dependantVariables: 'dependencies.builder.tableView.filter.dependantVariables'
				},
				condition: {
					editCondition: 'dependencies.builder.tableView.condition.editCondition',
					deleteCondition: 'dependencies.builder.tableView.condition.deleteCondition'
				},
				rule: {
					newCondition: 'dependencies.builder.tableView.rule.newCondition',
					editRule: 'dependencies.builder.tableView.rule.editRule',
					deleteRule: 'dependencies.builder.tableView.rule.deleteRule'
				},
				options: {
					editRuleTitle: 'dependencies.builder.tableView.options.editRuleTitle',
					editConditionTitle: 'dependencies.builder.tableView.options.editConditionTitle'
				},
				deleteModal: {
					deleteRuleMessage:
						'dependencies.builder.tableView.deleteModal.deleteRuleMessage',
					deleteConditionMessage:
						'dependencies.builder.tableView.deleteModal.deleteConditionMessage'
				}
			}
		},
		dependenciesSidebar: {
			mainLevel: 'dependencies.dependenciesSidebar.mainLevel',
			selectContext: 'dependencies.dependenciesSidebar.selectContext',
			searchVariables: 'dependencies.dependenciesSidebar.searchVariables',
			hint: 'dependencies.dependenciesSidebar.hint'
		},
		dependantFiltering: {
			more: 'dependencies.dependantFiltering.more',
			iconTitle: 'dependencies.dependantFiltering.iconTitle',
			configureFilteringValuesModal: {
				title: 'dependencies.dependantFiltering.configureFilteringValuesModal.title',
				previewSelected: {
					label: 'dependencies.dependantFiltering.configureFilteringValuesModal.previewSelected.label',
					hint: 'dependencies.dependantFiltering.configureFilteringValuesModal.previewSelected.hint'
				},
				selectCategoryValues:
					'dependencies.dependantFiltering.configureFilteringValuesModal.selectCategoryValues'
			}
		},
		dependencyCondition: {
			lessThan: 'dependencies.dependencyCondition.lessThan',
			lessOrEqualTo: 'dependencies.dependencyCondition.lessOrEqualTo',
			equals: 'dependencies.dependencyCondition.equals',
			greaterOrEqualTo: 'dependencies.dependencyCondition.greaterOrEqualTo',
			greaterThan: 'dependencies.dependencyCondition.greaterThan',
			before: 'dependencies.dependencyCondition.before',
			after: 'dependencies.dependencyCondition.after',
			contains: 'dependencies.dependencyCondition.contains'
		},
		directDependency: {
			selectCategory: 'dependencies.directDependency.selectCategory',
			deleteDependant: 'dependencies.directDependency.deleteDependant',
			selectVariable: 'dependencies.directDependency.selectVariable'
		},
		dependencyRule: {
			deleteDepenency: 'dependencies.dependencyRule.deleteDepenency',
			newCondition: 'dependencies.dependencyRule.newCondition'
		},
		types: {
			visibility: 'dependencies.types.visibility',
			filtering: 'dependencies.types.filtering',
			all: 'dependencies.types.all'
		}
	},
	projectMetadata: {
		general: {
			mandatoryField: 'projectMetadata.general.mandatoryField'
		},
		hso: {
			projectType: {
				label: 'projectMetadata.hso.projectType.label',
				description: 'projectMetadata.hso.projectType.description',
				placeholder: 'projectMetadata.hso.projectType.placeholder',
				medicalResearch: {
					label: 'projectMetadata.hso.projectType.medicalResearch.label',
					description: 'projectMetadata.hso.projectType.medicalResearch.description'
				},
				internalQA: {
					label: 'projectMetadata.hso.projectType.internalQA.label',
					description: 'projectMetadata.hso.projectType.internalQA.description'
				}
			},
			archiveNumber: {
				label: 'projectMetadata.hso.archiveNumber.label',
				description: 'projectMetadata.hso.archiveNumber.description'
			}
		}
	}
};
